import React, { useState, useHistory } from 'react';
import './login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from './logo.jpg';
import { Link, BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import logoImage from './logo.png';
import axios from 'axios';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';
import config from '../config';
import { Form, Button } from 'react-bootstrap';

const UserChangePassword = () => {
    const [userPassword, setUserPassword] = useState('');
    const [temPassword, setTemPassword] = useState('');
    const token = localStorage.getItem('adminToken');
    const userID = localStorage.getItem('userID');
    const naviagte = useNavigate();
   const  handelePassword=async(e)=>{
    e.preventDefault();
        if (temPassword !== userPassword) {
            Swal.fire('Error', 'Password and Conform password should be equal', 'error');
            return false;
          }
        try {
            //setLoading(true);
            const response = await axios.put(
              `${config.apiUrl}/changepassword/${userID}`,
              {
                'updatepassword': userPassword,
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  
                },
              }
            );
      
      
            Swal.fire({
              title: 'Saved!',
              text: `Password has been changed Successfully`,
              icon: 'success',
            }).then(() => {
                naviagte("/login");
            });
            // setBtnDisabled(false);
            //setBtnText("Submited");
            // props.loadEvents();
            //window.location.reload();
          } catch (error) {
            // setBtnDisabled(false);
            //setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error chnage password.", "error");
          }


   }
    return (

        <>
            <section className="h-100 gradient-form" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '100vh' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-left align-items-center h-100">
                        <div className="col-xl-5">
                            <div className="card rounded-3 text-black">
                                <div className="row g-0">
                                    <div className="col-lg-12">
                                        <div className="card-body">

                                            <div className="text-center">
                                                <img src={logoImage}
                                                    style={{ width: '185px' }} alt="logo" />

                                            </div>

                                            
                                                <p style={{ textAlign: 'center', marginTop: '20px' }}>Please reset your Password</p>

                                                <Form onSubmit={handelePassword}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>New Password</Form.Label>
                                                        <Form.Control type="password" placeholder="New Password" onChange={(e) => setTemPassword(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                        <Form.Label>Conform password</Form.Label>
                                                        <Form.Control type="password" placeholder="Conform password" onChange={(e) => setUserPassword(e.target.value)} />
                                                    </Form.Group>
                                                    <Button variant="primary" size="lg" active type='submit'>
                                                        Submit
                                                    </Button>
                                                </Form>


                                            

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    )
}

export default UserChangePassword
