import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import DeleteLeadershipPrfileImg from './DeleteLeadershipPrfileImg';

const UpdateLeadershipModal = (props) => {
    const [show, setShow] = useState(false);
    const token = localStorage.getItem('adminToken');
    const showLeadershipID = props.id;
    const [item, setItem] = useState({});
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [laeadershipname, setLaeadershipname] = useState("");
    const [position, setPosition] = useState("");
    const [details, setDetails] = useState("");
    const [imgFile, setImagFile] = useState(null);
    const [ranking, setRanking] = useState(0);
    const addby=localStorage.getItem('userID')
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    //console.log(addby);

    //modal open and close
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //onchange file function
    const onChangeFile = (e) => {
        const files = e.target.files[0];
        setImagFile(files);
        //console.log(files);
    }

    useEffect(() => {
        showLeadershipdetail();
    }, [showLeadershipID]);

    const showLeadershipdetail = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/leadership/${showLeadershipID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const leadershipData = response.data.data;
            const name=leadershipData.firstname +" "+leadershipData.lastname;
            setItem(leadershipData);
            setFirstname(leadershipData.firstname);
            setLastname(leadershipData.lastname);
            setLaeadershipname(name);
            setPosition(leadershipData.position);
            setDetails(leadershipData.details);
            setRanking(leadershipData.ranking);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }

    const UpdateLeadershipHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        

        // Check if endDate is greater than or equal to startDate
        
        
        if (imgFile) {
            if (imgFile.type !== "image/png" && imgFile.type !== 'image/jpeg' && imgFile.type !== 'image/jpg') {
                Swal.fire("Warning", "Please attach Image in .png or .jpeg or .jpg format.", "warning");
                return;
            }
            formData.append("imgfile", imgFile);
        }
        const name=firstname +" "+lastname;
        //setLaeadershipname(firstname+" "+lastname);
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("laeadershipname",name);
        formData.append("position", position);
        formData.append("details", details);
        formData.append("ranking", ranking);
        formData.append("addedby",addby);
        //formData.append("calenderevent", calenderEventLink);

        /*for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
          }*/
          //return false;
        try {
            const response = await axios.put(
                `${config.apiUrl}/leadership/${showLeadershipID}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setBtnDisabled(false);
            setBtnText("Submited");
            Swal.fire("Success", "Leadership details updated successfully!", "success");
            handleClose();
            props.loadLeader();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error updating event.", "error");
        }
    }

    
    return (
        <>
            <Button variant="btn btn-success" onClick={handleShow}><i className="bi bi-pencil-square"></i></Button>

            <Modal show={show} onHide={handleClose} animation={false} size="lg" >
                <Form onSubmit={UpdateLeadershipHandler} encType="multipart/form-data" method="post">
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Update Leadership Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">First Name <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Enter First Name" onChange={(e) => { setFirstname(e.target.value) }} value={firstname} required />
                            </div>
                            <div className="col">
                                <label className="form-label">Last Name <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" id="formGroupExampleInput" placeholder='Enter Last Name' onChange={(e) => { setLastname(e.target.value) }} value={lastname} required />
                            </div>
                        </div>
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">Designation <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Designation" aria-label="Designation" onChange={(e) => { setPosition(e.target.value) }}value={position} required />
                            </div>
                            <div className="col">
                                
                                <label className="form-label">Profile Image <span style={{ color: 'red' }}>*</span></label>
                                <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png .gif" onChange={onChangeFile} />
                                {item.imgfile !== null && (
                                    <p style={{ fontSize: '12px' ,margin:'5px' }}><img src={`${config.apiBaseUrl}/public/uploadLeadershipImg/${item.imgfile}`} width={50} className="img-fluid" /></p>
                                )}
                            </div>
                        </div>
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">Details <span style={{ color: 'red' }}>*</span></label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Details" onChange={(e) => { setDetails(e.target.value) }} value={details} required></textarea>
                            </div>
                            
                            
                            
                        </div>

                        <div className="row g-3 m-2">
                        <div className="col-md-4">
                                <label className="form-label">Ranking <span style={{ color: 'red' }}>*</span></label>
                                <input type="number" className="form-control" placeholder="Enter Ranking" aria-label="Ranking" onChange={(e) => { setRanking(e.target.value) }}value={ranking} required />
                            </div>
                        </div>

                        


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={btnDisabled}>
                            {btnText}
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}

export default UpdateLeadershipModal
