import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../../../config';

const DeleteSingleFile = (props) => {
    const id = props.deleteFileID;
    const token = props.token;

    const showAlert = () => {
        // Show a confirmation dialog before deleting
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this File',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed to delete
                deleteFileHandler();
            }
        });
    };

    const deleteFileHandler = async () => {
        try {
            await axios.delete(`${config.apiUrl}/eventsfile/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            Swal.fire({
                title: 'Deleted!',
                text: 'The event has been deleted.',
                icon: 'success',
            });
            window.location.reload();
        } catch (error) {
            console.error('Error deleting event:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the event.',
                icon: 'error',
            });
        }
    };

    return (
        <span style={{ color: 'red' }} onClick={showAlert}>
            <i className="bi bi-trash3"></i>
        </span>
    );
}

export default DeleteSingleFile;
