import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';

const ShowBlog = (props) => {
  const [show, setShow] = useState(false);
  const [item, setItem] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateid = props.id;
  const token = props.token;

  useEffect(() => {
    showBlogDetail();
  }, [updateid]);
  const showBlogDetail = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/ShowblogsByID/${updateid}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const BlogData = response.data.data;
      // console.log(newsgData);
      setItem(BlogData);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error: You are not authorized to access this resource.');
      } else {
        console.error('An error occurred:', error.message);
      }
    }
  }
  const publishdate = new Date(item.publishdate);
    const publishday = publishdate.getDate();
    const Month = publishdate.toLocaleString('default', { month: 'short' });
    const year = publishdate.getFullYear();
  return (
    <>
      <Button variant="warning" onClick={handleShow} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}>
        <i className="bi bi-eye"></i>
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card news-block'>
                  <div className='card-body'>
                    <div className='row'>

                      <div className='col-md-12 blog-body'>
                        <h6 className='text-end'><i className='bi bi-calendar-date-fill me-2'></i> {publishday}<sup>th</sup> {Month}, {year}</h6>
                        <h4>{item.title}</h4>

                        <p className='text-justify' dangerouslySetInnerHTML={{ __html: item.details }}>
                        
                          
                        </p>
                        
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShowBlog
