
export const TableCustomStyles = () => ({
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
      // float: 'left', // If you need this property, uncomment it
      // paddingLeft: '0 4px', // If you need this property, uncomment it
      justifyContent: 'left',
      backgroundColor: '#3572d4',
      color: 'white',
     maxWidth:'auto',
     whiteSpace:'pre-wrap',
     
    },
  },
  cells: {
    style: {
      fontSize: '12px',
      justifyContent: 'left',
      maxWidth:'auto',
      whiteSpace:'pre-wrap',
      
    },
  },
});

  
