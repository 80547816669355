import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

const AddUser = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isChecked, setIsChecked] = useState(true);
  const [userPassword, setUserPassword] = useState('');
  const [autoGeneratedPassword, setAutoGeneratedPassword] = useState('');
  const token = localStorage.getItem('adminToken');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [temPassword, setTemPassword] = useState('');
  const generatePassword = () => {
    // Simple password generation logic (you can enhance it)
    return Math.random().toString(36).slice(-8);
  };
  useEffect(() => {
    if (isChecked) {
      const newPass = generatePassword();
      setAutoGeneratedPassword(newPass);
    }
  }, [isChecked]);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const newPass = generatePassword();
      console.log(newPass);
      setAutoGeneratedPassword(newPass);
    }
  };

  const handleAddUser = async (e) => {

    e.preventDefault();
    const password = isChecked ? autoGeneratedPassword : userPassword;

    if (temPassword !== userPassword) {
      Swal.fire('Error', 'Password and Conform password should be equal', 'error');
      return false;
    }


    try {
      //setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}/user`,
        {
          'name': userName,
          'email': userEmail,
          'oneTimePassword': password,
          'expireon': new Date()

        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            
          },
        }
      );


      Swal.fire({
        title: 'Saved!',
        text: `User has been Saved Successfully`,
        icon: 'success',
      }).then(() => {
        handleClose();
      });
      // setBtnDisabled(false);
      //setBtnText("Submited");
       props.loadUser();
      //window.location.reload();
    } catch (error) {
      // setBtnDisabled(false);
      //setBtnText("Submited");
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.errors,
    });
    }






  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add Users
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form onSubmit={handleAddUser}>
          <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formUserName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="User Name" required onChange={(e) => setUserName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formUserEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" required onChange={(e) => setUserEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="autoGeneratePassword"
                label="Automatic generate password"
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
            </Form.Group>
            {!isChecked && (
              <>
                <Form.Control type="password" placeholder="Enter your password" className="mb-3" required onChange={(e) => setTemPassword(e.target.value)} />
                <Form.Control type="password" placeholder="Confirm Password" required onChange={(e) => setUserPassword(e.target.value)} />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUser;
