import React from 'react'
import { NavLink, Link } from 'react-router-dom'

function Footer() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
  return (
    <>
        <div className='container-fluid bg-mcolor footerbg'>
            <div className='row'>
                <div className='col-md-12 pt-5 pb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Link to="/">
                                    <img src='./images/logo_white.png' alt='SignatureIP Logo' className='img-fluid footer-logo p-2'/>
                                </Link>
                                <ul className='list-group list-group-horizontal socialicon mt-3'>
                                    <li className='list-group-item bg-transparent'>
                                        <a href='https://www.linkedin.com/company/signature-ip-corporation/' target={"_blank"} rel="noreferrer"><i className="bi bi-linkedin"></i></a>
                                    </li>
                                    <li className='list-group-item bg-transparent'>
                                        <a href='https://twitter.com/SignatureIp?t=2NjWamaKAmGjlbtjYHIfDw&s=09' target={"_blank"} rel="noreferrer"><i className="bi bi-twitter"></i></a>
                                    </li>
                                    <li className='list-group-item bg-transparent'>
                                        <a href='https://www.facebook.com/profile.php?id=100090804705100&mibextid=ZbWKwL' target={"_blank"} rel="noreferrer"><i className="bi bi-facebook"></i></a>
                                    </li>
                                    {/*<li className='list-group-item bg-transparent'><i className="bi bi-facebook"></i></li>*/}
                                </ul>
                            </div>
                            <div className='col-md-2 offset-md-1'>
                                <ul className='footer-nav'><p>Company</p>
                                    <li><a href="/AboutUs">About Us</a></li>
                                    <li><a href="/Leadership">Leadership</a> </li>
                                    <li><a href="/Partnerships">Our Partners</a></li>
                                    <li><a href="/Events">Events</a></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                                <ul className='footer-nav'><p>Products</p>
                                    <li><NavLink to="/INOCulator">iNoCulator<sup className='tm'>TM</sup></NavLink></li>
                                    <li><NavLink to="/NCNoc">NC-NoC</NavLink></li>
                                    <li><NavLink to="/CNoC">C-NoC</NavLink></li>
                                    <li><NavLink to="/PCIe">PCIe Gen6 Controller</NavLink></li>
                                    <li><NavLink to="/AIAccelerator">AI Accelerator</NavLink></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                                <ul className='footer-nav'><p>Signature's View</p>
                                    <li><NavLink to="/News">News</NavLink></li>
                                    <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                </ul>
                                
                            </div>
                            <div className='col-md-2'>
                               
                                <ul className='footer-nav'>
                                    <li><NavLink to="/Career"><p>Career</p></NavLink></li>
                                    <li><NavLink to="/Contact"><p>Contact Us</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='container-fluid bg-heighlight'>
            <div className='row'>
                <div className='col-md-12 p-2 text-center text-white'>
                    <cite className=''>Copyright © {currentYear} SignatureIP for your cutting edge IP needs - All Rights Reserved.</cite>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer 