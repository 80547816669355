import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

const ResetUserPassword = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [temPassword, setTemPassword] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const userID=props.id;
    const token = localStorage.getItem('adminToken');
    const HandelResetPassword=async(e)=>{
        e.preventDefault();
        if (temPassword !== userPassword) {
            Swal.fire('Error', 'Password and Conform password should be equal', 'error');
            return false;
          }
          try {
            //setLoading(true);
            const response = await axios.put(
              `${config.apiUrl}/changepassword/${userID}`,
              {
                'updatepassword': userPassword,
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  
                },
              }
            );
      
      
            Swal.fire({
              title: 'Saved!',
              text: `Password has been changed Successfully`,
              icon: 'success',
            }).then(() => {
             handleClose();
            });
            // setBtnDisabled(false);
            //setBtnText("Submited");
            // props.loadEvents();
            props.loadUser();
          } catch (error) {
            // setBtnDisabled(false);
            //setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error chnage password.", "error");
          }

    }
    return (
        <>
            <Button variant="success" onClick={handleShow}>
                <i class="bi bi-unlock"></i>
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Form onSubmit={HandelResetPassword}>
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formUserName">
                            <Form.Label>New PassWord</Form.Label>
                            <Form.Control type="password" placeholder="Enter your password" className="mb-3" required onChange={(e) => setTemPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formUserEmail">
                            <Form.Label>Conform Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" required onChange={(e) => setUserPassword(e.target.value)} />
                        </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}

export default ResetUserPassword
