import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import config from '../../../../config';

const AddNews = (props) => {
    const [show, setShow] = useState(false);
    const [publishDate, setPublishDate] = useState('');
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [text, setText] = useState('');
    const token = localStorage.getItem('adminToken');
    const addby = localStorage.getItem('userID');
    const [editorTypes, setEditorTypes] = useState([
        'Text' // The initial editor type for the first row
    ]);
    const [tableData, setTableData] = useState([
        {
            slNo: 1,
            text: '',
            images: null, // Add file state
            position: 'Center', // Add position state
            size: 'Full', // Add size state
        },
    ]);

    const addRow = () => {
        const newRow = {
            slNo: tableData.length + 1,
            text: '',
            images: null,
            position: 'Center',
            size: 'Full',
        };
        setEditorTypes((prevTypes) => [...prevTypes, 'Text']);
        setTableData((prevData) => [...prevData, newRow]);
    };

    const deleteRow = (slNo) => {
        if (tableData.length >1) {
            const updatedData = tableData.filter((row) => row.slNo !== slNo);
            const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
                ...row,
                slNo: index + 1,
            }));
            setTableData(updatedDataWithRenumberedSlNo);
        } else {
            Swal.fire('You cannot delete the last row.');
        }
    };
    const onChangeFile = (slNo, event) => {
        const updatedData = [...tableData];
        const file = event.target.files[0];
    
        if (file) {
            updatedData[slNo - 1].images = file;
            console.log(updatedData[slNo - 1].images);
        } else {
            console.error("No file selected.");
            // You can add more specific error handling or user feedback here.
        }
    
        setTableData(updatedData);
    };
    const addNewsHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        const adddetails = []
        

    tableData.forEach((row, i) => {
        const editorType = editorTypes[i];
        formData.append("images",row.images)

        
    
        if (editorType === 'Image') {
           // console.log(row.images.name)
            const imageData = {
                imageName: row.images.name,
                position: row.position,
                size: row.size,
                imageUrl:"",
            };
            adddetails.push({ editorType:editorType, imageData }); // Use object notation
        } else if (editorType === 'Text') {
            adddetails.push({ editorType:editorType, text: row.text }); // Use object notation
        }
    });
        // Now, append 'adddetails' as a single field in FormData
        formData.append('adddetails', JSON.stringify(adddetails));
       

        formData.append("publishdate", publishDate);
        formData.append("title", title);
        formData.append("addedby", addby);

         /*for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }*/


      //  return false;

        try {
            //setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/news`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setBtnDisabled(false);
            setBtnText("Submited");
            Swal.fire({
                title: 'Saved!',
                text: `News Details  has been Saved Successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            props.loadNews();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Send");
            console.error(error);
            Swal.fire("Error", "Error adding event.", "error");
        }

    }


    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Add News
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Form onSubmit={addNewsHandler} method="post" encType="multipart/form-data">
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Compose News</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Publish Date <span style={{ color: 'red' }}>*</span> </Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Publish Date"
                                onChange={(e) => setPublishDate(e.target.value)}
                                required

                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter News Title"
                                rows={3}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Details</Form.Label>
                            <Table responsive="sm" bordered={true}>
                                <thead style={{ backgroundColor: '#3572d4', color: 'white' }}>
                                    <tr>
                                        <th width="5%" height="5px">
                                            Sl#
                                        </th>
                                        <th width="15%" height="5px">
                                            Select Type
                                        </th>
                                        <th width="80%" style={{ textAlign: 'center' }}>
                                            News Deatils
                                        </th>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row) => (
                                        <tr key={row.slNo}>
                                            <td>{row.slNo}</td>
                                            <th width="5%" height="5px">
                                                <Form.Group as={Col} controlId="formGridState">

                                                    <Form.Select value={editorTypes[row.slNo - 1]} onChange={(e) => {
                                                        const updatedTypes = [...editorTypes];
                                                        updatedTypes[row.slNo - 1] = e.target.value; // Update the editor type for the specific row
                                                        setEditorTypes(updatedTypes);
                                                    }}
                                                    >
                                                        <option value="Text">Text</option>
                                                        <option value="Image">Image</option>
                                                    </Form.Select>
                                                </Form.Group>

                                            </th>

                                            <td>
                                                {editorTypes[row.slNo - 1] === 'Text' ? (
                                                    <div>
                                                        <ReactQuill

                                                            // Set the value for the specific row
                                                            onChange={(newtext) => {
                                                                const updatedData = [...tableData];
                                                                updatedData[row.slNo - 1].text = newtext;
                                                                setTableData(updatedData);
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                ) : (
                                                    // Render Image Editor
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label>Image <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    accept=".jpg, .jpeg, .png, .gif"
                                                                    onChange={(e) => onChangeFile(row.slNo, e)}
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>Postition <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                <Form.Select
                                                                    value={row.position}
                                                                    onChange={(e) => {
                                                                        const updatedData = [...tableData];
                                                                        updatedData[row.slNo - 1].position = e.target.value;
                                                                        setTableData(updatedData);
                                                                    }}
                                                                >
                                                                    <option value="Center">Center</option>
                                                                    <option value="Left">Left</option>
                                                                    <option value="Right">Right</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>Size <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                <Form.Select
                                                                    value={row.size}
                                                                    onChange={(e) => {
                                                                        const updatedData = [...tableData];
                                                                        updatedData[row.slNo - 1].size = e.target.value;
                                                                        setTableData(updatedData);
                                                                    }}
                                                                >
                                                                    <option value="Full">Full</option>
                                                                    <option value="Half">Half</option>
                                                                    <option value="One Fourth">One Fourth</option>
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => deleteRow(row.slNo)}
                                                >
                                                    <i class="bi bi-trash3"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <div style={{ textAlign: 'right' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary mb-2"
                                    onClick={addRow}
                                >
                                    <i className="bi bi-plus"></i>
                                </button>
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={btnDisabled}>
                        {btnText}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default AddNews;
