
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';

const ShowNewsModal = (props) => {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const updateid = props.id;
    const token = props.token;

    useEffect(() => {
        showNewsDetail();
    }, [updateid]);

    const showNewsDetail = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/newsdetails/${updateid}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const newsgData = response.data.data;
            
            setItem(newsgData);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }
    const publishdate = new Date(item.publishdate);
    const publishday = publishdate.getDate();
    const Month = publishdate.toLocaleString('default', { month: 'short' });
    const year = publishdate.getFullYear();
    const showHandler = () => {
        showNewsDetail();
        handleShow();
    }
    return (
        <>
            <Button variant="warning" onClick={showHandler} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}>
                <i className="bi bi-eye"></i>
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>News</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>

                                        <div className='row'>

                                            <div className='col-md-2 news-dates'>

                                                <h6>{Month} {publishday}</h6>
                                                <h6>{year}</h6>
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>{item.title}</h4>



                                                <div className='row'>
                                                    {item.detail && item.detail.map((detailItem, detailIndex) => {
                                                        const textContent = detailItem.text;

                                                        if (detailItem.images.length && detailItem.images.length > 0) {


                                                            return detailItem.images.map((image, imageIndex) => {

                                                                return <>

                                                                    {

                                                                        image.position === 'Center' ? <>
                                                                            {
                                                                                <div className='col-md-12 text-center pb-2 pt-2'>


                                                                                    <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                </div>
                                                                            }

                                                                        </> :
                                                                            image.position === 'Right' ? <>
                                                                                {
                                                                                    image.size === 'Full' ? <div className='col-md-12'>
                                                                                        <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                    </div> : image.size === 'Half' ? <div className='col-md-6'>
                                                                                        <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                    </div> : image.size === 'One Fourth' ? <div className='col-md-4'>
                                                                                        <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                    </div> : null
                                                                                }

                                                                            </> : null
                                                                    }
                                                                </>
                                                            })
                                                        }
                                                        else {

                                                            if (item.detail.length > 2) {
                                                                if (detailIndex < item.detail.length - 2) {
                                                                    
                                                                    if (item.detail[detailIndex + 1].images.length > 0) {
                                                                       
                                                                        return item.detail[detailIndex + 1].images.map((image, imageIndex) => {
                                                                           
                                                                            return (image.position === 'Right' ? <>
                                                                                {
                                                                                    image.size === 'Half' ? <div className='col-md-6'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                        : image.size === 'One Fourth' ?
                                                                                            <div className='col-md-8'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                            : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                }

                                                                            </> : image.position === 'Center' ? <>
                                                                                {
                                                                                    image.size === 'Half' ? <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                        : image.size === 'One Fourth' ?
                                                                                            <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                            : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                }


                                                                            </> : image.position === 'Left' ? <>
                                                                                {
                                                                                    image.size === 'Half' ? <><div className='col-md-6'>
                                                                                        <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                    </div><div className='col-md-6 text-left'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div></>
                                                                                        : image.size === 'One Fourth' ?
                                                                                            <><div className='col-md-4'>
                                                                                                <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                            </div><div className='col-md-8 text-left'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div></>
                                                                                            : <div className='col-md-12 text-left'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                }


                                                                            </> : null)
                                                                        })
                                                                    }
                                                                    
                                                                    else {
                                                                        return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                    }
                                                                }
                                                                else {
                                                                    return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                }

                                                            }
                                                            else {
                                                                return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                            }


                                                        }

                                                    })}

                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ShowNewsModal
