//import { Tooltip } from 'bootstrap'
import React from 'react'
import { Link} from 'react-router-dom'
import DownloadProductInfo from '../Company/downloadProductInfo'
import ContactSales from '../Company/contactSales'

function CNoC() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Coherent NoC (C-NoC)</h2>
                <ol>
                    <li>Products</li>
                    <li>C-NoC</li>
                </ol>
                </div>

            </div>
        </section>

        <div className='container-fluid p-5 pb-1'>
            <div className='row'>
                <div className="col-md-12 text-end">
                <DownloadProductInfo productName={"C-NoC"} produtFileName="C_NOC_Product Brief_v1.4.pdf" produtFileDownloadName="C-NoC.pdf" btnClasses={"btn btn-primary btn-sig"} />
                </div>
            </div>
        </div>
        
        <div className='container-fluid pb-5'>
            <div className='row'>
                <div className='col-md-12 pt-5 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div className='text-justify'>
                                    <p>C-NoC is a layered, scalable, configurable, and physically aware configurable NoC. It supports mesh, grid and torus topologies with simultaneous existence of both coherent and non-coherent traffic. It has configurable data bus widths, supports multiple clocking schemes, and is physically-aware with auto-pipelining and grouping of routers to meet timing requirements. C-NoC supports multiple protocols including CHI, AXI4/3 and AXI-lite, ACE and ACE-lite.</p>
                                    <p>C-NoC provides power control through power-islanding and a power-gating architecture at the interface port and router level, and is physically aware with automated insertion and deletion of pipelines to meet timing, generation of placement-aware groups and topologies, and power and frequency aware NoC generation.</p>
                                    <p>C-NoC provides high performance with on-chip L3 cache support to reduce memory access latency, and its pipeline architecture provides scalability with maximum throughput and minimum latency. It is parity enabled to provide resiliency.</p>
                           
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <img src='./images/C-NOC-1.png' alt='SignatureIP' className='img-fluid imgblock'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

        <ContactSales productName={"C-NoC"} />
    </>
  )
}

export default CNoC