import FooterDashboard from '../../FooterDashboard'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components'; // Import styled from styled-components at the top
import { TableCustomStyles } from '../TableCustomStyle';
import DataTable from 'react-data-table-component'
import axios from 'axios'
import config from '../../../../config';
import AddNews from './AddNews'
import ShowNewsModal from './ShowNewsModal'
import UpdateNews from './UpdateNews'
import DeleteNews from './DeleteNews'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2'

const YourComponent = styled.div`
  // Your styles here
`;
function dateformat(date) {
  const createdAtDate = new Date(date);
  const day = String(createdAtDate.getDate()).padStart(2, '0');
  const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
  const year = String(createdAtDate.getFullYear()).slice(-4);
  const newdate = `${day}-${month}-${year}`;
  return newdate;
}
const NewsPageDashboard = () => {
  const nevigate = useNavigate();
  const token = localStorage.getItem('adminToken');
  const [newsItem, setNewsItem] = useState([]);
  const [pending, setPending] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [exportToXLSXData, setExportToXLSXData] = useState([]);
  const [filterdNews, setFilterdNews] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const isLogin = localStorage.getItem('isLogedIn');
    if (!isLogin) {
      nevigate('/login');
    }
  });
  //const token = localStorage.getItem('adminToken');
  const getNewsDetail = async () => {
    setPending(true);
    await axios({
      method: 'get',
      url: `${config.apiUrl}/news`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        // Handle the response
        setPending(false);

        setNewsItem(response.data.data);
        setFilterdNews(response.data.data);
        //setFilterdEvents(response.data.data);
      })

      .catch(error => {
        setPending(false);
        if (error.response && error.response.status === 401) {
          // Unauthorized, handle accordingly (e.g., show login prompt)
          console.error('Authentication error: You are not authorized to access this resource.');
        } else {
          // Handle other errors
          console.error('An error occurred:', error.message);
        }
      });
  }
  useEffect(() => {
    getNewsDetail();
  }, []);
  const searcHandler = (e) => {
    return setSearch(e.target.value);

  }

  const columns =
    [
      {
        name: "SlNo",
        selector: (row, index) => index + 1,
        width: "80px",
      },
      {
        name: "Publish Date",
        sortable: true,
        cell: (row) => {
          //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
          return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{dateformat(row.publishdate)}</p>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.publishdate,
      },
      {
        name: "title",
        sortable: true,
        cell: (row) => {


          return <p>{row.title}</p>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.title,
      },

      {
        name: "Action",
        sortable: true,
        cell: (row) => {
          //const width = calculateWidth(row.endate); // Calculate the width based on content
          return (
            <>
              <div style={{ marginRight: '10px' }}>
                <ShowNewsModal id={row._id} token={token} />
              </div>
              <div style={{ marginRight: '10px' }}>
                <UpdateNews id={row._id} token={token} loadNews={getNewsDetail} />
              </div>
              {<div style={{ marginRight: '10px' }}>
                <DeleteNews id={row._id} />
              </div>}
            </>

          )
        },
      },

      /*{
        name: "Profile Image",
        sortable: true,
        cell: (row) => {
          return <p style={{ fontSize: '12px' }}><img src={`${config.apiBaseUrl}/public/uploadLeadershipImg/${row.imgfile}`} width={100} className="img-fluid" /></p>;
          // Wrap the content in a div with the calculated width
        },

      },*/



    ];
  const filterDate = () => {

    if (!fromDate || !toDate) {
      Swal.fire("Error", "Input FromDate and ToDate.", "error");
      return;
    }
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);
    if (fromDateObj > toDateObj) {
      Swal.fire("Error", "FromDate is greater then ToDate", "error");
      return;
    }

    const filteredData = newsItem.filter((row) => {
      const rowDate = new Date(row.createdAt);
      return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
    });

    setExportToXLSXData(filteredData);
    setFilterdNews(filteredData);
  };
  const exportToXLSX = () => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : newsItem;
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const excelFile = new Blob([excelBuffer], { type: fileType });
      const fileName = 'News' + fileExtension;
      saveAs(excelFile, fileName);
    } catch (error) {
      console.error('Error exporting to XLSX:', error);
    }

  };
  useEffect(() => {
    const result = newsItem.filter(data => {
      return (
        data.title.toLowerCase().match(search.toLowerCase())

      );

    });
    setFilterdNews(result);
  }, [search]);
  return (
    <>

      <div className='wrapper'>
        <HeaderDashboard />
        <SidebarDashboard />
        <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className='col-md-12 mt-2'>
                  <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                    <YourComponent sortActive={true}>
                      <DataTable customStyles={TableCustomStyles()} data={filterdNews} columns={columns}
                        title="News"
                        pagination
                        
                        fixedHeader
                        fixedHeaderScrollHeight='390px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <>

                            <Container>
                              <Row>
                                <Col className='p-1'>
                                  <Row className="align-items-center">
                                    <Col xs="auto">
                                      <Form.Label>From Date</Form.Label>
                                      <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                    </Col>
                                    <Col xs="auto">
                                      <Form.Label>To Date</Form.Label>
                                      <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                    </Col>

                                    <Col xs="auto">
                                      <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                        Serach
                                      </button>

                                    </Col>

                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col>
                                      <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                    </Col>
                                  </Row>
                                </Col>

                              </Row>
                            </Container>

                          </>
                        }
                        actions={<><AddNews loadNews={getNewsDetail} />
                          <Button className="btn btn-success" onClick={exportToXLSX} >
                            Export to XLSX
                          </Button>
                        </>}
                        progressPending={pending}
                      />
                    </YourComponent>
                  </StyleSheetManager>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <FooterDashboard />
    </>
  )
}

export default NewsPageDashboard
