import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import config from '../../../../config';

const UpdateNews = (props) => {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const [publishDate, setPublishDate] = useState('');
    const [title, setTitle] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const updateid = props.id;

    const token = localStorage.getItem('adminToken');
    const addby = localStorage.getItem('userID');
    
    const [editingRows, setEditingRows] = useState({});
    const [remotefile, setRemotefile] = useState([]);
    //const [editingImage, setEditingImage] = useState(false);
    const [tableData, setTableData] = useState([
        {
            slNo: 1,
            text: '',
            images: null,
            imagePath: '', // Add file state
            position: 'Center', // Add position state
            size: 'Full',
            editortype: 'Text'// Add size state
        },
    ]);

    const addRow = () => {
        const newRow = {
            slNo: tableData.length + 1,
            text: '',
            images: null,
            imagePath: '',
            position: 'Center',
            size: 'Full',
            editortype: 'Text', // Default to 'Text' for the new row
        };
        setTableData((prevData) => [...prevData, newRow]);
    };


    const deleteRow = (slNo) => {
        
        if (tableData.length === 1) {
            Swal.fire('You cannot delete the last row.');
        } else {
            const updatedData = tableData.filter((row) => row.slNo !== slNo);
            const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
                ...row,
                slNo: index + 1,
            }));
            setTableData(updatedDataWithRenumberedSlNo);
        }
    };
    const onChangeFile = (slNo, event) => {
        const updatedData = [...tableData];
        const file = event.target.files[0];



        if (file) {
            updatedData[slNo].images = file;
            console.log(updatedData[slNo].images);
        } else {
            console.error("No file selected.");
            // You can add more specific error handling or user feedback here.
        }
        console.log(updatedData)
        //setTableData((prevData) => [...prevData, updatedData])
        setTableData([...updatedData])
        //console.log(tableData)

    };
    useEffect(() => {
        showNewsDetail();
    }, [updateid]);
    const showNewsDetail = async () => {
        
        try {
            const response = await axios.get(
                `${config.apiUrl}/newsdetails/${updateid}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const newsgData = response.data.data;
            
            setItem(newsgData);
            setPublishDate(dateformat(newsgData.publishdate));
            setTitle(newsgData.title);
            const updatedTableData = newsgData.detail.map((details, index) => ({
                slNo: index + 1,
                text: details.text,
                images: null,
                imagePath: details.images.length > 0 ? details.images[0].imageUrl : "",
                position: details.images.length > 0 ? details.images[0].position : "",
                size: details.images.length > 0 ? details.images[0].size : "",
                editortype: details.editortype,
            }));
            const initialEditingRows = {}; // Initialize with an empty object
            updatedTableData.forEach((row) => {
                initialEditingRows[row.slNo] = false;
            });
            setEditingRows(initialEditingRows);

            //const hasTextEditor = updatedTableData.some(row => row.editortype);
            //setEditorTypes((prevTypes) => [...prevTypes, hasTextEditor ? 'Text' : 'Image']);
            
            setTableData(updatedTableData);


        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }

    const updateNewsHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        
        const updatedTableData = [...tableData];
        let formData = new FormData();
        const adddetails = []
       

        updatedTableData.forEach((row, i) => {

            

            const editorType = row.editortype;

            if (editorType === 'Image') {
                if (row.images) {
                    formData.append("images", row.images)
                    
                    

                    const imageData = {
                        imageName: row.images.name,
                        imagepath: '',
                        position: row.position,
                        size: row.size,

                    };
                    adddetails.push({ editortype: editorType, imageData }); // Use object notation
                }
                else {
                    const imageData = {
                        imageName: '',
                        imagepath: row.imagePath,
                        position: row.position,
                        size: row.size,

                    };
                    adddetails.push({ editortype: editorType, imageData });

                }
            }
            else if (editorType === 'Text') {
                adddetails.push({ editortype: editorType, text: row.text }); // Use object notation
            }
        });
        // Now, append 'adddetails' as a single field in FormData
        formData.append('adddetails', JSON.stringify(adddetails));
        formData.append("remotefile",remotefile);


        formData.append("publishdate", publishDate);
        formData.append("title", title);
        formData.append("addedby", addby);


        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        

        try {
            //setLoading(true);
            const response = await axios.put(
                `${config.apiUrl}/news/${updateid}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            //setCompanyName("");
            //setWebsiteLink("");
            Swal.fire({
                title: 'Saved!',
                text: `The leadership details  has been Saved Successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Submitted");
            handleClose();
            props.loadNews();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Submitted");
            console.error(error);
            Swal.fire("Error", "Error adding event.", "error");
        }

    }
    function dateformat(date) {
        const createdAtDate = new Date(date);
        const day = String(createdAtDate.getDate()).padStart(2, '0');
        const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
        const year = String(createdAtDate.getFullYear()).slice(-4);
        const newdate = `${year}-${month}-${day}`;
        return newdate;
    }

    const handleUpdateClick = (e) =>{
        showNewsDetail();
        handleShow();

    }
    return (
        <>
            <Button variant="primary" onClick={handleUpdateClick}>
                <i className="bi bi-pencil-square"></i>
            </Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Form onSubmit={updateNewsHandler} method="post" encType="multipart/form-data">
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Update News</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Publish Date <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Publish Date"
                                value={publishDate}
                                onChange={(e) => setPublishDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter News Title"
                                rows={3}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Details</Form.Label>
                            <Table responsive="sm" bordered={true}>
                                <thead style={{ backgroundColor: '#3572d4', color: 'white' }}>
                                    <tr>
                                        <th width="5%" height="5px">
                                            Sl#
                                        </th>
                                        <th width="15%" height="5px">
                                            Select Type
                                        </th>
                                        <th width="80%" style={{ textAlign: 'center' }}>
                                            News Details
                                        </th>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row, i) => (
                                        <tr key={row.slNo}>
                                            <td>{row.slNo}</td>
                                            <th width="5%" height="5px">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <Form.Select
                                                        value={row.editortype}
                                                        onChange={(e) => {
                                                            const updatedData = [...tableData];
                                                            updatedData[i].editortype = e.target.value;
                                                            setTableData(updatedData);
                                                        }}
                                                    >
                                                        <option value="Text">Text</option>
                                                        <option value="Image">Image</option>
                                                    </Form.Select>
                                                </Form.Group>

                                            </th>
                                            <td>

                                                {row.editortype === 'Text' ? (
                                                    <ReactQuill
                                                        value={row.text}
                                                        onChange={(newText) => {
                                                            const updatedData = [...tableData];
                                                            updatedData[i].text = newText;
                                                            setTableData(updatedData);
                                                        }}
                                                    />
                                                ) : row.editortype === 'Image' ? (
                                                    <>
                                                        {row.imagePath && !editingRows[row.slNo] ? (
                                                            <Row>
                                                                <Col>
                                                                    
                                                                    <Form.Label>Image</Form.Label>
                                                                    <p><img src={`${config.apiBaseUrl}/public/newsImage/${row.imagePath}`} className="img-fluid p-2" alt="" width={100} /></p>
                                                                </Col>
                                                                <Col>
                                                               
                                                                    <Form.Label>Position</Form.Label>
                                                                    <p>{row.position}</p>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Size</Form.Label>
                                                                    <p>{row.size}</p>

                                                                </Col>
                                                                <Col>
                                                                    <button
                                                                        className='btn btn-primary'
                                                                        onClick={() => {
                                                                            setEditingRows((prevEditingRows) => ({
                                                                                ...prevEditingRows,
                                                                                [row.slNo]: !prevEditingRows[row.slNo],
                                                                            }));
                                                                            //setRemotefile(row.imagePath);
                                                                            setRemotefile((prevData) => [...prevData, row.imagePath]);
                                                                        }}
                                                                    >
                                                                        Change
                                                                    </button>

                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Row>
                                                                <Col>
                                                                    <Form.Label>Image <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="file"
                                                                        accept=".jpg, .jpeg, .png, .gif"
                                                                        onChange={(e) => onChangeFile(i, e)}
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Postition <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                    <Form.Select
                                                                        value={row.position}
                                                                        onChange={(e) => {
                                                                            const updatedData = [...tableData];
                                                                            updatedData[row.slNo - 1].position = e.target.value;
                                                                            setTableData(updatedData);
                                                                        }}
                                                                    >
                                                                        <option value="Center">Center</option>
                                                                        <option value="Left">Left</option>
                                                                        <option value="Right">Right</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Size <span style={{ color: 'red' }}>*</span></Form.Label>
                                                                    <Form.Select
                                                                        value={row.size}
                                                                        onChange={(e) => {
                                                                            const updatedData = [...tableData];
                                                                            updatedData[row.slNo - 1].size = e.target.value;
                                                                            setTableData(updatedData);
                                                                        }}
                                                                    >
                                                                        <option value="Full">Full</option>
                                                                        <option value="Half">Half</option>
                                                                        <option value="One Fourth">One Fourth</option>
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </>

                                                ) : null}
                                            </td>
                                            <td>
                                            <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => deleteRow(row.slNo)}
                                                    >
                                                        <i className="bi bi-trash3"></i>
                                                    </button>
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div style={{ textAlign: 'right' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary mb-2"
                                    onClick={addRow}
                                >
                                    <i className="bi bi-plus"></i>
                                </button>
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={btnDisabled}>
                            {btnText}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default UpdateNews
