import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

const UpdateBlog = (props) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Save Changes");
  const token = localStorage.getItem('adminToken');
  const addby = localStorage.getItem('userID');
  const [item, setItem] = useState([]);
  const updateid = props.id;
  const [isError, setIsError] = useState(false);

    

  const [title, setTitle] = useState('');
  const [blogDetail, setBlogDetail] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  useEffect(() => {
    showBlogDetail();
  }, [updateid]);
  const showBlogDetail = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/ShowblogsByID/${updateid}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const BlogData = response.data.data;
      setItem(BlogData);
      setSelectedDate(BlogData.publishdate);
      setTitle(BlogData.title);
      setBlogDetail(BlogData.details);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error: You are not authorized to access this resource.');
      } else {
        console.error('An error occurred:', error.message);
      }
    }
  }
  function dateformat(date) {
    const createdAtDate = new Date(date);
    const day = String(createdAtDate.getDate()).padStart(2, '0');
    const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
    const year = String(createdAtDate.getFullYear()).slice(-4);
    const newdate = `${day}-${month}-${year}`;
    return newdate;
}
  const updateBlog=async (e)=>{
    e.preventDefault();
     if (blogDetail.trim().length === 0) {
      // Prevent form submission or show an error message
      setIsError(true);
      return;
    }
  
    setBtnText("Submitting...");
    setBtnDisabled(true);
  
    try {
      const response = await axios.put(
        `${config.apiUrl}/blogs/${updateid}`,
        {
          "publishdate": selectedDate,
          "title": title,
          "details": blogDetail,
          "addedby": addby,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      
  
      setBtnDisabled(false);
      setBtnText("Send");
  
      Swal.fire("Success", "Blog Update Successfully!", "success");
      handleClose();
      props.loadBlog();
    } catch (error) {
      setBtnDisabled(false);
      setBtnText("Send");
      Swal.fire("Error", "Error sending Data.", "error");
    }

  }
  const handleUpdateClick = (e) =>{
    showBlogDetail();
    handleShow();

}
  return (
    <>
      <Button variant="primary" onClick={handleUpdateClick}>
      <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Form method="post" onSubmit={updateBlog}>
          <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
            <Modal.Title>Update Blogs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formGroupEmail">

              <Form.Label>Publish Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <div className="row mx-1">

                <DatePicker
                  className="form-control"
                  //selected={dateformat(selectedDate)}
                  value={dateformat(selectedDate)}
                  onChange={handleDateChange}
                  placeholderText="MM-DD-YYYY"
                  required
                />

              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="textarea" aria-label="With textarea" placeholder='Title' required onChange={(e) => setTitle(e.target.value)} value={title} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Bolg Detail <span style={{ color: 'red' }}>*</span></Form.Label>
              <div>
                <ReactQuill
                  value={blogDetail}
                  onChange={(newTxt) => {setBlogDetail(newTxt)
                    setIsError(newTxt.trim().length === 0)}}

                  
                />
                {isError && <p style={{ color: 'red' }}>This field is required</p>}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              close
            </Button>
            <Button variant="primary" type='submit' disabled={btnDisabled}>{btnText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateBlog
