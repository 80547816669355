import React, { useState, useEffect } from 'react'
import FooterDashboard from '../FooterDashboard'
import HeaderDashboard from '../HeaderDashboard'
import SidebarDashboard from '../SidebarDashboard'
import DataTable from 'react-data-table-component'
import { TableCustomStyles } from './TableCustomStyle'
import { StyleSheetManager } from 'styled-components';
import AddLeadershipModal from './AddLeadershipModal'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import config from '../../../config';
import styled from 'styled-components'; // Import styled from styled-components at the top
import ShowLeadershipModal from './ShowLeadershipModal'
import UpdateLeadershipModal from './UpdateLeadershipModal'
import DeleteLeadershipModal from './DeleteLeadershipModal'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
const YourComponent = styled.div`
  // Your styles here
`;
const LeadershipDashboard = () => {

  const nevigate = useNavigate();

  const [leadershipItem, setLeadershipItem] = useState([]);
  const [pending, setPending] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [exportToXLSXData, setExportToXLSXData] = useState([]);
  const [filterdLeadership, setFilterdLeadership] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const isLogin = localStorage.getItem('isLogedIn');
    if (!isLogin) {
      nevigate('/login');
    }

  });

  const token = localStorage.getItem('adminToken');
  const getLeadershipDetail = async () => {
    setPending(true);
    await axios({
      method: 'get',
      url: `${config.apiUrl}/leadership`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        setPending(false);
        // Handle the response

        setLeadershipItem(response.data.data);
        setFilterdLeadership(response.data.data);

        //setFilterdEvents(response.data.data);
      })

      .catch(error => {
        setPending(false);
        if (error.response && error.response.status === 401) {
          // Unauthorized, handle accordingly (e.g., show login prompt)
          console.error('Authentication error: You are not authorized to access this resource.');
        } else {
          // Handle other errors
          console.error('An error occurred:', error.message);
        }
      });
  }
  useEffect(() => {
    getLeadershipDetail();
  }, []);
  const searcHandler = (e) => {
    return setSearch(e.target.value);

  }
  useEffect(() => {
    const result = leadershipItem.filter(data => {
      return (
        data.laeadershipname.toLowerCase().match(search.toLowerCase()) ||
        data.position.toLowerCase().match(search.toLowerCase()) ||
        data.details.toLowerCase().match(search.toLowerCase())


      );




    });
    setFilterdLeadership(result);
  }, [search]);

  const columns =
    [
      {
        name: "SlNo",
        selector: (row, index) => index + 1,
        width: "80px",


      },
      {
        name: "Name",
        sortable: true,
        cell: (row) => {
          //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
          return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{row.firstname + " " + row.lastname}</p>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.firstname,
      },
      {
        name: "Designation",
        sortable: true,
        cell: (row) => {


          return <p>{row.position}</p>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.position,
      },

      {
        name: "Details",
        sortable: true,
        cell: (row) => {
          //const width = calculateWidth(row.endate); // Calculate the width based on content
          const maxLength = 60; // Maximum character limit
          const truncatedName = row.details.length > maxLength
            ? row.details.slice(0, maxLength) + "..." // Truncate text and add ellipsis if it exceeds the limit
            : row.details;
          return <p>{truncatedName}</p>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.details,
      },

      {
        name: "Profile Image",
        sortable: true,
        cell: (row) => {
          return <p style={{ fontSize: '12px' }}><img src={`${config.apiBaseUrl}/public/uploadLeadershipImg/${row.imgfile}`} width={100} className="img-fluid" /></p>;
          // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.imgfile,

      },
      {
        name: "Action",
        sortable: true,
        cell: (row) => {
          //const width = calculateWidth(row.endate); // Calculate the width based on content
          return (
            <>
              <div style={{ marginRight: '10px' }}>
                <ShowLeadershipModal id={row._id} />
              </div>
              <div style={{ marginRight: '10px' }}>
                <UpdateLeadershipModal id={row._id} loadLeader={getLeadershipDetail} />
              </div>
              {<div style={{ marginRight: '10px' }}>
                <DeleteLeadershipModal id={row._id} />
              </div>}
            </>

          )
        },
      },


    ];
    const filterDate = () => {

      if (!fromDate || !toDate) {
          Swal.fire("Error", "Input FromDate and ToDate.", "error");
          return;
      }
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      if (fromDateObj > toDateObj) {
          Swal.fire("Error", "FromDate is Greater then ToDate", "error");
          return;
      }

      const filteredData = leadershipItem.filter((row) => {
          const rowDate = new Date(row.createdAt);
          return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
      });

      setExportToXLSXData(filteredData);
      setFilterdLeadership(filteredData);
  };
  const exportToXLSX = () => {
     
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

          try {
              let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : leadershipItem;
              const ws = XLSX.utils.json_to_sheet(dataToExport);
              const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
              const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
              const excelFile = new Blob([excelBuffer], { type: fileType });
              const fileName = 'leadership' + fileExtension;
              saveAs(excelFile, fileName);
          } catch (error) {
              console.error('Error exporting to XLSX:', error);
          }

  };

  return (
    <>
      <div className='wrapper'>
        <HeaderDashboard />
        <SidebarDashboard />
        <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className='col-md-12 mt-2'>
                  <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                    <YourComponent sortActive={true}>
                      <DataTable
                        columns={columns} data={filterdLeadership} customStyles={TableCustomStyles()}
                        title="LeaderShip"
                        pagination
                        paginationPerPage={5}
                        fixedHeader
                        fixedHeaderScrollHeight='390px'
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <>

                            <Container>
                              <Row>
                                <Col className='p-1'>
                                  <Row className="align-items-center">
                                    <Col xs="auto">
                                      <Form.Label>From Date</Form.Label>
                                      <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                    </Col>
                                    <Col xs="auto">
                                      <Form.Label>To Date</Form.Label>
                                      <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                    </Col>

                                    <Col xs="auto">
                                      <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                        Serach
                                      </button>

                                    </Col>

                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col>
                                      <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                    </Col>
                                  </Row>
                                </Col>

                              </Row>
                            </Container>

                          </>
                        }
                        actions={<>
                          <AddLeadershipModal loadLeader={getLeadershipDetail} />
                          <Button className="btn btn-success" onClick={exportToXLSX} >
                            Export to XLSX
                          </Button>
                        </>}
                        progressPending={pending}
                      />
                    </YourComponent>
                  </StyleSheetManager>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FooterDashboard />
      </div>

    </>
  )
}

export default LeadershipDashboard
