//import { Tooltip } from 'bootstrap'
import React from 'react'
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';
import JobDescriptionModal from './JobDescriptionModal';
import ReactLoading from 'react-loading';

function Career() {

    const [jobRole, setJobRole] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [details, setDetails] = useState("");
    const [base64Attachment, setBase64Attachment] = useState("");
    const [selectedFile, setSelectedFiles] = useState([]);
    const [preferredLocation, setPreferredLocation] = useState("");
    const [candidateName, setCandidateName] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Submit");
    const [JobpostingItem, setJobpostingItem] = useState([]);
    const [jobPostID, setJobPostID] = useState("");
    const [isLoading, setIsLoading] = useState(true);



    const onChangeFile = (e) => {
        const files = e.target.files;
        const file = files[0];

        setSelectedFiles(files);
        getBase64File(file);
    }

    const getBase64File = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setBase64Attachment(reader.result);
        }
    }

    const submitJobMailClick = async (e) => {
        e.preventDefault();
        let formData = new FormData(); // Create a FormData object

        if (selectedFile.length > 0) {
            let tempFile = selectedFile[0];

            if (tempFile.type !== "application/pdf") {
                Swal.fire("Warning", "Please attach resume in pdf format.", "warning");
                return false;
            }

            formData.append("filename", tempFile); // Append the file to FormData
        }
        formData.append("jobrole", jobRole);
        formData.append("candidatename", candidateName);
        formData.append("firstname", firstName);
        formData.append("midddlename", middleName);
        formData.append("lastname", lastName);
        formData.append("email", userEmail);
        formData.append("contactno", contactNo);
        formData.append("skillset", details);
        formData.append("preferredlocation", preferredLocation);
        formData.append("jobid", jobPostID);

        setBtnDisabled(true);
        setBtnText("Submitting...");
        for (const [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        try {
            const response = await axios.post(`${config.apiUrl}/career`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
                },
            });

            setFirstName("");
            setLastName("");
            setMiddleName("");
            setUserEmail("");
            setContactNo("");
            setDetails("");
            setPreferredLocation("");

            setBtnDisabled(false);
            setBtnText("Send");

            Swal.fire("Success", "Career saved Succesfully!", "success");
            fetch('https://5vqxa3ejd0.execute-api.ap-south-1.amazonaws.com/sendResumeMail', {
                mode: "no-cors",
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "first_name": firstName,
                    "last_name": lastName,
                    "user_email": userEmail,
                    "user_contact": contactNo,
                    "user_skillset": details,
                    "base64File": base64Attachment,
                   // "FileName": attachFileName,
                    "job_role": jobRole,
                    "middle_name": middleName,
                    "preferredLocation": preferredLocation,
                })
            })

                .catch(error => {

                });


            window.location.reload();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Send");
            Swal.fire("Error", "Error sending mail.", "error");
        }



        /*fetch('https://5vqxa3ejd0.execute-api.ap-south-1.amazonaws.com/sendResumeMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: {
                 "first_name": firstName,
                 "last_name": lastName,
                 "user_email": userEmail,
                 "user_contact": contactNo,
                 "user_skillset": details,
                 "base64File":base64Attachment,
                 "FileName":attachFileName,
                 "job_role": jobRole,
                 "middle_name":middleName,
                 "preferredLocation":preferredLocation,

            })
            }).then( response => {

                Swal.fire("Mail Sent.", "Thank you for your interest in joining SignatureIP .", "success");
                setBtnDisabled(false)
                setBtnText("Send")
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });*/


        e.target.reset();
    }
    const getJobposting = async () => {
        setIsLoading(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/showjobposting`,

        })
            .then(response => {
                // Handle the response

                setJobpostingItem(response.data.data);
                //setFilterdEvents(response.data.data);
                setIsLoading(false);
            })

            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getJobposting();
    }, []);
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Career</h2>
                    </div>

                </div>
            </section>

            <div className='container-fluid'>
                <div className="modal fade" id="jobApplyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header  modalHead-bg">
                                <h5 className="modal-title" id="">Apply Job</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={submitJobMailClick} encType="multipart/form-data" method="post">
                                    <div className="mb-3 mt-3">
                                        <h5 for="txtRole" className="form-label"><small>Job Role: </small> {jobRole}</h5>
                                    </div>

                                    <div class="row">
                                        <div class="col mb-3">
                                            <label for="txtFName" className="form-label">First Name <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName"
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                }}
                                                required />
                                        </div>
                                        <div class="col mb-3">
                                            <label for="txtMName" className="form-label">Middle Name</label>
                                            <input type="text" className="form-control" id="txtMName" placeholder="Middle Name" name="txtMName"
                                                onChange={(e) => {
                                                    setMiddleName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div class="col mb-3 ">

                                            <label for="txtLName" className="form-label">Last Name <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" id="txtLName" placeholder="Last Name"
                                                name="txtLName"
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                }}
                                                required />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <label for="txtEmail" className="form-label">Email <span className='text-danger'>*</span></label>
                                            <input type="email" className="form-control" id="txtEmail" placeholder="Email" name="txtEmail"
                                                onChange={(e) => {
                                                    setUserEmail(e.target.value);
                                                }}
                                                required />
                                        </div>
                                        <div class="col">

                                            <label for="txtContactNo" className="form-label">Contact No <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo"
                                                onChange={(e) => {
                                                    setContactNo(e.target.value);
                                                }}
                                                required />
                                        </div>
                                    </div>


                                    <div className="mb-3 mt-3">
                                        <label for="txtComment" className="form-label">Skill Set <span className='text-danger'>*</span></label>
                                        <textarea className="form-control" placeholder='Enter Skill Set' rows="5" id='txtComment'
                                            onChange={(e) => {
                                                setDetails(e.target.value);
                                            }}
                                            required
                                        ></textarea>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-7">
                                            <div className="mb-3">
                                                <label for="formFile" className="form-label">Resume <span className='text-danger'>*</span></label>
                                                <input className="form-control" type="file" id="formFile"
                                                    onChange={onChangeFile}
                                                    accept="application/pdf"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <label for="preferredlocation" className="form-label">Preferred Location <span className='text-danger'>*</span></label>
                                            <select className='form-select' id='preferredlocation' onChange={(e) => {
                                                setPreferredLocation(e.target.value)

                                            }} required>
                                                <option value={""}>Select Location</option>
                                                <option value={"USA (Milpitas)"}>USA (Milpitas)</option>
                                                <option value={"IN (Bhubaneswar)"}>IN (Bhubaneswar)</option>
                                                <option value={"PH (Manila)"}>PH (Manila)</option>
                                                <option value={"SG (Singapore)"}>SG (Singapore)</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4 offset-md-4'>

                                            <div class="d-grid gap-2 col-12 mx-auto">
                                                <button class="btn btn-primary" type="submit" className="btn btn-block btn-success" disabled={btnDisabled}>{btnText}</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 pt-4 pb-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5 className='mb-4'>Jump start your career with SignatureIP!</h5>
                                    <p>If you are looking to challenge yourself in a thriving environment that brings out innovative IP solutions for the customers, just send us your updated resume to resume@signatureip.ai{/*<span className='maillink3'><a href='https://outlook.office.com/mail/deeplink/compose?to=resume@signatureip.ai' target={'_blank'}>resume@signatureip.ai</a></span>*/}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                {isLoading ? (<>

                    <div className='col-md-12  pt-4 pb-4'>

                        <div className='d-flex justify-content-center align-items-center '>
                            <ReactLoading type={'spin'} color={'#007bff'} height={50} width={50} />
                        </div>


                    </div>


                </>) : (<>
                    {JobpostingItem.length === 0 ? (<>
                        <div className='col-md-12  pt-4 pb-4'>

                            <div className='d-flex justify-content-center align-items-center '>
                                <p>No Job Post is Available !</p>
                            </div>


                        </div>
                    </>) : (<>
                        <div className='row'>
                            <div className='col-md-12 pt-4 pb-4'>
                                <div className='container overflow-auto'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <table className='table table-bordered'>
                                                <thead className='table-dark'>
                                                    <tr>
                                                        <th>Sl#</th>
                                                        <th>Job Role</th>
                                                        <th>Location(s)</th>
                                                        <th>Experience(s)</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>{JobpostingItem.map((item, index) => (
                                                    item.isactive ? (
                                                        <tr key={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.jobrole}</td>
                                                            <td>
                                                                {
                                                                    item.joblocation.map(location => `${location.shortname} (${location.fullname})`).join(', ')
                                                                }
                                                            </td>
                                                            <td>{item.expriencefrom}-{item.exprienceto} Years</td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="groupButtons">
                                                                    <JobDescriptionModal id={item._id} />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success btn-sm"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#jobApplyModal"
                                                                        onClick={(e) => {
                                                                            setJobRole(item.jobrole);
                                                                            setJobPostID(item._id);

                                                                        }}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null
                                                ))}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}

                </>)}

            </div>
        </>
    )
}

export default Career