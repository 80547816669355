import FooterDashboard from '../../FooterDashboard'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

import { Form, Button } from 'react-bootstrap';

const ChangePassword = () => {
    const [userPassword, setUserPassword] = useState('');
    const [temPassword, setTemPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const token = localStorage.getItem('adminToken');
    const userID = localStorage.getItem('userID');
    
    const handelePassword= async (e)=>{
        
        e.preventDefault();
        if (temPassword !== userPassword) {
            Swal.fire('Error', 'Password and Conform password should be equal', 'error');
            return false;
          }
        try {
            //setLoading(true);
            const response = await axios.put(
              `${config.apiUrl}/changeOldPassword/${userID}`,
              {
                'updatepassword': userPassword,
                'oldPassword':oldPassword
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  
                },
              }
            );
      
      
            Swal.fire({
              title: 'Saved!',
              text: `Password has been changed Successfully`,
              icon: 'success',
            }).then(() => {
             // handleClose();
             setUserPassword("");
             setTemPassword("");
             setOldPassword("");
             window.location.reload();
            });
            // setBtnDisabled(false);
            //setBtnText("Submited");
            // props.loadEvents();
            //window.location.reload();
          } catch (error) {
            // setBtnDisabled(false);
            //setBtnText("Submited");
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.errors,
          });
          }

    }
    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className='row '>
                                <div className='col-md-12 mt-3'>
                                    <div className='col-md-6'>
                                        <Form onSubmit={handelePassword}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Old Password</Form.Label>
                                                <Form.Control type="password" placeholder="Old Password" onChange={(e) => setOldPassword(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control type="password" placeholder="New Password" onChange={(e) => setTemPassword(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                <Form.Label>Conform password</Form.Label>
                                                <Form.Control type="password" placeholder="Conform password" onChange={(e) => setUserPassword(e.target.value)}   />
                                            </Form.Group>
                                            <Button variant="primary" size="lg" active type='submit'>
                                               Submit
                                            </Button>
                                        </Form>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </div>
            <FooterDashboard />
        </>
    )
}

export default ChangePassword
