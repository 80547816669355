import React from 'react'
import {NavLink, useNavigate } from 'react-router-dom'

function Header() {
    const navigate = useNavigate();
    const loadProductPage = () =>{
        navigate('/Products');
    }
  return (

                    <nav className="navbar navbar-expand-lg navbar-dark bg-white fixed-top">
                    <div className="container-fluid">
                        <NavLink to="/">
                            <img src='./images/logo.png' alt='SignatureIP Logo' className='navbar-brand header-logo'/>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <i class="bi bi-list"></i>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Company </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container' >
                                                <div className="row  g-3" style={{"width":"100%"}}>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            {/*<h6 className="title">Company  <i class="bi bi-arrow-right-short"></i></h6>*/}
                                                            <ul className="list-unstyled">
                                                                <li><NavLink to="/AboutUs">About Us</NavLink></li>
                                                                <li><NavLink to="/Leadership">Leadership</NavLink></li>
                                                                <li><NavLink to="/Partnerships">Our Partners</NavLink></li>
                                                                <li><NavLink to="/Events">Events</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    {/*<div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <img src='./images/solutions.jpg' alt='SignatureIP' className='img-fluid'/>
                                                        </div>  
                                                    </div>*/}
                                                    
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>

                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="/NCNoc" data-bs-toggle="dropdown" onClick={loadProductPage}> Products</a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3" style={{"width":"100%"}}>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            {/*<h6 className="title">Company  <i class="bi bi-arrow-right-short"></i></h6>*/}
                                                            <ul className="list-unstyled">
                                                                <li><NavLink to="/INOCulator">iNoCulator<sup className='tm'>TM</sup></NavLink></li>
                                                                <li><NavLink to="/NCNoc">NC-NoC</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            {/*<h6 className="title">Company  <i class="bi bi-arrow-right-short"></i></h6>*/}
                                                            <ul className="list-unstyled">
                                                                <li><NavLink to="/CNoC">C-NoC</NavLink></li>
                                                                <li><NavLink to="/PCIe">PCIe Gen6 Controller</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    {/*<div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <img src='./images/solutions.jpg' alt='SignatureIP' className='img-fluid'/>
                                                        </div>  
                                                    </div>*/}
                                                    
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>

                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Signature's View </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3" style={{"width":"100%"}}>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            {/*<h6 className="title">Company  <i class="bi bi-arrow-right-short"></i></h6>*/}
                                                            <ul className="list-unstyled">
                                                                <li><NavLink to="/News">News</NavLink></li>
                                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>

                                {/*<li className="nav-item">
                                    <NavLink to="/Leadership" className="nav-link" data-target=".navbar-collapse.in">Leadership</NavLink>
                                                </li>*/}
                                {/*<li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Products</a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">
                                        <div className='container'>
                                            <div className="row g-3">  
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/INOCulator">
                                                        <img src='./images/icon-3.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title inoculator">iNoCulator<sup className='tm'>TM</sup> <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>SaaS-based EDA tool</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>  
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/NCNoc">
                                                        <img src='./images/icon-2.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">NC-NoC  <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p> Scalable & Physically-aware Configurable NoC: Coherent</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/CNoC">
                                                        <img src='./images/icon-1.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">C-NoC <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Scalable & Physically-aware Configurable NoC: Non-Coherent</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                
                                                
                                            </div>
                                            <div className='row g-3'>
                                                <div className="col-lg-4 col-md-4 col-sm-12 offset-md-2">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/CNoC">
                                                        <img src='./images/icon-1.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">PCIe Gen6 Controller <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Scalable & Physically-aware C</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/CNoC">
                                                        <img src='./images/icon-1.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">AI Accelerator <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Scalable & Physically-aware C</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                            </li>*/}
                                {/*
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Resource </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3">
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <h6 className="title">Resource  <i class="bi bi-arrow-right-short"></i></h6>
                                                            <ul className="list-unstyled">
                                                               
                                                                <li><NavLink to="/Events">Events</NavLink></li>
                                                                <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                                <li><NavLink to="/Downloads">Downloads</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    
                                                    <div className='col-lg-3 col-md-3'></div>
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="/Career" className="nav-link">Career</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Contact" className="nav-link">Contact</NavLink>
                                </li>
                                
                                {/*<li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Company </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow " role="menu">
                                        <div className='container'>
                                            <div className="row g-3">                                            
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">Our Company  <i class="bi bi-arrow-right-short"></i></h6>
                                                          <ul className="list-unstyled">
                                                            <li><NavLink to="/About">About Us</NavLink></li>
                                                            <li><NavLink to="/Career">Careers</NavLink> </li>
                                                            <li><NavLink to="/Events">Events</NavLink></li>
                                                            <li><NavLink to="/Locations">Locations</NavLink></li>
                                                            <li><NavLink to="/Partnerships">Partnerships</NavLink></li>
                                                            <li><NavLink to="/Downloads">Downloads</NavLink></li>
                                                        </ul>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">R & D <i class="bi bi-arrow-right-short"></i></h6>
                                                        <ul className="list-unstyled">
                                                            <li><a href="#">Artificial Intelligence</a></li>
                                                            <li><a href="#">Image & Video Processing </a></li>
                                                            <li><a href="#">Academia Relations</a></li>
                                                        </ul>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">Social <i class="bi bi-arrow-right-short"></i></h6>
                                                        <ul className="list-unstyled">
                                                            <li><a href="#">Newsletter</a></li>
                                                            <li><a href="#">Blog</a></li>
                                                        </ul>
                                                    </div>  
                                                </div>    
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <img src='./images/about.jpg' alt='SignatureIP' className='img-fluid'/>
                                                        <p className='pt-3'>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> IP Solutions</a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">
                                        <div className='container'>
                                            <div className="row g-3">    
                                                <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="#">
                                                        <img src='./images/digitalip.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">Digital IP  <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation.</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/AnalogIP">
                                                        <img src='./images/analogip.png' alt='SignatureIP' className='img-fluid'/>
                                                        <h6 className="title">Analog  IP <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>providing the best-in-class turnkey end-to-end SoC solution – </p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Products </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3">
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <h6 className="title">Solutions  <i class="bi bi-arrow-right-short"></i></h6>
                                                            <ul className="list-unstyled"> 
                                                                <li><NavLink to="/AIML">AI/ML</NavLink></li>
                                                                <li><a href="#">Storage</a></li>
                                                                <li><a href="#">Processors</a></li>
                                                                <li><a href="#">SoC</a></li>
                                                                <li><a href="#">Power Management</a></li>
                                                                <li><a href="#">Data Converters</a></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <img src='./images/solutions.jpg' alt='SignatureIP' className='img-fluid'/>
                                                            <p className='pt-3'>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                                    
                                                        </div>  
                                                    </div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>*/}
                            </ul>
                            {/* <ul className="navbar-nav ms-auto">
                                <li className="nav-item"><a className="nav-link" href="#"> Menu item </a></li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> Dropdown right </a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                    <li><a className="dropdown-item" href="#"> Submenu item 1</a></li>
                                    <li><a className="dropdown-item" href="#"> Submenu item 2 </a></li>
                                    </ul>
                                </li>
                            </ul> */}
                        </div>
                    </div> 
                    </nav>


  )
}

export default Header