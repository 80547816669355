import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FooterDashboard from '../FooterDashboard'
import HeaderDashboard from '../HeaderDashboard'
import SidebarDashboard from '../SidebarDashboard'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import config from '../../../config';
//import './TableCustomStyles.css';
import { TableCustomStyles } from './TableCustomStyle'

import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components'; // Import styled from styled-components at the top
import dateFormat from 'dateformat';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import ShowContact from './ShowContact'

const YourComponent = styled.div`
  // Your styles here
`;

function ContactDashboard() {
    const nevigate = useNavigate();
    const [contact, setContact] = useState([]);
    const [search, setSearch] = useState("");
    const [filterdContact, setFilterdContact] = useState([]);
    const token = localStorage.getItem('adminToken');
    const [pending, setPending] = React.useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [exportToXLSXData, setExportToXLSXData] = useState([]);

    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }
    });


    useEffect(() => {
        getcontact();
    }, []);

    useEffect(() => {
        // Load all data initially
        setFilterdContact(contact);
    }, [contact]);

    const getcontact = async () => {
        setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/Contact`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setPending(false);
                setContact(response.data.data);
                setFilterdContact(response.data.data);
            })

            .catch(error => {
                setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }

    const searchHandler = (e) => {
        return setSearch(e.target.value);
    }

    
    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            width: "80px",
            sortable: true,

        },
        {

            name: "Received On",
            cell: (row) => {
                const createdAtDate = new Date(row.createdAt);
                const formattedDate = dateFormat(createdAtDate, 'dd/mmm/yyyy h:MM TT');


                const width = calculateWidth(formattedDate); // Calculate the width based on content
                return <div style={{ width, fontSize: '12px' }}>{formattedDate}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.createdAt,
            sortable: true,
        },
        {
            name: "Name",

            cell: (row) => {
                const Name = row.firstname + " " + row.lastname;
                const width = calculateWidth(Name); // Calculate the width based on content
                return <div style={{ width, fontSize: '12px' }}>{Name}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => `${row.firstname} ${row.lastname}`,
            sortable: true,
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <ShowContact id={row._id} />
                        </div>
                        
                    </>

                )
            },
        },
        {

        }
    ]

    const calculateWidth = (cellContent, minWidth = 50, maxWidth = 150) => {
        // Calculate the width based on the content length
        const contentLength = cellContent.toString().length;
        const measuredWidth = Math.min(Math.max(minWidth, contentLength * 9), maxWidth); // Adjust the factor and limits as needed

        return `${measuredWidth}px`;
    };
    //effect use for login check.



    useEffect(() => {

        const result = contact.filter(data => {

            return (
                data.firstname.toLowerCase().match(search.toLowerCase()) ||
                data.lastname.toLowerCase().match(search.toLowerCase()) ||
                data.email.toLowerCase().match(search.toLowerCase()) ||
                data.jobtitle.toLowerCase().match(search.toLowerCase()) ||
                data.subject.toLowerCase().match(search.toLowerCase()) ||
                data.message.toLowerCase().match(search.toLowerCase())
            );




        });
        setFilterdContact(result);
    }, [search]);

    const filterDate = () => {

        if (!fromDate || !toDate) {
            Swal.fire("Error", "Input FromDate and ToDate.", "error");
            return;
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        if (fromDateObj > toDateObj) {
            Swal.fire("Error", "FromDate is greater then ToDate", "error");
            return;
        }

        const filteredData = contact.filter((row) => {
            const rowDate = new Date(row.createdAt);
            return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
        });

        // Use filteredData for display or export

        setExportToXLSXData(filteredData);
        setFilterdContact(filteredData);
    };

    const exportToXLSX = () => {
        //console.log(data)


        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        try {

            try {
                let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : contact;
                const ws = XLSX.utils.json_to_sheet(dataToExport);
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const excelFile = new Blob([excelBuffer], { type: fileType });
                const fileName = 'contact' + fileExtension;
                saveAs(excelFile, fileName);
            } catch (error) {
                console.error('Error exporting to XLSX:', error);
            }

        } catch (error) {
            console.error('Error exporting to XLSX:', error);
        }
    };

    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                                        <YourComponent sortActive={true}>
                                            <DataTable columns={columns} data={filterdContact} customStyles={TableCustomStyles()}
                                                title="Contact Lead"
                                                pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='390px'
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <>

                                                        <Container>
                                                            <Row>
                                                                <Col className='p-1'>
                                                                    <Row className="align-items-center">
                                                                        <Col xs="auto">
                                                                            <Form.Label>From Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <Form.Label>To Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                                                        </Col>

                                                                        <Col xs="auto">
                                                                            <Button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                                                                Serach
                                                                            </Button>

                                                                        </Col>

                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col></Col>
                                                                        <Col></Col>
                                                                        <Col>
                                                                            <input type="text" placeholder="Search Here" className="form-control mx-3" value={search} onChange={searchHandler} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </Container>

                                                    </>
                                                }
                                                progressPending={pending}
                                                actions={<Button className="btn btn-success" onClick={exportToXLSX} >
                                                    Export to XLSX
                                                </Button>}

                                            />
                                        </YourComponent>
                                    </StyleSheetManager>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <FooterDashboard />
            </div>

        </>
    )
}

export default ContactDashboard;
