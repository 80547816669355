//import { Tooltip } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import config from '../config'
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactLoading from 'react-loading';

function AboutUs() {
    const [membershipItem, setMembershipItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getMembership = async () => {
        setIsLoading(true);
        //setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/membershipMain`,

        })
            .then(response => {

                //setPending(false);
                setMembershipItem(response.data.data);
                //setFilterdPartner(response.data.data);
                setIsLoading(false);

            })

            .catch(error => {
                //setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getMembership();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>About Us</h2>
                        <ol>
                            <li>Company</li>
                            <li>About Us</li>
                        </ol>
                    </div>

                </div>
            </section>

            <div className='container-fluid' id='aboutUsBlockID'>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>About SignatureIP</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img src='./images/logo_short_494.png' alt='SignatureIP' className='img-fluid p-4' />
                                </div>
                                <div className='col-md-7 d-flex align-items-center justify-content-center'>
                                    <div className=''>
                                        <p className='text-justify'>
                                            After several years of incubation, SignatureIP was founded in 2021 to develop advanced Network-on-Chip (NoC) solutions that form the basis for a full platform for SoC design. SignatureIP features 120+ person-years of engineering leadership in interconnect, networking, datacenter, storage and connectivity IP, from specification to production. Our team has an extensive engineering background with expertise in interconnects, interfaces, bus protocols, CPUs and AI processing.
                                        </p>
                                        <p className='text-justify'>
                                            At SignatureIP, "we strive to speed SoC design" by making the design of the NoC – the backbone of the chip – fast, flexible and configurable. By enabling our customers to easily change the NoC topology, experiment with different configuration settings, and instantly simulate the results to measure throughput and latency, we make it possible to explore the design space at the top level of the chip before making major architectural decisions. When customers are ready to prototype or implement the NoC, our pushbutton RTL generation connects directly with customers’ EDA and FPGA environments. Our cloud-based tool architecture makes tool access easy and reduces the burden on their IT department.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-light pb-5'>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>Memberships</h3>
                                </div>
                            </div>
                            {isLoading ? (<><div className='col-md-12  pt-4 pb-4'>

                                <div className='d-flex justify-content-center align-items-center '>
                                    <ReactLoading type={'spin'} color={'#007bff'} height={50} width={50} />
                                </div>


                            </div></>) : (<>
                                <div className='row'>
                                    {membershipItem.map((memberItem, memberIndex) => (
                                        <div className='col-md-6' key={"col_1" + memberIndex}>
                                            <div className='membership-box' key={"memberbox" + memberIndex}>

                                                <a href={memberItem.websitelink} target={"_blank"} rel="noreferrer"><img src={`${config.apiBaseUrl}/public/membershipImg/${memberItem.imgfile}`} className="img-fluid p-4" /></a>
                                            </div>
                                        </div>
                                    ))}
                                </div></>)}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs