import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import dateFormat from 'dateformat';

const ShowEvent = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem('adminToken');
    const showEventId = props.id;
    const [eventItem, setEventItem] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    useEffect(() => {
        getUpdatedEvent();
    }, [showEventId]);

    const getUpdatedEvent = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/events/${showEventId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const eventData = response.data.data;
            const fromDate = new Date(eventData.startdate);
            const toDate = new Date(eventData.endate);
            setEventItem(eventData);

            setStartDate(dateFormat(fromDate, 'dd/mmm/yyyy h:MM TT'));
            setEndDate(dateFormat(toDate, 'dd/mmm/yyyy h:MM TT'));

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }
    const showData = () => {
        getUpdatedEvent();
        handleShow();

    }
    return (
        <>
            <Button variant="warning" onClick={showData} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}><i className="bi bi-eye"></i></Button>

            <Modal show={show} onHide={handleClose} animation={false} size="xl" >

                <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                    <Modal.Title>Show Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <>

                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                    Event Title :
                                </Form.Label>
                                <Col sm={10}>
                                    {eventItem.eventitle}
                                </Col>
                            </Form.Group>
                            {eventItem.eventtype === 'text' ? (<>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                        Event Details :
                                    </Form.Label>
                                    <Col sm={10}>
                                        {eventItem.details}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                    <Form.Label column sm={2}>
                                        Event Logo :
                                    </Form.Label>
                                    <Col sm={10}>
                                        {eventItem.imgfile === null ? (<>No Logo</>) : (<><img src={`${config.apiBaseUrl}/public/uploadImg/${eventItem.imgfile}`} width={100} className="img-fluid" /></>)}
                                    </Col>
                                </Form.Group></>) : eventItem.eventtype === 'image' ? (<>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                    <Form.Label column sm={2}>
                                        Event Image :
                                    </Form.Label>
                                    <Col sm={10}>
                                        {eventItem.imgfile === null ? (<>No Logo</>) : (<><img src={`${config.apiBaseUrl}/public/uploadImg/${eventItem.imgfile}`} width={100} className="img-fluid" /></>)}
                                    </Col>
                                </Form.Group>
                                </>) : null}


                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Start Date :
                                </Form.Label>
                                <Col sm={10}>
                                    {startDate}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    End Date :
                                </Form.Label>
                                <Col sm={10}>
                                    {endDate}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Event Venue :
                                </Form.Label>
                                <Col sm={10}>
                                    {eventItem.venue}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                     EventLink :
                                </Form.Label>
                                <Col sm={10}>
                                    {eventItem.calenderevent === "" ? (
                                        <p>No Eventlink</p>
                                    ) : (
                                        <>{eventItem.calenderevent}</>
                                    )}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Is Active :
                                </Form.Label>
                                <Col sm={10}>
                                    {eventItem.isactive === true ? (<>Yes</>) : (<>No</>)}
                                </Col>
                            </Form.Group>




                        </Form>

                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>


                </Modal.Footer>

            </Modal>

        </>
    )
}

export default ShowEvent
