import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import dateFormat from 'dateformat';

const ShowProductLead = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem('adminToken');
    const showProductID = props.id;
    const [productItem, setProductItem] = useState([]);
    const [recivedOn, setRecivedOn] = useState(null);
    useEffect(() => {
        showProduct();
    }, [showProductID]);

    const showProduct = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/productBriefContact/${showProductID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const productData = response.data.data;

            setProductItem(productData);
            const createdAtDate = new Date(productData.createdAt);
            const formattedDate = dateFormat(createdAtDate, 'dd/mmm/yyyy h:MM TT');
            setRecivedOn(formattedDate);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }
    const showData = () => {
        showProduct();
        handleShow();

    }

    return (
        <>
            <Button variant="warning" onClick={showData} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}><i className="bi bi-eye"></i></Button>
            <Modal show={show} onHide={handleClose} animation={false} size="xl" >

                <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                    <Modal.Title>Show Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <>

                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                    Received On :
                                </Form.Label>
                                <Col sm={10}>
                                    {recivedOn}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                    Name :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.contactname}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Email :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.email}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Company Name :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.companyname}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Job Title :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.jobtitle}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Subject :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.subject}
                                </Col>
                            </Form.Group>
                            
                            
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                <Form.Label column sm={2}>
                                    Message :
                                </Form.Label>
                                <Col sm={10}>
                                    {productItem.message}
                                </Col>
                            </Form.Group>




                        </Form>

                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>


                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ShowProductLead
