//import { Tooltip } from 'bootstrap'
import React from 'react'
import {NavLink} from 'react-router-dom'

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

function Gallery() {

    const images = [
        {
          original: './images/gallery/Gallery-1.jpeg',
          thumbnail: './images/gallery/Gallery-1.jpeg',
        },
        {
            original: './images/gallery/Gallery-2.jpeg',
            thumbnail: './images/gallery/Gallery-2.jpeg',
          },
      ];

  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Gallery</h2>
                <ol>
                    <li><NavLink to="/Home">Home</NavLink></li>
                    <li>Resource</li>
                    <li>Gallery</li>
                </ol>
                </div>

            </div>
        </section>
        
        <div className='container-fluid leadership'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 p-5'>
                            <ImageGallery items={images} autoPlay={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Gallery