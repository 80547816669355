//import { Tooltip } from 'bootstrap'
import React from 'react'
import {NavLink} from 'react-router-dom'

function SignatureViews() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Signature Views</h2>
                <ol>
                    <li><NavLink to="/Home">Home</NavLink></li>
                    <li>Resource</li>
                    <li>Signature Views</li>
                </ol>
                </div>

            </div>
        </section>
        
        <div className='container-fluid leadership'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <img src='./images/uc.jpeg' alt='SignatureIP' className='img-fluid p-4'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SignatureViews