import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import FooterDashboard from '../../FooterDashboard'
import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components'; // Import styled from styled-components at the top
import { TableCustomStyles } from '../TableCustomStyle';
import DataTable from 'react-data-table-component'
import axios from 'axios'
import config from '../../../../config';
import AddPartnersModal from './AddPartnersModal'
import UpdatePartnershipModal from './UpdatePartnershipModal'
import DeletePartnershipModal from './DeletePartnershipModal'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2'



const YourComponent = styled.div`
  // Your styles here
`;

const PartnershipDashboard = () => {
    const nevigate = useNavigate();
    const token = localStorage.getItem('adminToken');
    const [item, setItem] = useState([]);
    const [pending, setPending] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [exportToXLSXData, setExportToXLSXData] = useState([]);
    const [filterdPartner, setFilterdPartner] = useState([]);
    const [search, setSearch] = useState("");
    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }
    });
    const getPartnership = async () => {
        setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/partnership`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                setPending(false);
                setItem(response.data.data);
                setFilterdPartner(response.data.data);

            })

            .catch(error => {
                setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        getPartnership();
    }, []);
    const columns = [
        {
            name: "SlNo",
            selector: (row, index) => index + 1,
            width: "80px",

        },
        {
            name: "Company Name",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{row.companyname}</p>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.companyname,
        },
        {
            name: "Website Link",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                if (row.websitelink !== '') {
                    return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{row.websitelink}</p>;
                }
                else {
                    return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>No websitlink</p>;
                }
                // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.websitelink,
        },
        {
            name: "company Logo",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ fontSize: '12px' }}><img src={`${config.apiBaseUrl}/public/prtnershipImg/${row.imgfile}`} width={100} className="img-fluid" /></p>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.imgfile,
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <UpdatePartnershipModal id={row._id} loadPartnership={getPartnership} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <DeletePartnershipModal id={row._id} />
                        </div>
                    </>

                )
            },
        },
    ];
    const searcHandler = (e) => {
        return setSearch(e.target.value);

    }
    const filterDate = () => {

        if (!fromDate || !toDate) {
            Swal.fire("Error", "Input FromDate and ToDate.", "error");
            return;
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        if (fromDateObj > toDateObj) {
            Swal.fire("Error", "FromDate is greater then ToDate", "error");
            return;
        }

        const filteredData = item.filter((row) => {
            const rowDate = new Date(row.createdAt);
            return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
        });

        setExportToXLSXData(filteredData);
        setFilterdPartner(filteredData);
    };
    const exportToXLSX = () => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        try {
            let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : item;
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const excelFile = new Blob([excelBuffer], { type: fileType });
            const fileName = 'partner' + fileExtension;
            saveAs(excelFile, fileName);
        } catch (error) {
            console.error('Error exporting to XLSX:', error);
        }

    };
    useEffect(() => {
        const result = item.filter(data => {
          return (
            data.companyname.toLowerCase().match(search.toLowerCase()) ||
            data.websitelink.toLowerCase().match(search.toLowerCase()) ||
            data.imgfile.toLowerCase().match(search.toLowerCase())
    
    
          );
    
    
    
    
        });
        setFilterdPartner(result);
      }, [search]);
    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                                        <YourComponent sortActive={true}>
                                            <DataTable columns={columns} data={filterdPartner} customStyles={TableCustomStyles()}
                                                title="Pratnership"
                                                pagination
                                                
                                                fixedHeader
                                                fixedHeaderScrollHeight='390px'
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <>

                                                        <Container>
                                                            <Row>
                                                                <Col className='p-1'>
                                                                    <Row className="align-items-center">
                                                                        <Col xs="auto">
                                                                            <Form.Label>From Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <Form.Label>To Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                                                        </Col>

                                                                        <Col xs="auto">
                                                                            <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                                                                Serach
                                                                            </button>

                                                                        </Col>

                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col></Col>
                                                                        <Col></Col>
                                                                        <Col>
                                                                            <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </Container>

                                                    </>
                                                }
                                                actions={<><AddPartnersModal loadPartnership={getPartnership} />
                                                    <Button className="btn btn-success" onClick={exportToXLSX} >
                                                        Export to XLSX
                                                    </Button></>}
                                                progressPending={pending}
                                            />
                                        </YourComponent>
                                    </StyleSheetManager>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterDashboard />
            </div>
        </>
    )
}

export default PartnershipDashboard
