import FooterDashboard from '../../FooterDashboard'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components'; // Import styled from styled-components at the top
import { TableCustomStyles } from '../TableCustomStyle';
import DataTable from 'react-data-table-component'
import axios from 'axios'
import config from '../../../../config';
import AddJobPosting from './AddJobPosting'
import DeleteJobPosting from './DeleteJobPosting'
import UpdateJobPosting from './UpdateJobPosting'
import ShowJobPosting from './ShowJobPosting'
import ShowCandidate from './ShowCandidate'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2'
const YourComponent = styled.div`
  // Your styles here
`;

const JobPostingDashboard = () => {
    const nevigate = useNavigate();
    const token = localStorage.getItem('adminToken');
    const [pending, setPending] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [exportToXLSXData, setExportToXLSXData] = useState([]);
    const [filterdJob, setFilterdJob] = useState([]);
    const [search, setSearch] = useState("");
    const [jobpostingItem, setJobpostingItem] = useState([]);
    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }
    });
    const getJobposting = async () => {
        setPending(true);

        await axios({
            method: 'get',
            url: `${config.apiUrl}/jobposting`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                setPending(false)
                setJobpostingItem(response.data.data);
                setFilterdJob(response.data.data);

            })
            .catch(error => {
                setPending(false)
                if (error.response && error.response.status === 401) {

                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {

                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        getJobposting();
    }, []);
    const searcHandler = (e) => {
        return setSearch(e.target.value);

    }
    const columns = [
        {
            name: "SlNo",
            selector: (row, index) => index + 1,
            width: "80px",

        },
        {
            name: "Job Role",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{row.jobrole}</p>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.jobrole,
        },
        {
            name: "Job Location",
            sortable: true,
            cell: (row) => {
                const jobLocations = row.joblocation.map((item) => item.fullname);
                const commaSeparatedJobLocations = jobLocations.join(', ');
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{commaSeparatedJobLocations}</p>;

                // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.joblocation,
        },
        {
            name: "Exprience",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ fontSize: '12px' }}>{row.expriencefrom} - {row.exprienceto} </p>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.expriencefrom,
        },
        {
            name: "Added By",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.laeadershipname); // Calculate the width based on content
                return <p style={{ fontSize: '12px' }}>{row.addedby.email}  </p>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.addedby.email,
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <UpdateJobPosting id={row._id} token={token} loadJobPosting={getJobposting} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <ShowJobPosting id={row._id} token={token} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <DeleteJobPosting id={row._id} token={token} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <ShowCandidate id={row._id} token={token} />
                        </div>
                    </>

                )
            },
        },
    ];
    const filterDate = () => {

        if (!fromDate || !toDate) {
            Swal.fire("Error", "Input FromDate and ToDate.", "error");
            return;
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        if (fromDateObj > toDateObj) {
            Swal.fire("Error", "FromDate is greater then ToDate", "error");
            return;
        }

        const filteredData = jobpostingItem.filter((row) => {
            const rowDate = new Date(row.createdAt);
            return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
        });

        setExportToXLSXData(filteredData);
        setFilterdJob(filteredData);
    };
    const exportToXLSX = () => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        try {
            let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : jobpostingItem;
            //const jobLocations = dataToExport.joblocation.map((item) => item.fullname);
            const columnsToExport = dataToExport.map(row => {
                const jobLocations = row.joblocation.map(item => item.fullname).join(', '); // Concatenate fullname values
                return {
                    'Job Role': row.jobrole,
                    'Job Location': jobLocations,
                    'Experience': `${row.expriencefrom} - ${row.exprienceto}`,
                    'Addedby': row.addedby.email,
                    'CreatedAt':row.createdAt,
                    'updatedAt':row.updatedAt,
                    // Add more columns as needed in this format: 'Column Name': row.property
                };
            });
            const ws = XLSX.utils.json_to_sheet(columnsToExport);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const excelFile = new Blob([excelBuffer], { type: fileType });
            const fileName = 'job' + fileExtension;
            saveAs(excelFile, fileName);
        } catch (error) {
            console.error('Error exporting to XLSX:', error);
        }

    };
    useEffect(() => {
        const result = jobpostingItem.filter(data => {
            return (
                data.jobrole.toLowerCase().match(search.toLowerCase()) ||
                data.joblocation.toLowerCase().match(search.toLowerCase())

            );




        });
        setFilterdJob(result);
    }, [search]);
    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                                        <YourComponent sortActive={true}>
                                            <DataTable data={filterdJob} columns={columns} customStyles={TableCustomStyles()}
                                                title="Job Posting"
                                                pagination
                                                
                                                fixedHeader
                                                fixedHeaderScrollHeight='390px'
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <>

                                                        <Container>
                                                            <Row>
                                                                <Col className='p-1'>
                                                                    <Row className="align-items-center">
                                                                        <Col xs="auto">
                                                                            <Form.Label>From Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <Form.Label>To Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                                                        </Col>

                                                                        <Col xs="auto">
                                                                            <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                                                                Serach
                                                                            </button>

                                                                        </Col>

                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col></Col>
                                                                        <Col></Col>
                                                                        <Col>
                                                                            <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </Container>

                                                    </>
                                                }
                                                actions={<><AddJobPosting loadJobPosting={getJobposting} />
                                                    <Button className="btn btn-success" onClick={exportToXLSX} >
                                                        Export to XLSX
                                                    </Button>
                                                </>}
                                                progressPending={pending}
                                            />
                                        </YourComponent>
                                    </StyleSheetManager>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterDashboard />
        </>
    )
}

export default JobPostingDashboard
