/*import React, { useState } from 'react';
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    UpSquareOutlined,
} from '@ant-design/icons';
import logoImage from '../logo.png';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Speedometer } from 'react-bootstrap-icons';
import { Container, Row, Col } from 'react-bootstrap';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,

    };
}
const items = [
    getItem('DashBoard', '1', <Speedometer />),
    getItem('Employee', '2', <DesktopOutlined />),
    getItem('User', 'sub1', <UserOutlined />, [
        getItem('Tom', '3'),
        getItem('Bill', '4'),
        getItem('Alex', '5'),
    ]),
    getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    getItem('Files', '9', <FileOutlined />),
];
const Dashboard = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Sider>

                <Menu theme="dark" mode="inline" items={items} />

            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}

                >

                    <div className='container'>
                        <Row>
                            <Col sm={10}><img src={logoImage} alt='compny logo' height="50px" /></Col>
                            <Col sm={2}><p style={{ alignItem: 'left' }}>Amit Kumar Panda</p></Col>
                        </Row>
                        <div style={{ marginLeft: '20px' }}>


                        </div>

                    </div>
                </Header>
                <Content
                    style={{
                        margin: '0 16px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                    >
                        <Breadcrumb.Item>User</Breadcrumb.Item>
                        <Breadcrumb.Item>Bill</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 560,
                            background: colorBgContainer,
                        }}
                    >
                        Bill is a cat.
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Ant Design ©2023 Created by Ant UED
                </Footer>
            </Layout>
        </Layout>
    );
};
export default Dashboard;*/
import AppRouter from './AppRouter'
import ContentDashboard from './ContentDashboard'
import FooterDashboard from './FooterDashboard'
import HeaderDashboard from './HeaderDashboard'
import SidebarDashboard from './SidebarDashboard'
 import { useEffect } from 'react'
 import { useNavigate } from 'react-router-dom'

//window.location.reload(false);

function Dashboard (){
    
    const nevigate=useNavigate();
   
     useEffect(()=>{
         const isLogin=localStorage.getItem('isLogedIn');
        if(!isLogin)
        {
             nevigate('/login');
         }
     });
    //const history=useNavigate();
    /*useEffect(() => {
        // Check if the user is authenticated (e.g., by checking for a token)
         // Example: Storing the token in local storage

        if (!isLogin) {
        // Redirect to the login page or display an error message
        history('/login');
        }
    }, []);*/
    
  return (
    
    <div className='wrapper'>
      <HeaderDashboard/>
      <SidebarDashboard/>
      <ContentDashboard/>
      <FooterDashboard/>
    </div>
    
  )
}

export default Dashboard;
