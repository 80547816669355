//import { Tooltip } from 'bootstrap'
import React from 'react'
import {Link} from 'react-router-dom'
import ContactSales from '../Company/contactSales'
import DownloadProductInfo from '../Company/downloadProductInfo'

function PCIe() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>PCIe Gen6 Controller</h2>
                <ol>
                    <li>Products</li>
                    <li>PCIe Gen6 Controller</li>
                </ol>
                </div>

            </div>
        </section>
        {/*
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <p>
                                Our latest PCIe gen 6 controller IP, which is "NoC aware", provides a high-speed interface for efficient data transfer and system communication, supporting speeds of up to 64 GT/s. We are currently in the final stages of development and encourage interested customers to contact us for early access to take advantage of its advanced features and capabilities. Stay ahead of the competition and contact us today to learn more.
                                </p>
                            </div>
                            <div className='col-md-12 pb-5 pt-5'>
                                <img src='./images/pciegen6controller2.png' alt='iNoCulator GUI' className='img-fluid'/>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className='container-fluid p-5 pb-1'>
            <div className='row'>
                <div className="col-md-12 text-end">
                <DownloadProductInfo productName={"C-NoC"} produtFileName="PCIe_Gen6_Product_Brief v1.0.pdf" produtFileDownloadName="PCIe_Gen6_Product_Brief_v1.0.pdf" btnClasses={"btn btn-primary btn-sig"} />
                </div>
            </div>
        </div>

        <div className='container-fluid  pb-5'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <p className='text-justify'>
                                    Our latest PCIe gen 6 controller IP, which is "NoC aware", provides a high-speed interface for efficient data transfer and system communication, supporting speeds of up to 64 GT/s. We are currently in the final stages of development and encourage interested customers to contact us for early access to take advantage of its advanced features and capabilities. Stay ahead of the competition and contact us today to learn more.</p>
                                   {/* <button className='btn btn-outline-info'>Learn More</button> */}
                                </div>
                            </div>
                            <div className='col-md-6 p-3'>
                                <img src='./images/pciegen6controller2.png' alt='iNoCulator GUI' className='img-fluid imgblock'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContactSales productName={"PCIe Gen6 Controller"} />
    </>
  )
}

export default PCIe