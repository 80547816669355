// import React from 'react';
import './ForgotPassword.css';
import logo from './logo.jpg';
// import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import logoImage from './logo.png';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };


    const handleForgotPasswordClick = () => {
        if (isValidEmail(email)) {
            setMessage('Reset link sent to your email address.');
        } else {
            setMessage('Invalid email address.');
        }
    };

    const isValidEmail = (email) => {

        return /\S+@\S+\.\S+/.test(email);
    };
    return (
        <div className="forgot-password-container" style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '100vh' }} >


            

            <div className="container py-5 h-100">
                <div className="row ">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-white text-black" styles="border-radius: 1rem;">
                            <div className="card-body p-5 ">

                                <div className="mb-md-5 mt-md-4 pb-5">
                                    
                                    <img
                                        src={logoImage}
                                        alt="Company Logo"
                                        className="img-fluid mb-4"
                                        style={{ maxHeight: '100px' }}
                                    />
                                    <h2 className="fw-bold mb-2 text-uppercase text-center text-primary">Forgot Password</h2>
                                    <p className="fw-bold mb-2 text-uppercase mb-5 text-center text-primary">Enter your Email to get password reset link.

                                    </p>

                                    <div className="fw-bold mb-2 text-uppercase mb-3 text-left text-primary">
                                        <label className="form-label" for="typeEmailX">Email</label>
                                        <input placeholder='Please Enter Your Email'
                                            type="email"
                                            id="typeEmailX"
                                            className="form-control form-control-lg"
                                            value={email}
                                            onChange={handleEmailChange}
                                            required />


                                    </div>

                                    
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary btn-lg px-5 text-center text-white mt-3"
                                            type="button"
                                            onClick={handleForgotPasswordClick}
                                        >
                                            Forgot password
                                        </button>


                                        {message && <p className='p-2 mt-1'>{message}</p>}
                                    </div>






                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;