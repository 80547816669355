
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select from 'react-select';
import { Windows } from 'react-bootstrap-icons';
const UpdateJobPosting = (props) => {
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Save Changes");
  const [locationItem, setLocationItem] = useState([]);
  const [isActiveJobposting, setIsActiveJobposting] = useState(true)
  const [tableData, setTableData] = useState([
    {
      slNo: 1,
      skillRequirement: '',
    },
  ]);
  const [tableDataPreferredExpertise, setTableDataPreferredExpertise] = useState([
    {
      slNo: 1,
      preferredExpertise: '',
    },
  ]);
  const [jobrole, setJobrole] = useState("");
  const [joblocation, setJoblocation] = useState("");
  const [expriencefrom, setExprienceFrom] = useState("");
  const [exprienceto, setExprienceTo] = useState("");
  const [item, setItem] = useState([]);
  //const [locationItemUpdate,setLocationItemUpdate]=useState([]);
  const updateid = props.id;
  const token = props.token;
  const addby = localStorage.getItem('userID');
  useEffect(() => {
    showJobPostingDetail();
  }, [updateid]);

  const showJobPostingDetail = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/jobpostingdetails/${updateid}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const jobpostingData = response.data.data;
      

      setItem(jobpostingData);
      setJobrole(jobpostingData.jobrole);
      setExprienceFrom(jobpostingData.expriencefrom);
      setExprienceTo(jobpostingData.exprienceto);
      setIsActiveJobposting(jobpostingData.isactive);
      //setLocationItemUpdate(jobpostingData.joblocation);
      const selectedOptionsArray = jobpostingData.joblocation.map((location) => ({
        value: location._id,
        label: `${location.fullname} (${location.shortname})`,
      }));

      setSelectedOptions(selectedOptionsArray);

      //const preferredExpertiseArray = jobpostingData.preferredexpertise;

      //console.log(jobpostingData.preferredexpertise)
      
      setTableDataPreferredExpertise(
        jobpostingData.preferredexpertise.map((preferredExpertise, index) => ({
          slNo: index + 1, // Calculate the slNo based on the index
          preferredExpertise: preferredExpertise, // Set the preferredExpertise value
        }))
      );

      setTableData(
        jobpostingData.skillrequirement.map((skillrequirement, index) => ({
          slNo: index + 1, // Calculate the slNo based on the index
          skillRequirement: skillrequirement, // Set the preferredExpertise value
        }))
      );

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error: You are not authorized to access this resource.');
      } else {
        console.error('An error occurred:', error.message);
      }
    }
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UpdateJobPostingHandler = async (e) => {
    e.preventDefault();
    setBtnDisabled(true); // Disable the button
    setBtnText("Submitting...");
    const skillRequirements = tableData.map((row) => row.skillRequirement);
    const preferredExpertise = tableDataPreferredExpertise.map((row) => row.preferredExpertise);
    const selectedvalue = selectedOptions.map((row) => row.value);
    await axios.put(`${config.apiUrl}/jobposting/${updateid}`, {
      "jobrole": jobrole,
      "joblocation": selectedvalue,
      "expriencefrom": expriencefrom,
      "exprienceto": exprienceto,
      "skillrequirement": skillRequirements,
      "preferredexpertise": preferredExpertise,
      "addedby": addby,
      "isactive": isActiveJobposting

    }, {
      headers: {
        'Authorization': `Bearer ${token}`,

      },
    }).then(response => {


      setBtnText("Submitted");
      handleClose(); // This will close the modal
      Swal.fire("Success", "Job posting updated successfully!", "success");
      // Reload the page
      //window.location.reload();
      props.loadJobPosting();
    })
      .catch(error => {
        setBtnDisabled(false)
        setBtnText("Save Changes")
        Swal.fire("Error", "Error sending mail.", "error");
      });
   
    
  }
  const addRowPreferredExpertise = () => {
    const newRow = {
      slNo: tableDataPreferredExpertise.length + 1,
      preferredExpertise: '',
    };

    setTableDataPreferredExpertise([...tableDataPreferredExpertise, newRow]);
  };
  const deleteRowPreferredExpertise = (slNo) => {
   
    if (tableDataPreferredExpertise.length > 1) {
      const updatedData = tableDataPreferredExpertise.filter((row) => row.slNo !== slNo);
      const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
        ...row,
        slNo: index + 1,
      }));
      setTableDataPreferredExpertise(updatedDataWithRenumberedSlNo);
    } else {
      // Handle the case when there is only one row (prevent deletion)
      Swal.fire('You cannot delete the last row.');
    }
  };
  const addRow = () => {
    const newRow = {
      slNo: tableData.length + 1,
      skillRequirement: '',
    };
    setTableData([...tableData, newRow]);
  };
  const deleteRow = (slNo) => {
    if (tableData.length > 1) {
      const updatedData = tableData.filter((row) => row.slNo !== slNo);
      const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
        ...row,
        slNo: index + 1,
      }));
      setTableData(updatedDataWithRenumberedSlNo);
    } else {
      // Handle the case when there is only one row (prevent deletion)
      Swal.fire('You cannot delete the last row.');
    }
  };

  const getLocation = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}/companylocation`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        // Handle the response
        

        setLocationItem(response.data.data);
        //setFilterdEvents(response.data.data);
      })

      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Unauthorized, handle accordingly (e.g., show login prompt)
          console.error('Authentication error: You are not authorized to access this resource.');
        } else {
          // Handle other errors
          console.error('An error occurred:', error.message);
        }
      });
  }
  useEffect(() => {
    getLocation();
  }, []);

  const showIsActiveAlert = () => {
    // Show a confirmation dialog before updating
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to update the status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed to update the status
        setIsActiveJobposting(!isActiveJobposting);
      }
    });
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} size="xl">
        <Form onSubmit={UpdateJobPostingHandler} method="post">
          <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
            <Modal.Title>Update JobPosting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Job Role</Form.Label>
                <Form.Control type="text" placeholder="Enter Job Role" onChange={(e) => setJobrole(e.target.value)} value={jobrole} required />
              </Form.Group>


            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Location</Form.Label>
                <Select
                  isMulti
                  options={locationItem.map((item) => ({
                    value: item._id,
                    label: `${item.fullname} (${item.shortname})`,
                  }))}
              
                  value={selectedOptions}
                  onChange={(selected) => setSelectedOptions(selected)}
                />
                

              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Exprience From</Form.Label>
                <Form.Control type="number" placeholder="Enter Exprience From" onChange={(e) => setExprienceFrom(e.target.value)} required value={expriencefrom} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Exprience To</Form.Label>
                <Form.Control type="number" placeholder="Enter Exprience To" onChange={(e) => setExprienceTo(e.target.value)} required value={exprienceto}/>
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label className="d-block">Publish Job</Form.Label>
                <div>
                  <BootstrapSwitchButton
                    checked={isActiveJobposting}
                    onstyle="warning"
                    width={75}
                    onlabel="Yes"
                    offlabel="No"
                    style={{ fontWeight: 'bold' }}
                    onChange={showIsActiveAlert}
                  />
                </div>
              </Form.Group>
            </Row>
            <Table responsive="sm" bordered={true} >
              <thead style={{ backgroundColor: '#3572d4', color: 'white', }}>
                <tr>
                  <th width="5%" height='5px'>Sl#</th>
                  <th width="90%" style={{ textAlign: 'center' }}>Skill Requirement</th>
                  <th width="5%" height='5px'></th>

                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row.slNo}>
                    <td>{row.slNo}</td>
                    <td>
                      <Form.Control
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="enter Skill Requirement"
                        value={row.skillRequirement}
                        required
                        onChange={(e) => {
                          const updatedData = [...tableData];
                          updatedData[row.slNo - 1].skillRequirement = e.target.value;
                          setTableData(updatedData);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteRow(row.slNo)}
                      >
                        <i class="bi bi-trash3"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={addRow}
              >
                <i class="bi bi-plus"></i>
              </button>
            </div>
            <Table responsive="sm" bordered={true} >
              <thead style={{ backgroundColor: '#3572d4', color: 'white', }}>
                <tr>
                  <th width="5%" height='5px'>Sl#</th>
                  <th width="90%" style={{ textAlign: 'center' }}>Preferred Expertise</th>
                  <th width="5%" height='5px'></th>

                </tr>
              </thead>
              <tbody>
              
                {tableDataPreferredExpertise.map((row) => (
                 
                  <tr key={row.slNo}>
                    <td>{row.slNo}</td>
                    
                    <td>
                      <Form.Control
                        as="textarea"
                        aria-label="With textarea"
                        placeholder="enter Preferred Expertise"
                        value={row.preferredExpertise}
                        required
                        onChange={(e) => {
                          const updatedData = [...tableDataPreferredExpertise];
                          updatedData[row.slNo - 1].preferredExpertise = e.target.value;
                          setTableDataPreferredExpertise(updatedData);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteRowPreferredExpertise(row.slNo)}
                      >
                        <i class="bi bi-trash3"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={addRowPreferredExpertise}
              >
                <i class="bi bi-plus"></i>
              </button>
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={btnDisabled}>
              {btnText}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateJobPosting
