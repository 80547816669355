import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

const UpdateUser = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isChecked, setIsChecked] = useState(true);
  const [userPassword, setUserPassword] = useState('');
  const [autoGeneratedPassword, setAutoGeneratedPassword] = useState('');
  const token = localStorage.getItem('adminToken');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [temPassword, setTemPassword] = useState('');
  const updateId = props.id;
  const [userData, setUserData] = useState([])
  const generatePassword = () => {
    // Simple password generation logic (you can enhance it)
    return Math.random().toString(36).slice(-8);
  };
  useEffect(() => {
    if (isChecked) {
      const newPass = generatePassword();
      setAutoGeneratedPassword(newPass);
    }
  }, [isChecked]);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const newPass = generatePassword();
      console.log(newPass);
      setAutoGeneratedPassword(newPass);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    // setPending(true);
    await axios({
      method: 'get',
      url: `${config.apiUrl}/userDetailsById/${updateId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        /* setPending(false);
         setContact(response.data.data);
         setFilterdContact(response.data.data);*/

        setUserData(response.data.data);
        setUserName(response.data.data.name);
        setUserEmail(response.data.data.email);

      })

      .catch(error => {
        // setPending(false);
        if (error.response && error.response.status === 401) {
          // Unauthorized, handle accordingly (e.g., show login prompt)
          console.error('Authentication error: You are not authorized to access this resource.');
        } else {
          // Handle other errors
          console.error('An error occurred:', error.message);
        }
      });
  }
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const password = isChecked ? autoGeneratedPassword : userPassword;

    if (temPassword !== userPassword) {
      Swal.fire('Error', 'Password and Conform password should be equal', 'error');
      return false;
    }
    try {
      //setLoading(true);
      const response = await axios.put(
        `${config.apiUrl}/user/${updateId}`,
        {
          'name': userName,
          'email': userEmail,
          

        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            
          },
        }
      );


      Swal.fire({
        title: 'Saved!',
        text: `User has been Saved Successfully`,
        icon: 'success',
      }).then(() => {
        handleClose();
      });
      // setBtnDisabled(false);
      //setBtnText("Submited");
      props.loadUser();
      //window.location.reload();
    } catch (error) {
      // setBtnDisabled(false);
      //setBtnText("Submited");
      console.error(error);
      Swal.fire("Error", "Error adding user.", "error");
    }

  }

  const UpdateShowData = () => {
    handleShow();
    getUser();

  }
  return (
    <div>
      <Button variant="primary" onClick={UpdateShowData}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form onSubmit={handleUpdateUser}>
          <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formUserName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="User Name" required onChange={(e) => setUserName(e.target.value)} value={userName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formUserEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" required onChange={(e) => setUserEmail(e.target.value)} value={userEmail} />
            </Form.Group>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateUser
