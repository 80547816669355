import React from 'react'

function Banner() {
  return (
    <>

        <div id="demo" className="carousel slide main_carousel" data-bs-ride="carousel">

        <div className="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0"  className="active"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"  ></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="2" ></button>
        </div>       

        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src="./images/banner/banner-1.jpg" alt="SignatureIP Banner1" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-7'>
                        <h1 className='display-6 text-start banner-text'>SignatureIP – providing a configurable and flexible platform for SoC development</h1>
                    </div>
                </div>
                {/*<p className='blockquote'>Network-on-chip IP to design high performance SoCs</p>
                <button className='btn btn-outline-info'>Learn More</button>*/}
            </div>
            </div>
            <div className="carousel-item">
            <img src="./images/banner/banner-2.jpg" alt="SignatureIP Banner2" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-7'>
                        <h1 className='display-6 text-start banner-text'>Build your SoC’s Network-on-Chip with iNoCulator<sup className='tm'>TM</sup>, enabling fast and flexible NoC development</h1>
                    </div>
                </div>
                
            </div> 
            </div>
            <div className="carousel-item">
            <img src="./images/banner/banner-3.jpg" alt="SignatureIP Banner3" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-7'>
                        <h1 className='display-6 text-start banner-text'>Explore Your SoC’s Design Space by easily changing NoC topology and configuration and simulating on the fly</h1>
                    </div>
                </div>
                
            </div>  
            </div>
        </div>        

        <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <span className="carousel-control-prev-icon"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
            <span className="carousel-control-next-icon"></span>
        </button>
        </div>
    </>
  )
}

export default Banner