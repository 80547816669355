import React, { useState, useEffect } from 'react'
import FooterDashboard from '../../FooterDashboard'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import DataTable from 'react-data-table-component'
import { TableCustomStyles } from '../TableCustomStyle'
import { StyleSheetManager } from 'styled-components';
import AddLeadershipModal from '../AddLeadershipModal'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import AddUser from './AddUser'
import UpdateUser from './UpdateUser'
import DeleteUser from './DeleteUser'
import ResetUserPassword from './ResetUserPassword'
import dateFormat from 'dateformat';

const YourComponent = styled.div`
  // Your styles here
`;

const UserDashboard = () => {
    const nevigate = useNavigate();
    const [userData,setUserData]=useState([])
    const [pending, setPending] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [search, setSearch] = useState("");
    const [filterdUsers, setFilterdUser] = useState([]);

    const [exportToXLSXData, setExportToXLSXData] = useState([]);
    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }

    });
    const token = localStorage.getItem('adminToken');
    useEffect(() => {
        getUser();
    }, []);
    const getUser = async () => {
       setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/userDetails`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setPending(false);
               /* setContact(response.data.data);
                setFilterdContact(response.data.data);*/
                //console.log(response.data);
                setUserData(response.data.data);
            })

            .catch(error => {
                setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        // Load all data initially
        setFilterdUser(userData);
    }, [userData]);
    const hasOneTimePassword = userData.some(user => user.oneTimePassword);
    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            width: "80px",
            sortable: true,

        },
        {
            name: "Name",

            cell: (row) => {
                const Name = row.name;
                
                return <div style={{  fontSize: '12px' }}>{Name}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => `${row.name}`,
            sortable: true,
        },
        {
            name: "Email",

            cell: (row) => {
                const email = row.email;
                
                return <div style={{ fontSize: '12px' }}>{email}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => `${row.email}`,
            sortable: true,
        },
        
        /*{
            name: "OneTime Password",

            cell: (row) => {
                const oneTimePassword = row.oneTimePassword;
                 // Calculate the width based on content
                return <div style={{ fontSize: '12px' }}>{oneTimePassword}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => `${row.oneTimePassword}`,
            sortable: true,
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <UpdateUser id={row._id} loadUser={getUser} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <DeleteUser id={row._id} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <ResetUserPassword id={row._id}loadUser={getUser} />
                        </div>
                    </>

                )
            },
        },*/
    ]
    if (hasOneTimePassword) {
        columns.push({
            name: "OneTime Password",
            cell: (row) => <div style={{ fontSize: '12px' }}>{row.oneTimePassword}</div>,
            selector: (row) => `${row.oneTimePassword}`,
            sortable: true,
        });
    }
    columns.push({
        name: "Action",
        sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <UpdateUser id={row._id} loadUser={getUser} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <DeleteUser id={row._id} />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <ResetUserPassword id={row._id}loadUser={getUser} />
                        </div>
                    </>

                )
            },
        // ... [rest of the Action column configuration]
    });

    const searcHandler = (e) => {
        return setSearch(e.target.value);
    }
    useEffect(() => {

        const result = userData.filter(data => {

            return (
                data.name.toLowerCase().match(search.toLowerCase()) ||
                data.email.toLowerCase().match(search.toLowerCase()) 
                
            );




        });
        setFilterdUser(result);
    }, [search]);

    const filterDate = () => {

        if (!fromDate || !toDate) {
            Swal.fire("Error", "Input FromDate and ToDate.", "error");
            return;
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        if (fromDateObj > toDateObj) {
            Swal.fire("Error", "FromDate is greater then ToDate", "error");
            return;
        }

        const filteredData = userData.filter((row) => {
            const rowDate = new Date(row.createdAt);
            return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
        });

        // Use filteredData for display or export

        setExportToXLSXData(filteredData);
        setFilterdUser(filteredData);
    };

    const exportToXLSX = () => {
        //console.log(data)


        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        try {

            try {
                let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : userData;
                const ws = XLSX.utils.json_to_sheet(dataToExport);
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const excelFile = new Blob([excelBuffer], { type: fileType });
                const fileName = 'user' + fileExtension;
                saveAs(excelFile, fileName);
            } catch (error) {
                console.error('Error exporting to XLSX:', error);
            }

        } catch (error) {
            console.error('Error exporting to XLSX:', error);
        }
    };
    return (
        <div>
            <>
                <div className='wrapper'>
                    <HeaderDashboard />
                    <SidebarDashboard />
                    <div className="content-wrapper">
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className='col-md-12 mt-2'>
                                        <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                                            <YourComponent sortActive={true}>
                                                <DataTable
                                                   columns={columns} data={filterdUsers} customStyles={TableCustomStyles()}
                                                    title="User"
                                                    pagination
                                                    paginationPerPage={5}
                                                    fixedHeader
                                                    fixedHeaderScrollHeight='390px'
                                                    highlightOnHover
                                                    subHeader
                                                    subHeaderComponent={
                                                        <>

                                                            <Container>
                                                                <Row>
                                                                    <Col className='p-1'>
                                                                        <Row className="align-items-center">
                                                                            <Col xs="auto">
                                                                                <Form.Label>From Date</Form.Label>
                                                                                <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Form.Label>To Date</Form.Label>
                                                                                <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                                                            </Col>

                                                                            <Col xs="auto">
                                                                                <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                                                                    Serach
                                                                                </button>

                                                                            </Col>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col></Col>
                                                                            <Col></Col>
                                                                            <Col>
                                                                                <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                </Row>
                                                            </Container>

                                                        </>
                                                    }
                                                    actions={<>
                                                        <AddUser loadUser={getUser} />
                                                        {/*<AddLeadershipModal loadLeader={getLeadershipDetail} />*/}
                                                        <Button className="btn btn-success" onClick={exportToXLSX} >
                                                            Export to XLSX
                                                        </Button>
                                                    </>}
                                                progressPending={pending}
                                                />
                                            </YourComponent>
                                        </StyleSheetManager>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <FooterDashboard />
                </div>

            </>
        </div>
    )
}

export default UserDashboard
