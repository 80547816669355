//import { Tooltip } from 'bootstrap'
import React from 'react'
import {Link} from 'react-router-dom'
import DownloadProductInfo from '../Company/downloadProductInfo'
import ContactSales from '../Company/contactSales'

function NCNoc() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Non-Coherent NoC (NC-NoC)</h2>
                <ol>
                    <li>Products</li>
                    <li>NC-NoC</li>
                </ol>
                </div>

            </div>
        </section>

        <div className='container-fluid p-5 pb-1'>
            <div className='row'>
                <div className="col-md-12 text-end">
                <DownloadProductInfo productName={"NC-NoC"} produtFileName="NC_NOC_Product Brief_v1.4.pdf" produtFileDownloadName="NC-Noc.pdf" btnClasses={"btn btn-primary btn-sig"} />
                </div>
            </div>
        </div>
        
        <div className='container-fluid pb-5'>
            <div className='row'>
                <div className='col-md-12 pt-5 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div className='text-justify'>
                                    <p>NC-NoC is a layered, scalable, physically aware configurable NoC supporting multiple clocking schemes for SoCs that do not require coherency. NC-NoC supports multiple clocking schemes and multiple protocols such as AXI4/3, AHB, APB, AXI-lite and multiple bus widths from 32 to 2048 bits. It provides power control through power-islanding and a power-gating architecture at the interface port and router level.</p>
                                    <p>NC-NoC is physically aware, providing automated insertion and deletion of pipelines to meet timing, generation of placement-aware groups and topologies, and generation of power and frequency-aware NoC generation. </p>
                                    <p>NC-NoC delivers high performance though any-to-any connections at multiple levels. Its packetized architecture provides scalability with maximum throughput and minimum latency. It is parity enabled to provide resiliency.</p>
                            
                                </div>
                            </div>
                            <div className='col-md-4 text-center'>
                                <img src='./images/ncnoc1.png' alt='SignatureIP' className='img-fluid imgblock'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContactSales productName={"NC-NoC"} />

{/*
        <div className="suscribe-area">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs=12">
                    <div className="suscribe-text text-center">
                        <Link className="sus-btn" to="/Contact">Contact Sales</Link>
                    </div>
                </div>
                </div>
            </div>
        </div>*/}
    </>
  )
}

export default NCNoc