import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';

const AddBlogs = (props) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Save Changes");
  const token = localStorage.getItem('adminToken');
  const addby = localStorage.getItem('userID');

  const [title, setTitle] = useState('');
  const [blogDetail, setBlogDetail] = useState('');
  const [isError, setIsError] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handelAddBlog = async (e) => {
    e.preventDefault();
    if (blogDetail.trim().length === 0) {
      // Prevent form submission or show an error message
      setIsError(true);
      return;
    }

    setBtnText("Submitting...");
    setBtnDisabled(true);


    try {
      const response = await axios.post(
        `${config.apiUrl}/blogs`,
        {
          "publishdate": selectedDate,
          "title": title,
          "details": blogDetail,
          "addedby": addby,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      setSelectedDate(null);
      setTitle("");
      setBlogDetail("");

      setBtnDisabled(false);
      setBtnText("Send");

      Swal.fire("Success", "Blog Saved Successfully!", "success");
      handleClose();
      props.loadBlog();
    } catch (error) {
      setBtnDisabled(false);
      setBtnText("Send");
      Swal.fire("Error", "Error sending Data.", "error");
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add blogs
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Form method="post" onSubmit={handelAddBlog}>
          <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
            <Modal.Title>Add Blogs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formGroupEmail">

              <Form.Label>Publish Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <div className="row mx-1">

                <DatePicker
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="DD/MM/YYYY"
                  required
                />

              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="textarea" aria-label="With textarea" placeholder='Title' required onChange={(e) => setTitle(e.target.value)} value={title} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Bolg Detail <span style={{ color: 'red' }}>*</span></Form.Label>
              <div>
                <ReactQuill

                  onChange={(newTxt) => {
                    setBlogDetail(newTxt)
                    setIsError(newTxt.trim().length === 0)
                  }}

                  
                />
                {isError && <p style={{ color: 'red' }}>This field is required</p>}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              close
            </Button>
            <Button variant="primary" type='submit' disabled={btnDisabled}>{btnText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default AddBlogs
