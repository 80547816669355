//import { Tooltip } from 'bootstrap'
import { useState, useEffect } from 'react';
import {NavLink} from 'react-router-dom'
import axios from 'axios';
import config from '../config';

function News() {
    
    const [blogItem, setBlogItem] = useState([]);
    
    const getBlogDetail = async () => {
        await axios({
            method: 'get',
            url: `${config.apiUrl}/blogsinmainpage`
        })
            .then(response => {
                // Handle the response
                

                setBlogItem(response.data.data);
                //setFilterdEvents(response.data.data);
            })

            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        getBlogDetail();
    }, []);
    
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Blogs</h2>
                <ol>
                    <li>Signature's View</li>
                    <li>Blogs</li>
                </ol>
                </div>

            </div>
        </section>
        
        <div className='container-fluid leadership'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card blog-block'>
                                    
                                    <div className='card-body'>
                                    {blogItem.map((item)=>{
                                        const publishdate = new Date(item.publishdate);
                                        const publishday = publishdate.getDate();
                                        const Month = publishdate.toLocaleString('default', { month: 'short' });
                                        const year = publishdate.getFullYear();
                                        return(
                                        <div className='row'>

                                            <div className='col-md-12 blog-body'>
                                                <h6 className='text-end'><i className='bi bi-calendar-date-fill me-2'></i> {publishday}<sup>th</sup> {Month}, {year}</h6>
                                                <h4>{item.title}</h4>

                                                <p className='text-justify' dangerouslySetInnerHTML={{ __html: item.details }}></p>
                                                
                                            </div>
                                        </div>)})}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default News