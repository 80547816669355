import React, { useState } from 'react';
import { Link } from 'react-router-dom';


import logoImage from '../logo.png';

function SidebarDashboard() {
    const [activeMenu, setActiveMenu] = useState(null);
    
    const CheckEmail = localStorage.getItem('userEmail');
    const adminEmail = "pms@marqueesemi.com"; // Define the admin email

    const handleMenuClick = (index) => {
        setActiveMenu(index);
    };
    const SidebarData = [
        {
            Title: 'Contact Lead',
            Link: '/ContactDashboard',
            icon: 'nav-icon bi bi-envelope',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Sales Lead',
            Link: '/SalesContactDashboard',
            icon: 'nav-icon bi bi-person-fill-check',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Product Lead',
            Link: '/ProductBriefContact',
            icon: 'nav-icon bi bi-clipboard-plus',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Events',
            Link: '/EventsDashboard',
            icon: 'nav-icon bi bi-calendar2-event',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Leadership',
            Link: '/LeadershipDashboard',
            icon: 'nav-icon bi bi-person-badge',
            SubMenuIcon: '',
            submenu: [],
        },

        {
            Title: 'Partnership',
            Link: '/PartnershipDashboard',
            icon: 'nav-icon bi bi-building-fill-add',
            img: "/images/agreement.png",
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Job Posting',
            Link: '/JobPostingDashboard',
            icon: 'nav-icon bi bi-briefcase',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'News',
            Link: '/NewsPageDashboard',
            icon: 'nav-icon bi bi-newspaper',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Blogs',
            Link: '/BlogsPageDashboard',
            icon: 'nav-icon bi bi-book',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Address',
            Link: '/CompanyDetailDashboard',
            icon: 'nav-icon bi bi-geo-alt',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'Membership',
            Link: '/MembershipDashboard',
            icon: 'nav-icon bi bi-person-check-fill',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'User',
            Link: '/UserDashboard',
            icon: 'nav-icon bi bi-person',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        {
            Title: 'ChangePassword',
            Link: '/ChangePassword',
            icon: 'nav-icon bi bi-lock',
            img: '',
            SubMenuIcon: '',
            submenu: [],
        },
        /*{
            Title: 'Layout Options',
            Link: '/NewDashboardpage',
            icon: 'nav-icon fas fa-copy',
            SubMenuIcon: 'fas fa-angle-left right',
            submenu: [
                {
                    SubTitle: 'Top Navigation',
                    submenuicon: 'far fa-circle nav-icon',
                    SubLink: '/WidgetPage',
                },
                {
                    SubTitle: 'Top Navigation 2',
                    submenuicon: 'far fa-circle nav-icon',
                    SubLink: '',
                },
            ],
        },*/
    ];

    return (
        <>
            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
                <div className="sidebar">
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="true"
                        >
                            {SidebarData.map((item, index) => {
                                // Check if the current user is not admin and the item is 'User', skip rendering it
                                if (CheckEmail !== adminEmail && item.Title === 'User') {
                                    return null;
                                }

                                return (
                                    <li
                                        className={`nav-item ${activeMenu === index ? 'menu-open' : ''}`}
                                        key={index}
                                        onClick={() => handleMenuClick(index)}
                                    >
                                        <Link
                                            to={item.Link}
                                            className={`nav-link ${activeMenu === index ? 'active' : ''}`}
                                        >
                                            {item.icon !== '' ? (
                                                <i className={item.icon}></i>
                                            ) : (
                                                item.img && <img src={item.img} height={30} width={30} style={{ marginRight: '4px', color: 'white' }} alt="Icon" />
                                            )}
                                            <p>{item.Title}</p>
                                            {item.SubMenuIcon && <i className={item.SubMenuIcon} />}
                                        </Link>
                                        {/* Submenu rendering (if any) */}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    );
}

export default SidebarDashboard;
