//import { Tooltip } from 'bootstrap'
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import Accordion from 'react-bootstrap/Accordion';
import ReactLoading from 'react-loading';

function News() {
    const [newsItem, setNewsItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getNewsDetail = async () => {
        setIsLoading(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/showNewsMainPage`
        })
            .then(response => {
                // Handle the response


                setNewsItem(response.data.data);
                setIsLoading(false);
                //setFilterdEvents(response.data.data);
            })

            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getNewsDetail();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>News</h2>
                        <ol>
                            <li>Signature's View</li>
                            <li>News</li>
                        </ol>
                    </div>

                </div>
            </section>

            <div className='container-fluid leadership'>
                {isLoading ? (<>
                    <div className='col-md-12  pt-4 pb-4'>

                        <div className='d-flex justify-content-center align-items-center '>
                            <ReactLoading type={'spin'} color={'#007bff'} height={50} width={50} />
                        </div>


                    </div></>) : (<>
                        <div className='row'>
                            <div className='col-md-12 pt-4 pb-4'>
                                <div className='container'>
                                    <div className='row'>

                                        <Accordion>
                                            {newsItem.map((item, index) => {

                                                const publishdate = new Date(item.publishdate);
                                                const publishday = publishdate.getDate();
                                                const Month = publishdate.toLocaleString('default', { month: 'short' });
                                                const year = publishdate.getFullYear(); return (
                                                    <>
                                                        <Accordion.Item key={index} eventKey={index.toString()}>
                                                            <Accordion.Header style={{ paddingRight: '15px' }}>
                                                                <div className='col-md-2 news-dates'>

                                                                    <h6>{Month} {publishday}</h6>
                                                                    <h6>{year}</h6>
                                                                </div>
                                                                <div className='col-md-10 news-body'>
                                                                    <h6>{item.title}</h6>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='col-md-12'>
                                                                    <div className='card news-block'>
                                                                        <div className='card-body'>
                                                                            <div className='row'>
                                                                                <div className='col-md-2 news-dates'>

                                                                                    <h6>{Month} {publishday}</h6>
                                                                                    <h6>{year}</h6>
                                                                                </div>
                                                                                <div className='col-md-10 news-body'>
                                                                                    <h4>{item.title}</h4>



                                                                                    <div className='row'>
                                                                                        {item.detail && item.detail.map((detailItem, detailIndex) => {
                                                                                            const textContent = detailItem.text;
                                                                                            if (item.detail.length < 2) {
                                                                                                return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                            }


                                                                                            if (detailItem.images.length && detailItem.images.length > 0) {


                                                                                                return detailItem.images.map((image, imageIndex) => {
                                                                                                    //console.log(image.size)
                                                                                                    return <>

                                                                                                        {

                                                                                                            image.position === 'Center' ? <>
                                                                                                                {
                                                                                                                    <div className='col-md-12 text-center pb-2 pt-2'>


                                                                                                                        <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </> :
                                                                                                                image.position === 'Right' ? <>
                                                                                                                    {
                                                                                                                        image.size === 'Full' ? <div className='col-md-12'>
                                                                                                                            <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                        </div> : image.size === 'Half' ? <div className='col-md-6'>
                                                                                                                            <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                        </div> : image.size === 'One Fourth' ? <div className='col-md-4'>
                                                                                                                            <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                        </div> : null
                                                                                                                    }

                                                                                                                </> : null
                                                                                                        }
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                            else {

                                                                                                if (item.detail.length > 2) {
                                                                                                    if (detailIndex < item.detail.length - 2) {

                                                                                                        if (item.detail[detailIndex + 1].images.length > 0) {
                                                                                                            return item.detail[detailIndex + 1].images.map((image, imageIndex) => {
                                                                                                                return (image.position === 'Right' ? <>
                                                                                                                    {
                                                                                                                        image.size === 'Half' ? <div className='col-md-6'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                            : image.size === 'One Fourth' ?
                                                                                                                                <div className='col-md-8'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                                : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                    }

                                                                                                                </> : image.position === 'Center' ? <>
                                                                                                                    {
                                                                                                                        image.size === 'Half' ? <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                            : image.size === 'One Fourth' ?
                                                                                                                                <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                                : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                    }


                                                                                                                </> : image.position === 'Left' ? <>
                                                                                                                    {
                                                                                                                        image.size === 'Half' ? <> <div className='col-md-6'>
                                                                                                                            <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                        </div><div className='col-md-6'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div></>
                                                                                                                            : image.size === 'One Fourth' ?
                                                                                                                                <><div className='col-md-4'>
                                                                                                                                    <img src={`${config.apiBaseUrl}/public/newsImage/${image.imageUrl}`} className="img-fluid p-2 " alt="" />
                                                                                                                                </div><div className='col-md-8'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div></>
                                                                                                                                : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                    }


                                                                                                                </> : null)
                                                                                                            })
                                                                                                        }
                                                                                                        /*else if (detailIndex > 2 && item.detail[detailIndex + 1].images.length > 0) {
                                                                                                            return item.detail[detailIndex - 1].images.map((image, imageIndex) => {
                                                                                                                return (image.position === 'Left' ? <>
                                                                                                                    {
                                                                                                                        image.size === 'Half' ? <div className='col-md-6'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div> : image.size === 'One Fourth' ? <div className='col-md-4'>
                                                                                                                            <div className='col-md-8'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                        </div> : <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                                    }
                                                                                                                </> : null)
                                                                                                            })
                                                                                                        }*/
                                                                                                        else {
                                                                                                            return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                    }

                                                                                                }
                                                                                                else {
                                                                                                    return <div className='col-md-12'> <p className='text-justify' dangerouslySetInnerHTML={{ __html: textContent }}></p></div>
                                                                                                }


                                                                                            }

                                                                                        })}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>)
                                            })}
                                        </Accordion>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}

            </div>
        </>
    )
}

export default News