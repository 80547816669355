import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FooterDashboard from '../FooterDashboard'
import HeaderDashboard from '../HeaderDashboard'
import SidebarDashboard from '../SidebarDashboard'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import config from '../../../config';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { TableCustomStyles } from './TableCustomStyle'
import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components';
import Swal from 'sweetalert2'
import dateFormat from 'dateformat';
import ShowSalesLead from './ShowSalesLead'
const YourComponent = styled.div`
  // Your styles here
`;


const SalesContactDashboard = () => {
    const [salesContact, setSalesContact] = useState([]);
    const token = localStorage.getItem('adminToken');
    const nevigate = useNavigate();
    const [search, setSearch] = useState("");
    const [filterdSaleContact, setFilterdSaleContact] = useState([]);
    const [pending, setPending] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [exportToXLSXData, setExportToXLSXData] = useState([]);

    useEffect(() => {
        // Load all data initially
        setFilterdSaleContact(salesContact);
    }, [salesContact]);

    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }
    });

    const getsalesContact = async () => {
        setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/salesContact`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                // Handle the response
                setPending(false);


                setSalesContact(response.data.data);
                setFilterdSaleContact(response.data.data);
                //setFilterdContact(response.data.data);
            })

            .catch(error => {
                setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    //effct is used to show data in page loading.
    useEffect(() => {
        getsalesContact();
    }, []);
    /*const columns = [{
        name: "Sl No",
        selector: (row, index) => index + 1,
        width: "70px",

    },
    {
        name: "Received On",
        sortable: true,
        cell: (row) => {
            const createdAtDate = new Date(row.createdAt);
            const day = String(createdAtDate.getDate()).padStart(2, '0');
            const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
            const year = String(createdAtDate.getFullYear()).slice(-4);
            const hours = String(createdAtDate.getHours()).padStart(2, '0');
            const minutes = String(createdAtDate.getMinutes()).padStart(2, '0');
            //const amPm = createdAtDate.getHours() >= 12 ? 'PM' : 'AM';
            const formattedDate = `${day}/${month}/${year} (${hours}:${minutes})`;
            const width = calculateWidth(formattedDate); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px' }}>{formattedDate}</div>; // Wrap the content in a div with the calculated width
        },

    },
    {
        name: "Contact Name",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.salescontactname); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px', }}>{row.salescontactname}</div>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.salescontactname,

    },
    {
        name: "Contact Email",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.email); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px', }}>{row.email}</div>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.email,
    },
    {
        name: "Job Title",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.jobtitle); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px', }}>{row.jobtitle}</div>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.jobtitle,
    },
    {
        name: "subject",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.subject); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px', }}>{row.subject}</div>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.subject,

    },
    {
        name: "Message",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.message); // Calculate the width based on content
            return <pre style={{ width, fontSize: '12px', }}>{row.message}</pre>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.message,
    },
    {
        name: "Company Name",
        sortable: true,
        cell: (row) => {
            const width = calculateWidth(row.companyname); // Calculate the width based on content
            return <div style={{ width, fontSize: '12px', }}>{row.companyname}</div>; // Wrap the content in a div with the calculated width
        },
        selector: (row) => row.companyname,
    },
    {
        name: "Product",
        sortable: true,
        cell: (row) => {
            if (row.products.length === 0) {
                return <p>No Product</p>;
            } else {
                const listItems = row.products.map((product, index) => (
                    <li key={index}>{product}</li>
                ));
                const width = calculateWidth(row.products);

                return (
                    <ul style={{ width, fontSize: '12px' }}>
                        {listItems}
                    </ul>
                );
            }
        },
    }



    ];*/
    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            width: "80px",
            sortable: true,

        },
        {

            name: "Received On",
            cell: (row) => {
                const createdAtDate = new Date(row.createdAt);
                const formattedDate = dateFormat(createdAtDate, 'dd/mmm/yyyy h:MM TT');


                const width = calculateWidth(formattedDate); // Calculate the width based on content
                return <div style={{ width, fontSize: '12px' }}>{formattedDate}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => row.createdAt,
            sortable: true,
        },
        {
            name: "Name",

            cell: (row) => {
                const Name = row.firstname + " " + row.lastname;
                const width = calculateWidth(Name); // Calculate the width based on content
                return <div style={{ width, fontSize: '12px' }}>{Name}</div>; // Wrap the content in a div with the calculated width
            },
            selector: (row) => `${row.firstname} ${row.lastname}`,
            sortable: true,
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                //const width = calculateWidth(row.endate); // Calculate the width based on content
                return (
                    <>
                        <div style={{ marginRight: '10px' }}>
                            <ShowSalesLead id={row._id} />
                        </div>
                        
                    </>

                )
            },
        },
        {

        }
    ]

    const calculateWidth = (cellContent, minWidth = 50, maxWidth = 200) => {
        // Calculate the width based on the content length
        const contentLength = cellContent.toString().length;
        const measuredWidth = Math.min(Math.max(minWidth, contentLength * 9), maxWidth); // Adjust the factor and limits as needed

        return `${measuredWidth}px`;
    };
    const searcHandler = (e) => {
        return setSearch(e.target.value);

    }
    useEffect(() => {
        const result = salesContact.filter(data => {
            return (
                data.salescontactname.toLowerCase().match(search.toLowerCase()) ||
                data.email.toLowerCase().match(search.toLowerCase()) ||
                data.jobtitle.toLowerCase().match(search.toLowerCase()) ||
                data.subject.toLowerCase().match(search.toLowerCase()) ||
                data.message.toLowerCase().match(search.toLowerCase())
            );




        });
        setFilterdSaleContact(result);
    }, [search]);
    const filterDate = () => {

        if (!fromDate || !toDate) {
            Swal.fire("Error", "Input FromDate and ToDate .", "error");
            return;
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        if (fromDateObj > toDateObj) {
            Swal.fire("Error", "FromDate is greater then ToDate", "error");
            return;
        }

        const filteredData = salesContact.filter((row) => {
            const rowDate = new Date(row.createdAt);
            return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
        });

        // Use filteredData for display or export

        setExportToXLSXData(filteredData);
        setFilterdSaleContact(filteredData);
    };
    const exportToXLSX = () => {
        //console.log(data)


        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        try {
            let dataToExport = exportToXLSXData.length > 0 ? exportToXLSXData : salesContact;
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const excelFile = new Blob([excelBuffer], { type: fileType });
            const fileName = 'salesLead' + fileExtension;
            saveAs(excelFile, fileName);
        } catch (error) {
            console.error('Error exporting to XLSX:', error);
            Swal.fire("Error", "Error exporting to XLSX:", error);
        }
    };

    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('sortActive')}>
                                        <YourComponent sortActive={true}>
                                            {<DataTable columns={columns} data={filterdSaleContact} customStyles={TableCustomStyles()}
                                                title="Sales Lead"
                                                pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='390px'
                                                highlightOnHover
                                                subHeader
                                                subHeaderAlign='left'
                                                subHeaderComponent={
                                                    <>

                                                        <Container>
                                                            <Row>
                                                                <Col className='p-1'>
                                                                    <Row className="align-items-center">
                                                                        <Col xs="auto">
                                                                            <Form.Label>From Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setFromDate(e.target.value)} />
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <Form.Label>To Date</Form.Label>
                                                                            <Form.Control type="date" onChange={(e) => setToDate(e.target.value)} />
                                                                        </Col>

                                                                        <Col xs="auto">
                                                                            <button className="btn btn-primary btn-sm mt-4" onClick={filterDate}>
                                                                                Serach
                                                                            </button>

                                                                        </Col>

                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col></Col>
                                                                        <Col></Col>
                                                                        <Col>
                                                                            <input type="text" placeholder='Search Here' className='form-control mx-2' value={search} onChange={searcHandler} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </Container>

                                                    </>
                                                }
                                                progressPending={pending}
                                                actions={<Button className="btn btn-success" onClick={exportToXLSX} >
                                                    Export to XLSX
                                                </Button>}
                                            />}
                                        </YourComponent>
                                    </StyleSheetManager>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <FooterDashboard />
            </div>

        </>

    )
}

export default SalesContactDashboard
