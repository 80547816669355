import FooterDashboard from '../../FooterDashboard'
import HeaderDashboard from '../../HeaderDashboard'
import SidebarDashboard from '../../SidebarDashboard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { Form, Row, Col, Table, Button, Container } from 'react-bootstrap';

import axios from 'axios'
import config from '../../../../config';



const CompanyDetailDashboard = () => {
    const nevigate = useNavigate();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const token = localStorage.getItem('adminToken');
    const addby = localStorage.getItem('userID');
    const [companydetailsItem, setCompanydetailsItem] = useState([]);
    const [showPlusButton, setShowPlusButton] = useState(true);
    const [showPlusButtonEmail, setShowPlusButtonEmail] = useState(true);
    const [showPlusButtonAddress, setShowPlusButtonAddress] = useState(true);
    const [updateId, setUpdateId] = useState('');
    useEffect(() => {
        showCompanydetail();
    }, []);

    useEffect(() => {
        const isLogin = localStorage.getItem('isLogedIn');
        if (!isLogin) {
            nevigate('/login');
        }
    });
    const [tablePhoneNoData, setTablePhoneNoData] = useState([
        {
            slNo: 1,
            PhoneNoinfo: '',
        },
    ]);
    const [tableEmailData, setTableEmailData] = useState([
        {
            slNo: 1,
            emailinfo: '',
        },
    ]);
    const [tableAddressData, setTableAddressData] = useState([
        {
            slNo: 1,
            addressinfo: '',
        },
    ]);
    const addRow = () => {
        const newRow = {
            slNo: tablePhoneNoData.length + 1,
            PhoneNoinfo: '',
        };

        setTablePhoneNoData([...tablePhoneNoData, newRow]);
        setShowPlusButton(false);
    };
    const addRowEmail = () => {
        const newRow = {
            slNo: tableEmailData.length + 1,
            emailinfo: '',
        };

        setTableEmailData([...tableEmailData, newRow]);
        setShowPlusButtonEmail(false);
    };
    const addRowAddress = () => {
        const newRow = {
            slNo: tableAddressData.length + 1,
            addressinfo: '',
        };

        setTableAddressData([...tableAddressData, newRow]);
        setShowPlusButtonAddress(false);
    };
    const deleteRow = (slNo) => {

        console.log(tablePhoneNoData.length)
        if (tablePhoneNoData.length > 1) {
            const updatedData = tablePhoneNoData.filter((row) => row.slNo !== slNo);
            const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
                ...row,
                slNo: index + 1,
            }));


            setTablePhoneNoData(updatedDataWithRenumberedSlNo);
            if (tablePhoneNoData.length === 2) {
                // If the second row is deleted, show the "AddRow" button for the second row
                setShowPlusButton(true);
            }

        } else {
            //setShowPlusButton(true);
            // Handle the case when there is only one row (prevent deletion)
            Swal.fire('You cannot delete the last row.');
        }

    };
    const deleteRowEmail = (slNo) => {


        if (tableEmailData.length > 1) {
            const updatedData = tableEmailData.filter((row) => row.slNo !== slNo);
            const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
                ...row,
                slNo: index + 1,
            }));


            setTableEmailData(updatedDataWithRenumberedSlNo);
            if (tableEmailData.length === 2) {
                // If the second row is deleted, show the "AddRow" button for the second row
                setShowPlusButtonEmail(true);
            }

        } else {
            //setShowPlusButton(true);
            // Handle the case when there is only one row (prevent deletion)
            Swal.fire('You cannot delete the last row.');
        }

    };
    const deleteRowAddress = (slNo) => {


        if (tableAddressData.length > 1) {
            const updatedData = tableAddressData.filter((row) => row.slNo !== slNo);
            const updatedDataWithRenumberedSlNo = updatedData.map((row, index) => ({
                ...row,
                slNo: index + 1,
            }));


            setTableAddressData(updatedDataWithRenumberedSlNo);
            

        } else {
            //setShowPlusButton(true);
            // Handle the case when there is only one row (prevent deletion)
            Swal.fire('You cannot delete the last row.');
        }

    };
    const handleCompanyDetails = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        const phonenumber = tablePhoneNoData.map((item) => item.PhoneNoinfo)
        const emailaddress = tableEmailData.map((item) => item.emailinfo)
        const companyaddress = tableAddressData.map((item) => item.addressinfo)
        try {
            if (companydetailsItem.length > 0) {

                const response = await axios.put(
                    `${config.apiUrl}/companydetails/${updateId}`,
                    {
                        "phonenumber": phonenumber,
                        "emailaddress": emailaddress,
                        "addressdetail": companyaddress,
                        "addedby": addby,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setBtnDisabled(false);
                setBtnText("Send");

                Swal.fire("Success", "Company Details Update Successfully!", "success");
                //window.location.reload();
                handleCompanyDetails();

            }
            else {

                const response = await axios.post(
                    `${config.apiUrl}/companydetails`,
                    {
                        "phonenumber": phonenumber,
                        "emailaddress": emailaddress,
                        "addressdetail": companyaddress,
                        "addedby": addby,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setBtnDisabled(false);
                setBtnText("Send");

                Swal.fire("Success", "Company Details Saved Successfully!", "success");
                window.location.reload();
            }



        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Send");
            Swal.fire("Error", "Error sending Data.", "error");
        }
    }

    const showCompanydetail = async (e) => {

        try {
            const response = await axios.get(
                `${config.apiUrl}/companydetails`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const companydetailsData = response.data.data;
            
            setCompanydetailsItem(companydetailsData);
            setUpdateId(companydetailsData[0]._id);
            setTablePhoneNoData(
                companydetailsData[0].phonenumber.map((phonenumber, index) => ({
                    slNo: index + 1, // Calculate the slNo based on the index
                    PhoneNoinfo: phonenumber, // Set the preferredExpertise value
                }))
            );
            setTableEmailData(
                companydetailsData[0].emailaddress.map((emailaddress, index) => ({
                    slNo: index + 1, // Calculate the slNo based on the index
                    emailinfo: emailaddress, // Set the preferredExpertise value
                }))
            );
            setTableAddressData(
                companydetailsData[0].addressdetail.map((addressdetail, index) => ({
                    slNo: index + 1, // Calculate the slNo based on the index
                    addressinfo: addressdetail, // Set the preferredExpertise value
                }))
            );


        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }

    }
    return (
        <>
            <div className='wrapper'>
                <HeaderDashboard />
                <SidebarDashboard />
                <div className="content-wrapper">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <Form onSubmit={handleCompanyDetails}>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="2">
                                                Phone Nunmber
                                            </Form.Label>
                                            <Col sm="10" style={{border:'none'}}>
                                                <Table >

                                                    <tbody >

                                                        {tablePhoneNoData.map((row, index) => (
                                                            <tr key={row.slNo} style={{borderStyle: 'hidden'}} >

                                                                <td>


                                                                    <Form.Control
                                                                        type="text"
                                                                        aria-label="With textarea"
                                                                        placeholder="enter Phone number"
                                                                        value={row.PhoneNoinfo}
                                                                        required
                                                                        onChange={(e) => {
                                                                            const updatedData = [...tablePhoneNoData];
                                                                            updatedData[row.slNo - 1].PhoneNoinfo = e.target.value;
                                                                            setTablePhoneNoData(updatedData);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    {tablePhoneNoData.length===1 ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary mb-2 text-right"
                                                                            onClick={addRow}
                                                                        >
                                                                            <i class="bi bi-plus"></i>
                                                                        </button>
                                                                    ) : tablePhoneNoData.length>1 ?(
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={() => deleteRow(row.slNo)}
                                                                        >
                                                                            <i className="bi bi-trash3"></i>
                                                                        </button>
                                                                    ):null}
                                                                </td>

                                                            </tr>

                                                        ))}

                                                        {tablePhoneNoData.length > 1 ? (
                                                            <tr style={{borderStyle: 'hidden'}}>
                                                                <td></td>

                                                                <td>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mb-2 text-right"
                                                                        onClick={addRow}
                                                                    >
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>

                                                                </td>

                                                            </tr>
                                                        ) : null}

                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="2">
                                                Email Address
                                            </Form.Label>
                                            <Col sm="10">
                                                <Table>

                                                    <tbody>
                                                        {tableEmailData.map((row, index) => (
                                                            <tr key={row.slNo} style={{borderStyle: 'hidden'}}>

                                                                <td>

                                                                    <Form.Control
                                                                        type="email"
                                                                        aria-label="With textarea"
                                                                        placeholder="enter Email Address"
                                                                        value={row.emailinfo}
                                                                        required
                                                                        onChange={(e) => {
                                                                            const updatedData = [...tableEmailData];
                                                                            updatedData[row.slNo - 1].emailinfo = e.target.value;
                                                                            setTableEmailData(updatedData);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    { tableEmailData.length===1? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary mb-2"
                                                                            onClick={addRowEmail}
                                                                        >
                                                                            <i class="bi bi-plus"></i>
                                                                        </button>
                                                                    ) : tableEmailData.length>1?(
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={() => deleteRowEmail(row.slNo)}
                                                                        >
                                                                            <i className="bi bi-trash3"></i>
                                                                        </button>
                                                                    ):null}
                                                                </td>

                                                            </tr>

                                                        ))}
                                                        {tableEmailData.length > 1 ? (
                                                            <tr style={{borderStyle: 'hidden'}}>
                                                                <td></td>

                                                                <td>


                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mb-2 text-right"
                                                                        onClick={addRowEmail}
                                                                    >
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>

                                                                </td>

                                                            </tr>
                                                        ) : null}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="2">
                                                Address
                                            </Form.Label>
                                            <Col sm="10">
                                                <Table style={{ borderCollapse: 'collapse' }}>

                                                    <tbody>
                                                        {tableAddressData.map((row, index) => (
                                                            <tr key={row.slNo} style={{borderStyle: 'hidden'}}>

                                                                <td >

                                                                    <Form.Control
                                                                        as="textarea" rows={3}
                                                                        aria-label="With textarea"
                                                                        placeholder="enter Address"
                                                                        value={row.addressinfo}
                                                                        required
                                                                        onChange={(e) => {
                                                                            const updatedData = [...tableAddressData];
                                                                            updatedData[row.slNo - 1].addressinfo = e.target.value;
                                                                            setTableAddressData(updatedData);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {tableAddressData.length===1 ? (
                                                                         <button
                                                                         type="button"
                                                                         className="btn btn-primary mb-2 text-right"
                                                                         onClick={addRowAddress}
                                                                     >
                                                                         <i class="bi bi-plus"></i>
                                                                     </button>

                                                                    ): tableAddressData.length>1?(<button
                                                                        type="button"
                                                                        className="btn btn-danger"
                                                                        onClick={() => deleteRowAddress(row.slNo)}
                                                                    >
                                                                        <i className="bi bi-trash3"></i>
                                                                    </button>):null}
                                                                    
                                                                    
                                                                </td>

                                                            </tr>

                                                        ))}
                                                        
                                                            <tr style={{borderStyle: 'hidden'}}>
                                                                <td></td>
                                                                {tableAddressData.length>1&&(
                                                                <td>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mb-2 text-right"
                                                                        onClick={addRowAddress}
                                                                    >
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>

                                                                </td>)}

                                                            </tr>
                                                        
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Form.Group>
                                        <div className='col-md-12 text-center m-2 p-2'>
                                            <Button variant="primary" type="submit" className='text-center' disabled={btnDisabled}>{btnText}</Button>
                                        </div>



                                    </Form>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterDashboard />
        </>
    )
}

export default CompanyDetailDashboard
