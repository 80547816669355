import React from 'react'
import { Routes,Route } from 'react-router-dom'

import NewDashboardpage from './Pages/NewDashboardpage';
import ContactDashboard from './Pages/ContactDashboard'
import SalesContactDashboard from './Pages/SalesContactDashboard'
import ProductBriefContact from './Pages/ProductBriefContact'
import EventsDashboard from './Pages/EventsDashboard';
 import LeadershipDashboard from './Pages/LeadershipDashboard';
import PartnershipDashboard from './Pages/PartnershipDashboard/PartnershipDashboard';
import JobPostingDashboard from './Pages/JobPosting/JobPostingDashboard';
import NewsPageDashboard from './Pages/NewsPage/NewsPageDashboard';
import BlogsPageDashboard from './Pages/BlogsPages/BlogsPageDashboard';
import CompanyDetailDashboard from './Pages/CompanyDetail/CompanyDetailDashboard';
import MembershipDashboard from './Pages/Membership/MembershipDashboard';
import UserDashboard from './Pages/User/UserDashboard';

import ChangePassword from './Pages/User/ChangePassword';


const ContentDashboard = () => {
    
    return (
        <>
            
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            <Routes>
                                
                                <Route  path="/ContactDashboard" element={<ContactDashboard/>}></Route>
                                <Route  path="/SalesContactDashboard" element={<SalesContactDashboard/>}></Route>
                                <Route  path="/ProductBriefContact" element={<ProductBriefContact/>}></Route>
                                <Route  path="/EventsDashboard" element={<EventsDashboard/>}></Route>
                                <Route  path="/LeadershipDashboard" element={<LeadershipDashboard/>}></Route>
                                <Route  path="/PartnershipDashboard" element={<PartnershipDashboard/>}></Route>
                                <Route  path="/JobPostingDashboard" element={<JobPostingDashboard/>}></Route>
                                <Route  path="/NewsPageDashboard" element={<NewsPageDashboard/>}></Route>
                                <Route  path="/BlogsPageDashboard" element={<BlogsPageDashboard/>}></Route>
                                <Route  path="/CompanyDetailDashboard" element={<CompanyDetailDashboard/>}></Route>
                                <Route  path="/MembershipDashboard" element={<MembershipDashboard/>}></Route>
                                <Route  path="/UserDashboard" element={<UserDashboard/>}></Route>
                                <Route  path="/ChangePassword" element={<ChangePassword/>}></Route>
                            </Routes>
                            
                        </div>
                        
                        
                        
                    </div>
                </section>
                
                
            </div>
            

        </>
    )
}

export default ContentDashboard
