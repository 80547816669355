import React, { useState } from 'react';
import backgroundImage from './logo.jpg';
import logoImage from './logo.png';
import './SetPassword.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const SetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validateConfirmPassword(event.target.value);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(password)) {
      setMessage('Password must include at least one uppercase letter, one lowercase letter, one special character, one number, and be at least 8 characters long.');
    } else {
      setMessage('');
    }
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (confirmPassword !== password) {
      setMessage('Passwords do not match.');
    } else {
      setMessage('');
    }
  };

  const handleResetPasswordClick = () => {
    if (password === confirmPassword ) {
      // Simulate password reset logic
      // Replace with actual backend API call in a real implementation
      setMessage('Password reset successful');
    } else {
      setMessage('Passwords do not match or do not meet the criteria.');
    }
  };

  return (
    <div className="set-password-container container-fluid" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '100vh' }}>
      <div className="container py-5 h-100">
        <div className="row ">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-white text-black" style={{ borderRadius: '1rem' }}>
              <div className="card-body p-5 ">
                <div className="mb-md-5 mt-md-4 pb-5">
                  <img
                    src={logoImage}
                    alt="Company Logo"
                    className="img-fluid mb-4"
                    style={{ maxHeight: '200px' }}
                  />

                  <h2 className="fw-bold mb-2 text-uppercase text-center text-primary">Password Reset</h2>

                  <div className="fw-bold mb-2 text-uppercase mb-4 text-left text-primary">
                    <label className="form-label" htmlFor="typePasswordX">Password</label>
                    <input
                      placeholder='New Password'
                      type="password"
                      id="typePasswordX"
                      className={`form-control ${message ? 'is-invalid' : ''}`}
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                  </div>

                  <div className="fw-bold mb-2 text-uppercase mb-4 text-left text-primary">
                    <label className="form-label" htmlFor="typeConfirmPasswordX">Confirm Password</label>
                    <input
                      placeholder='Confirm Your Password'
                      type="password"
                      id="typeConfirmPasswordX"
                      className={`form-control ${message ? 'is-invalid' : ''}`}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary btn-lg px-5 text-center text-white p-2 mt-4"
                      type="button"
                      onClick={handleResetPasswordClick}
                    >
                      Reset Password
                    </button>
                    {message && <p className="invalid-feedback">{message}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;