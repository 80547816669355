import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';



const AddEventsModal = (props) => {
    //const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [detail, setDetail] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [venue, setVenue] = useState("");
    const [imgFile, setImagFile] = useState("");
    const [eventImgFile, setEventImagFile] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem('adminToken');
    const [calenderEventLink, setCalenderEventLink] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const [eventType, setEventType] = useState('text');

    
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }
    /*const onChangeImageFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }*/
    const addEventHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        // Check if endDate is greater than or equal to startDate
        if (endDateObj < startDateObj) {
            Swal.fire("Warning", "End date must be greater than or equal to start date.", "warning");
            return;
        }

        /* if (imgFile.length > 0) {
             let tempFile = imgFile[0];
             //console.log(tempFile.type);
 
 
             if (tempFile.type !== "image/png" && tempFile.type !== 'image/jpeg' && tempFile.type !== 'image/jpg' && tempFile.type !== 'image/gif') {
                 Swal.fire("Warning", "Please attach Image in .png or .jpeg or.jpg format.", "warning");
                 return false;
             }
 
             formData.append("imagefile", tempFile); // Append the file to FormData
         }
         /*if (eventImgFile.length > 0) {
             let tempFile = eventImgFile[0];
             //console.log(tempFile.type);
 
 
             if (tempFile.type !== "image/png" && tempFile.type !== 'image/jpeg' && tempFile.type !== 'image/jpg' && tempFile.type !== 'image/gif') {
                 Swal.fire("Warning", "Please attach Image in .png or .jpeg or.jpg format.", "warning");
                 return false;
             }
 
             formData.append("imgfile", tempFile); // Append the file to FormData
             
         }
 
         formData.append("eventitle", title);
         formData.append("details", detail);
         formData.append("startdate", startDate);
         formData.append("endate", endDate);
         formData.append("venue", venue);
         formData.append("calenderevent", calenderEventLink);*/
        formData.append("eventitle", title);
        formData.append("startdate", startDate);
        formData.append("endate", endDate);
        formData.append("venue", venue);
        formData.append("calenderevent", calenderEventLink);
        formData.append("eventtype", eventType); // Store the event type

        // Conditional data based on event type
        if (eventType === 'text') {
            formData.append("details", detail); // Text details

            if (imgFile.length > 0) {
                formData.append("imagefile", imgFile[0]); // Event logo
            }
        } else if (eventType === 'image') {
            if (imgFile.length > 0) {
                formData.append("imagefile", imgFile[0]); // Event image
            }
        }



        try {
            //setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/events`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setTitle("");
            setDetail("");
            setStartDate("");
            setEndDate("");
            setVenue("");
            Swal.fire({
                title: 'Saved!',
                text: `The event has been Saved Successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Submited");
            props.loadEvents();
            //window.location.reload();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error adding event.", "error");
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>Add Events</Button>

            <Modal show={show} onHide={handleClose} animation={false} size="xl" >
                <Form onSubmit={addEventHandler} encType="multipart/form-data" method="post">
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Add Events</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="mb-3">
                            <label className="form-label">Title <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Event Title" onChange={(e) => { setTitle(e.target.value) }} required />
                        </div>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="Text"
                                    name="eventType"
                                    type={type}
                                    id={`inline-${type}-Text`}
                                    onChange={() => setEventType('text')}
                                    checked={eventType==='text' ?true:false }
                                />
                                <Form.Check
                                    inline
                                    label="Image"
                                    name="eventType"
                                    type={type}
                                    id={`inline-${type}-Image`}
                                    onChange={() => setEventType('image')}
                                />

                            </div>
                        ))}
                        {eventType === 'text' ? (
                            <>
                                <div className="row g-3 my-3">
                                    <div className="col">
                                        <label className="form-label">Detail <span style={{ color: 'red' }}>*</span></label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Enter Event detail' onChange={(e) => { setDetail(e.target.value) }} required></textarea>
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Event Logo <span style={{ color: 'red' }}></span></label>
                                        <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} />
                                    </div>
                                </div>
                            </>
                        ) : eventType === 'image' ? (
                            <>
                                <div className="my-3">
                                    <label className="form-label">Event Image <span style={{ color: 'red' }}></span></label>
                                    <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} />
                                </div>
                            </>
                        ) : null}


                        <div className="row g-3">
                            <div className="col">
                                <label className="form-label">Start Date <span style={{ color: 'red' }}>*</span></label>
                                <input type="date" className="form-control" placeholder="Start Date" aria-label="Start Date" onChange={(e) => { setStartDate(e.target.value) }} required />
                            </div>
                            <div className="col">
                                <label className="form-label">End Date <span style={{ color: 'red' }}>*</span></label>
                                <input type="date" className="form-control" placeholder="End date" aria-label="End Date" onChange={(e) => { setEndDate(e.target.value) }} required />
                            </div>
                        </div>

                        <div className="my-3">
                            <label className="form-label">Venue <span style={{ color: 'red' }}>*</span></label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Venue" onChange={(e) => { setVenue(e.target.value) }} required></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label"> EventLink </label>
                            <input type="text" className="form-control" placeholder="Calender EventLink" onChange={(e) => { setCalenderEventLink(e.target.value) }} />
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {/*{<Button variant="primary" type='submit' disabled={btnDisabled}>
                            {btnText}
                        </Button>}*/}
                        <Button variant="primary" type='submit' >
                            submit
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>

        </>

    )
}

export default AddEventsModal;
