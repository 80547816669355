// import React, { useState } from 'react';
// import axios from 'axios';
// import config from '../config';

// function Login() {
//   const [email, setUsername] = useState('');
//   const [password, setPassword] = useState('');

//   const handleLogin = async () => {
//     try {
//       const response = await axios.post(`${config.apiUrl}/login`, { email, password });
//       console.log(response)
//       const token = response.data.token;
//       // You can store the token in local storage or a cookie for authentication
//       console.log('Login successful', token);
//     } catch (error) {
//       console.error('Login error', error.response.data.errors);
//     }
//   };

//   return (
//     <div>
//       <h1>Login</h1>
//       <div>
//         <input
//           type="text"
//           placeholder="Username"
//           value={email}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//       </div>
//       <div>
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//       </div>
//       <button onClick={handleLogin}>Login</button>
//     </div>
//   );
// }

// export default Login;

import React, { useState, useHistory } from 'react';
import './login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from './logo.jpg';
import { Link, BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import logoImage from './newlogo.png';
import axios from 'axios';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';
import config from '../config';






const Login = (setAuthenticated ) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const[sessionValue,setSessionValue]=useState('');

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const naviagte = useNavigate();



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitted(true);
        const validationErrors = {};

        if (!formData.email) {
            validationErrors.email = 'Email is required';
        }

        if (!formData.password) {
            validationErrors.password = 'Password is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            

            const response = await axios.post(`${config.apiUrl}/login`, formData);
               
                
                const tokendata = response.data.token;
                const userEmail=response.data.user.email;
                const userID=response.data.user._id;
                const currDate=new Date();
                const expireon=new Date(response.data.user.expireon);
                console.log(expireon)
           if(userEmail !== 'pms@marqueesemi.com' && currDate > expireon && response.data.user.oneTimePassword!=="")
            {
                    localStorage.setItem('adminToken',tokendata);
                    localStorage.setItem('userEmail',userEmail);
                    localStorage.setItem('userID',userID);
                    naviagte("/UserChangePassword");
            }
            else{
                if(tokendata)
                {
                    localStorage.setItem('adminToken',tokendata);
                    localStorage.setItem('userEmail',userEmail);
                    localStorage.setItem('userID',userID);
                    localStorage.setItem('isLogedIn',true);
                    naviagte("/ContactDashboard");
                }
            }
            

        } catch (error) {
            //console.log(error); 

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.errors,
            });

            //  alert('Invalid credentials');


        }
        
        //console.log('Login data:', formData);



    };

    // import React, { useState } from 'react';
    // import './login.css';
    // import 'bootstrap/dist/css/bootstrap.min.css';
    // import backgroundImage from './logo.jpg';
    // import { Link,  useNavigate } from 'react-router-dom';
    // import logoImage from './logo.png';
    // import axios from 'axios';
    // import 'sweetalert2/dist/sweetalert2.min.css';
    // import Swal from 'sweetalert2';
    // import config from '../config';

    // const Login = () => {
    //     // const history = useHistory(); // Allows us to navigate programmatically
    //     const navigate = useNavigate();
    //     const [formData, setFormData] = useState({
    //         email: '',
    //         password: '',
    //     });

    //     const [errors, setErrors] = useState({});
    //     const [submitted, setSubmitted] = useState(false);

    //     const handleChange = (e) => {
    //         const { name, value } = e.target;
    //         setFormData({ ...formData, [name]: value });
    //     };

    //     const handleSubmit = async (e) => {
    //         e.preventDefault();

    //         setSubmitted(true);
    //         const validationErrors = {};

    //         if (!formData.email) {
    //             validationErrors.email = 'Email is required';
    //         }

    //         if (!formData.password) {
    //             validationErrors.password = 'Password is required';
    //         }

    //         if (Object.keys(validationErrors).length > 0) {
    //             setErrors(validationErrors);
    //             return;
    //         }

    //         try {
    //             const response = await axios.post('http://localhost:8000/api/login', formData);

    //             const token = response.data.token;

    //             // Store the token in local storage
    //             localStorage.setItem('adminToken', token);

    //             Swal.fire({
    //                 icon: 'success',
    //                 title: 'Success',
    //                 text: response.data.message,
    //             });

    //             // Redirect to a protected route after successful login
    //             navigate.push('/dashboard'); // Change to your desired route

    //         } catch (error) {
    //             console.error('Error:', error.response.data.errors); 
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: error.response.data.errors,
    //             });
    //         }
    //     };
    

    return (
        <>
            <section className="h-100 gradient-form" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '100vh' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-left align-items-center h-100">
                        <div className="col-xl-5">
                            <div className="card rounded-3 text-black">
                                <div className="row g-0">
                                    <div className="col-lg-12">
                                        <div className="card-body">

                                            <div className="text-center">
                                                <img src={logoImage}
                                                    style={{ width: '185px' }} alt="logo" />

                                            </div>

                                            <form onSubmit={handleSubmit}>
                                                <p style={{textAlign:'center',marginTop:'20px'}}>Please login to your account</p>

                                                <div className="form-outline mb-4">

                                                    <label className="form-label">Username</label>
                                                    <input
                                                        placeholder='Please Enter Your Email'
                                                        type="email"
                                                        id='typeEmailX'
                                                        className="form-control"
                                                        required
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    {submitted && errors.email && <div className="text-danger">{errors.email}</div>}
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="typePasswordX">Password</label>
                                                    <input
                                                        placeholder='Please Enter Your Password'
                                                        type="password"
                                                        id="typePasswordX"
                                                        className="form-control"
                                                        required
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                    {submitted && errors.password && <div className="text-danger">{errors.password}</div>}
                                                </div>

                                                <div className="text-center pt-1 mb-5 pb-1">
                                                    <button className="btn btn-primary text-center text-white col-md-12" type="submit">Login</button>
                                                    <div className="mt-3">
                                                        <Link to="/forgot-password" className="text-muted text-decoration-none">
                                                            <span className="text-danger">Forgot Password?</span>
                                                        </Link>
                                                    </div>
                                                </div>



                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    );
}

export default Login;