import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

const UpdateMembership = (props) => {
    const token = localStorage.getItem('adminToken');
    const addby = localStorage.getItem('userID');
    const membershipID = props.id;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgFile, setImagFile] = useState(null);
    const [displayOrder, setDisplayOrder] = useState(0);
    const [membershipItem, setMembershipItem] = useState([]);
    const [websitelink, setWebsitelink] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");

    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }

    const getMembership = async () => {

        //setPending(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/membership/${membershipID}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {

                //setPending(false);

                setMembershipItem(response.data.data);
                setDisplayOrder(response.data.data.displayorder);
                setWebsitelink(response.data.data.websitelink);
                //setFilterdPartner(response.data.data);

            })

            .catch(error => {
                //setPending(false);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        getMembership();
    }, [membershipID]);

    const updateMembershipHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        if (imgFile) {
            let tempFile = imgFile[0];
            //console.log(tempFile.type);


            if (tempFile.type !== "image/png" && tempFile.type !== 'image/jpeg' && tempFile.type !== 'image/jpg' && tempFile.type !== 'image/gif') {
                Swal.fire("Warning", "Please attach Image in .png or .jpeg or.jpg format.", "warning");
                return false;
            }

            formData.append("imgfile", tempFile);
            // Append the file to FormData
        }
        formData.append("displayorder", displayOrder);
        formData.append("websitelink", websitelink);
        formData.append("addedby", addby);
        try {
            //setLoading(true);
            const response = await axios.put(
                `${config.apiUrl}/membership/${membershipID}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setDisplayOrder("");
            Swal.fire({
                title: 'Update!',
                text: `Membership details  has been updated successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Send");
            props.loadMembership();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Send");

            console.error(error);
            Swal.fire("Error", "Error adding Pratnership details.", "error");
        }


    }
    const showData = () => {
        getMembership();
        handleShow();
    }
    return (
        <>
            <Button variant="primary" onClick={showData}>
                <i className="bi bi-pencil-square"></i>
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size="lg">
                <Form onSubmit={updateMembershipHandler} encType="multipart/form-data" method="post">
                    <Modal.Header style={{ backgroundColor: '#3572d4', color: 'white' }} closeButton>
                        <Modal.Title>Update Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Membership Image</Form.Label>
                            <Form.Control type="file" accept=".jpg, .jpeg, .png .gif" onChange={onChangeFile} />
                        </Form.Group>
                        <div className='m-2'>
                            <img src={`${config.apiBaseUrl}/public/membershipImg/${membershipItem.imgfile}`} width={100} className="img-fluid" />

                        </div>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Label>Display Order</Form.Label>
                            <Form.Control type="number" placeholder="Enter Order" onChange={(e) => { setDisplayOrder(e.target.value) }} value={displayOrder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formWebsiteLink">
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control type="text" placeholder="Enter Website Link" onChange={(e) => { setWebsitelink(e.target.value) }} value={websitelink} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={btnDisabled}>
                            {btnText}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default UpdateMembership
