//import { Tooltip } from 'bootstrap'
import React from 'react'
import {Link} from 'react-router-dom'
import DownloadProductInfo from '../Company/downloadProductInfo'
import ContactSales from '../Company/contactSales'

function AIAccelerator() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>AI Accelerator</h2>
                <ol>
                    <li>Products</li>
                    <li>AI Accelerator</li>
                </ol>
                </div>

            </div>
        </section>

        <div className='container-fluid p-5 pb-1'>
            <div className='row'>
                <div className="col-md-12 text-end">
                <DownloadProductInfo productName={"NC-NoC"} produtFileName="NovuTensor_ScalableIP_Product_Brief_v1.0.pdf" produtFileDownloadName="NovuTensor_ScalableIP_Product_Brief_v1.0.pdf" btnClasses={"btn btn-primary btn-sig"} />
                </div>
            </div>
        </div>
        
        <div className='container-fluid pb-5'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 text-justify'>
                                <p>Signature has partnered with NovuMind to offer a performance efficient AI acceleration core, based on the NovuTensor Architecture.   NovuTensor is at the forefront of the AI industry, pioneering the use of a Domain Specific Architecture with heterogeneous computing paradigm. The patented NovuTensor AI accelerator architecture is designed from scratch, with a dedicated focus on AI inference, resulting in hardware efficiency and software simplicity that is unparalleled in the market. This innovative architecture makes NovuTensor an ideal choice for a wide range of inference applications, from battery-powered embedded devices to the most demanding PetaOps-level self-driving tasks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContactSales productName={"AI Accelerator"} />
    </>
  )
}

export default AIAccelerator