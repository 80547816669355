import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';


const ShowJobPosting = (props) => {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const updateid = props.id;
    const token = props.token;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        showJobPostingDetail();
    }, [updateid]);

    const showJobPostingDetail = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/jobpostingdetails/${updateid}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const jobpostingData = response.data.data;
            setItem(jobpostingData);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }

    return (
        <>
            <Button variant="warning" onClick={handleShow} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}>
                <i className="bi bi-eye"></i>
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Job Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className='subHeading'><i className='bi bi-box-fill'></i> Skill Requirements</h5>
                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                        {item.skillrequirement
                            && item.skillrequirement
                                .map((skill, index) => (
                                    <li className='list-group-item' key={index}>
                                        <i className='bi bi-diamond-fill'></i>
                                        <p>{skill}</p>
                                    </li>
                                ))}
                    </ul>
                    <h5 className='subHeading'><i className='bi bi-box-fill'></i> Preferred Expertise</h5>
                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                        {item.preferredexpertise

                            && item.preferredexpertise

                                .map((preferredexpertise, index) => (
                                    <li className='list-group-item' key={index}>
                                        <i className='bi bi-diamond-fill'></i>
                                        <p>{preferredexpertise}</p>
                                    </li>
                                ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShowJobPosting;
