import React from 'react'
import logoImage from '../newlogo.png';

const HeaderDashboard = () => {
    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./login";
      };
    //const user=JSON.parse(adminTokendata);
    return (
        <>
            <div>
                {/* Navbar */}
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            
                            <img src={logoImage} style={{ width: '185px' }} alt="logo" />
                        </li>
                        
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Navbar Search */}
                        
                        {/* Messages Dropdown Menu */}
                        
                        {/* Notifications Dropdown Menu */}
                        
                        <li className="nav-item">
                            <p>{localStorage.getItem('userEmail')}</p>
                        </li>
                        <li className="nav-item">
                            <button className='btn btn-danger' onClick={logOut} ><i className="bi bi-power" ></i></button>
                        </li>
                    </ul>
                </nav>
                {/* /.navbar */}
            </div>

        </>
    )
}

export default HeaderDashboard
