import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import config from '../../../config';

const DeleteEventModal = (props) => {
    const [loading, setLoading] = useState(false);
    const Deleteid = props.id;
    const token = localStorage.getItem('adminToken');

    const handleDelete = async () => {
        try {
            setLoading(true);
            // Send a DELETE request to delete the event
            await axios.delete(`${config.apiUrl}/events/${Deleteid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Show a success message
            Swal.fire({
                title: 'Deleted!',
                text: 'The event has been deleted.',
                icon: 'success',
            });
            window.location.reload();
        } catch (error) {
            // Handle errors here
            console.error('Error deleting event:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the event.',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const showAlert = () => {
        // Show a confirmation dialog before deleting
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this event',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed to delete
                handleDelete();
            }
        });
    };

    return (
        <div>
            <Button
                className={`btn btn-danger${loading ? ' disabled' : ''}`}
                onClick={showAlert}
            >
                {loading ? 'Deleting...' : <i className="bi bi-trash3"></i>}
            </Button>
        </div>
    );
};

export default DeleteEventModal;
