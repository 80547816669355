//import { Tooltip } from 'bootstrap'
import React, { useRef, useState, useEffect } from 'react'
import { Link} from 'react-router-dom'
import DownloadProductInfo from '../Company/downloadProductInfo'
import ContactSales from '../Company/contactSales'
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';



function INOCulator(props) {
    const ScheduleDemoForm = useRef();
    const [btnText, setBtnText] = useState("Submit");


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);

    const submitDemoRequest = async(e) =>{
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        
        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        setBtnDisabled(true)
        setBtnText("Requesting...")
          await axios.post(`${config.apiUrl}/scheduleaDemo`, {
            "contactname":firstName+ " "+lastName,
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "message": message,
            "companyname": companyName,

            }).then( response => {

                ScheduleDemoForm.current.reset();
                

                Swal.fire("Success", "Thank you for contacting us!", "success");
                fetch('https://r6ge6sr19i.execute-api.ap-south-1.amazonaws.com/handleDemoRequestMail', {
                    mode:"no-cors",
                    method: 'POST',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({
                         "user_name": firstName+ " "+lastName,
                         "user_fname": firstName,
                         "user_lname": lastName,
                         "user_email": email,
                         "user_message": message,
                         "company_name": companyName,
                    })
                    })
                    .catch(error => {
                        
                    });

                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setMessage("");
                    setCompanyName("");
                    setBtnDisabled(false)
                    setBtnText("Submit")
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });
    }

  return (
    <>

        <div className='container-fluid'>
            <div className="modal fade" id="scheduleDemoModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header  modalHead-bg">
                            <h5 className="modal-title" id="">Schedule a Demo</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form ref={ScheduleDemoForm} onSubmit={submitDemoRequest}>

                                <div className='row'>
                                    <div className='col-md-12 mb-2'>
                                            <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Corporate Email *" 
                                            onChange={(e)=>{
                                                setEmail(e.target.value)
                                                setErrorMessage("")
                                            }}
                                            required/>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <input type="text" className="form-control " id="fname" placeholder="First Name" name="firstName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        />
                                    </div>
                                    <div className='col-md-6  mb-2'>
                                        <input type="text" className="form-control " id="lname" placeholder="Last Name" name="lastName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    
                                    <div className='col-md-12 mb-2'>
                                        <input type="text" className="form-control" name="comapny_Name" id="comapny_Name" placeholder="Company Name" 
                                        onChange={(e)=>{
                                            setCompanyName(e.target.value)
                                        }}
                                        />
                                        
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <textarea className="form-control" name="message" rows="4" placeholder="Additional Information" 
                                        onChange={(e)=>{
                                            setMessage(e.target.value)
                                        }}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger mb-3'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <div class="d-grid gap-2 col-12 mx-auto">
                                            <button type="submit" className="btn btn-block btn-primary btn-sig" disabled={btnDisabled}>{btnText}</button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>


        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>iNoCulator<sup className='tm'>TM</sup></h2>
                <ol>
                    <li>Products</li>
                    <li>iNoCulator<sup className='tm'>TM</sup></li>
                </ol>
                </div>

            </div>
        </section>
        <div className='container-fluid p-5 pb-1'>
            <div className='row'>
                <div className="col-md-3"></div>
                <div className="col-md-2">
                    <a href="https://inoculator.ai/request-access" className="btn btn-primary btn-lg btn-block float-right btnnew" target='_blank'><i className='fa fa-external-link-alt me-1'></i>Start Eval</a>
                    
                </div>

                <div className="col-md-2">
                    <button data-bs-toggle="modal" data-bs-target="#scheduleDemoModalID" className="btn btn-secondary btn-lg btn-block float-right btnnew" >Schedule a Demo</button>
                </div>

                <div className="col-md-2">
                    <DownloadProductInfo productName={"iNoCulator"} produtFileName="iNoCulator Product Brief v1.4.pdf" produtFileDownloadName="iNoCulator Product Brief v1.4.pdf" btnClasses={"btn btn-secondary btn-lg btn-block btnnew"} />
                </div>
            </div>
        </div>
        
        {/*}
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        
                        <div className='row'>
                            <div className='col-md-12 text-justify'>
                               <p>iNoCulator<sup className='tm'>TM</sup> is a SaaS-based EDA tool that is used to define the topology of a NoC quickly and easily, configure its parameters and simulate it to measure throughput and latency, power requirements and area. iNoCulator<sup className='tm'>TM</sup> enables interactive whiteboard editing of the NoC topology, through connection of initiators, routers and endpoints, and easy configuration. It supports on-the-fly instant simulation of bandwidth and latency, and estimation of power and area. iNoCulator<sup className='tm'>TM</sup> provides pushbutton RTL generation with targets to an SoC design flow or to an FPGA emulation flow.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className='container-fluid '>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                <p className='text-justify'>iNoCulator<sup className='tm'>TM</sup> is a cloud-based EDA tool that is used to define the topology of a NoC quickly and easily, configure its parameters and simulate it to measure throughput and latency, power requirements and area. iNoCulator enables interactive whiteboard editing of the NoC topology, through connection of initiators, routers and endpoints, and easy configuration. It supports on-the-fly instant simulation of bandwidth and latency, and estimation of power and area. iNoCulator provides pushbutton RTL generation with targets to an SoC design flow or to an FPGA emulation flow.</p>
                                </div>
                            </div>
                            <div className='col-md-6 p-3'>
                                <img src='./images/inoculatorgui.png' alt='iNoCulator GUI' className='img-fluid imgblock'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        
                        <div className='row'>
                        <div className='col-md-12 pb-4'>
                                <h5 className='text-uppercase text-mcolor'>Key Features</h5>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Fast, easy configuration of NoC topology and SoC architecture</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Easy, interactive architectural exploration of performance and latency</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Support for non-coherent and coherent NoCs</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Configuration as ring, mesh, concentrated mesh or torus topologies</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Support for popular interface protocols (CHI, ACE, AXI, AHB, APB) and multiple bus widths</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Layered, scalable physically-aware network with automatic physical partitioning and auto-pipelining</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Multiple clocking schemes including GALS</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='featureBox'>
                                    <p>Power control with power island generation and UPF output</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        {/*
        <div className='container-fluid pb-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        
                        <div className='row'>
                            <div className='col-md-12 pb-4'>
                                <h5 className='text-mcolor'>iNoCulator<sup className='tm'>TM</sup> GUI</h5>
                            </div>
                            <div className='col-md-12'>
                            <img src='./images/inoculatorgui.png' alt='iNoCulator GUI' className='img-fluid imgblock'/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="suscribe-area">
            <div className="container-fluid">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs=12">
                    <div className="suscribe-text text-center">
                        <div className='row'>
                            <div className="col-md-3"></div>
                            <div className="col-md-2">
                                <a href="https://inoculator.ai/request-access" className="btn btn-light btn-lg btn-block float-right btnnew" target='_blank'><i className='fa fa-external-link-alt me-1'></i>Start Eval</a>
                            </div>

                            <div className="col-md-2">
                                <button data-bs-toggle="modal" data-bs-target="#scheduleDemoModalID" className="btn btn-light btn-lg btn-block float-right btnnew" >Schedule a Demo</button>
                            </div>

                            <div className="col-md-2">
                                <DownloadProductInfo productName={"iNoCulator"} produtFileName="iNoCulator Product Brief v1.4.pdf" produtFileDownloadName="iNoCulator Product Brief v1.4.pdf" btnClasses={"btn btn-light btn-lg btn-block btnnew"} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default INOCulator