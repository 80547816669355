import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';
// import emailjs from '@emailjs/browser';


function Sendquery() {
    const form = useRef();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Send");



    async function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        console.log(email)
        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else {
            setErrorMessage("");
        }

        setBtnDisabled(true)
        setBtnText("Sending...")
        await axios.post(`${config.apiUrl}/Contact`, {
            "contactname":firstName+ " "+lastName,
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "subject": subject,
            "message": message,
            "jobtitle": jobTitle,
            "companyname": companyName,

        }).then(response => {

            setFirstName("");
            setLastName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setCompanyName("");
            setJobTitle("");
            setBtnDisabled(false)
            setBtnText("Send")

            Swal.fire("Success", "Thank you for contacting us!", "success");
            fetch('https://3rxol6ia0g.execute-api.ap-south-1.amazonaws.com/sendContactMail', {
          mode:"no-cors",
          method: 'POST',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
               "user_name": firstName+ " "+lastName,
               "first_name": firstName,
               "last_name": lastName,
               "user_email": email,
               "user_subject": subject,
               "user_message": message,
               "user_jobTitle": jobTitle,
               "user_companyName": companyName,
          })
          })
          .catch(error => {
              
          });
        })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });
        /*fetch('https://3rxol6ia0g.execute-api.ap-south-1.amazonaws.com/sendContactMail', {
          mode:"no-cors",
          method: 'POST',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
               "user_name": firstName+ " "+lastName,
               "first_name": firstName,
               "last_name": lastName,
               "user_email": email,
               "user_subject": subject,
               "user_message": message,
               "user_jobTitle": jobTitle,
               "user_companyName": companyName,
          })
          }).then( response => {

              setFirstName("");
              setLastName("");
              setEmail("");
              setSubject("");
              setMessage("");
              setCompanyName("");
              setJobTitle("");
              setBtnDisabled(false)
              setBtnText("Send")

              Swal.fire("Success", "Thank you for contacting us!", "success");
          })
          .catch(error => {
              setBtnDisabled(false)
              setBtnText("Send")
              Swal.fire("Error", "Error sending mail.", "error");
          });*/

        e.target.reset();
    }

    //   const changehandler = (e)=>{
    //     const inputField = e.target.name;
    //     if(inputField=='user_name'){
    //         setenqname(e.target.value);
    //         console.log(setenqname);
    //         if(!e.target.value){
    //             setenqnameerror("fill your name")
    //         }else if(e.target.value.length<4){
    //             setenqnameerror("Atleast 4 Character")
    //         }else{
    //             setenqnameerror("")
    //         }
    //     }        
    //   }

    return (
        <>
            <form ref={form} onSubmit={sendEmail}>

                <div className='row'>
                    <div className='col-md-6 mb-2'>
                        <input type="text" className="form-control " id="fname" placeholder="First Name" name="firstName"
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                            required />
                    </div>
                    <div className='col-md-6  mb-2'>
                        <input type="text" className="form-control " id="lname" placeholder="Last Name" name="lastName"
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                            required />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6 mb-2'>
                        <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Corporate Email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setErrorMessage("")
                            }}
                            required />
                    </div>
                    <div className='col-md-6 mb-2'>
                        <input type="text" className="form-control" name="job_title" id="job_title" placeholder="Job title"
                            onChange={(e) => {
                                setJobTitle(e.target.value)
                            }}
                            required />
                    </div>
                    <div className='col-md-12 mb-2'>
                        <input type="text" className="form-control" name="comapny_Name" id="comapny_Name" placeholder="Company Name"
                            onChange={(e) => {
                                setCompanyName(e.target.value)
                            }}
                            required />

                    </div>
                    <div className='col-md-12 mb-2'>
                        <input type="text" className="form-control" name="user_subject" id="user_subject" placeholder="Subject"
                            onChange={(e) => {
                                setSubject(e.target.value)
                            }}
                            required />
                    </div>
                    <div className='col-md-12 mb-2'>
                        <textarea className="form-control" name="message" rows="4" placeholder="Message"
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                            required></textarea>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        {
                            errorMessage ? <>
                                <div className='alert alert-danger mb-3'>
                                    {"Error: " + errorMessage}
                                </div>
                            </> : <></>
                        }
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-sig" disabled={btnDisabled}>{btnText}</button>
            </form>
        </>
    )
}

export default Sendquery