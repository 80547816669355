import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../../../config';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const IsActiveButton = (props) => {
    const [isActive, setIsActive] = useState(true);
    const activeid = props.id;
    const token = props.token;

    const getUpdatedEvent = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/events/${activeid}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const eventData = response.data.data;
            setIsActive(eventData.isactive);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    };

    useEffect(() => {
        getUpdatedEvent();
    }, [activeid]);

    const showAlert = () => {
        // Show a confirmation dialog before updating
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to update the status?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: 'Yes!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed to update the status
                handleIsActive();
            }
        });
    };

    const handleIsActive = async () => {
        try {
            // Invert the current state (toggle between true and false)
            const newIsActive = !isActive;

            const response = await axios.patch(
                `${config.apiUrl}/eventsactive/${activeid}`,
                {
                    isactive: newIsActive,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                // If the update was successful, update the state
                setIsActive(newIsActive);
                Swal.fire({
                    title: 'Success!',
                    text: 'Event is now ' + (newIsActive ? 'active' : 'inactive'),
                    icon: 'success',
                });
            }
            window.location.reload();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
                Swal.fire({
                    title: 'Error!',
                    text: 'An error occurred while updating the event.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <BootstrapSwitchButton
            checked={isActive}
            onstyle="warning"
            width={75}
            onlabel="Yes"
            offlabel="No"
            style={{ fontWeight: 'bold' }}
            onChange={showAlert}
        />
    );
};

export default IsActiveButton;
