import React, { useEffect,useState } from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Home from './Home/Home';
import {Routes, Route, useLocation} from 'react-router-dom'
import Partnerships from './Company/Partnerships';
import Career from './Company/Career';
import PagenotFound from './Home/PagenotFound';
import Leadership from './Company/Leadership';
import Contact from './Company/Contact';
import CNoC from './Prtoduct/CNoC';
import NCNoc from './Prtoduct/NCNoc';
import INOCulator from './Prtoduct/iNOCulator';
import SignatureViews from './Resource/SignatureViews';
import News from './Resource/News';
import Events from './Resource/Events';
import Gallery from './Resource/Gallery';
import Downloads from './Resource/Downloads';
import Blogs from './Resource/Blogs';

import AboutUs from './Company/AboutUs';
import Memberships from './Company/Memberships';

import PCIe from './Prtoduct/PCIe';
import AIAccelerator from './Prtoduct/AIAccelerator';

import Login from './admin/login';
import ForgotPassword from './admin/ForgotPassword'; 
import SetPassword from './admin/SetPassword';

import Dashboard from './admin/Dashboard/dashboard';
import NewDashboardpage from './admin/Dashboard/Pages/NewDashboardpage'
import ContactDashboard from './admin/Dashboard/Pages/ContactDashboard'
import SalesContactDashboard from './admin/Dashboard/Pages/SalesContactDashboard'
import PrivateRoute from './privateRoute';
import ProductBriefContact from './admin/Dashboard/Pages/ProductBriefContact';
import EventsDashboard from './admin/Dashboard/Pages/EventsDashboard';
import LeadershipDashboard from './admin/Dashboard/Pages/LeadershipDashboard';
import PartnershipDashboard from './admin/Dashboard/Pages/PartnershipDashboard/PartnershipDashboard';
import JobPostingDashboard from './admin/Dashboard/Pages/JobPosting/JobPostingDashboard';
import NewsPageDashboard from './admin/Dashboard/Pages/NewsPage/NewsPageDashboard';
import BlogsPageDashboard from './admin/Dashboard/Pages/BlogsPages/BlogsPageDashboard';
import CompanyDetailDashboard from './admin/Dashboard/Pages/CompanyDetail/CompanyDetailDashboard';
import MembershipDashboard from './admin/Dashboard/Pages/Membership/MembershipDashboard';
import Products from './Prtoduct/Products2'
import UserDashboard from './admin/Dashboard/Pages/User/UserDashboard';
import ChangePassword from './admin/Dashboard/Pages/User/ChangePassword';
import UserChangePassword from './admin/UserChangePassword';




function App() {  

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  const isAuthenticated=localStorage.getItem('isLogedIn')
  
  const isLoginPage =
    (
      location.pathname === '/admin'
      || location.pathname === '/login'
      || location.pathname === '/forgot-password'
      || location.pathname === '/set-password'
      || location.pathname === "/NewDashboardpage"
      || location.pathname === "/WidgetPage"
      || location.pathname === "/ContactDashboard"
      || location.pathname === "/SalesContactDashboard"
      || location.pathname === "/ProductBriefContact"
      || location.pathname === "/EventsDashboard"
      || location.pathname === "/LeadershipDashboard"
      || location.pathname === "/PartnershipDashboard"
      ||location.pathname ==="/JobPostingDashboard"
      ||location.pathname ==="/NewsPageDashboard"
      ||location.pathname ==="/BlogsPageDashboard"
      ||location.pathname ==="/CompanyDetailDashboard"
      ||location.pathname ==="/MembershipDashboard"
      ||location.pathname ==="/UserDashboard"
      ||location.pathname==="/ChangePassword"
      ||location.pathname==="/UserChangePassword"
    );
  
  return (
    <>
      {!isLoginPage && <Header />}
      
        <Routes>
        <Route
            exact
            path={isAuthenticated=== "true" ? '/dashboard' :'/login'}
            element={isAuthenticated === "true" ? <Dashboard /> : <Login />}
          />
          <Route
            exact
            path={isAuthenticated=== "true" ? '/ContactDashboard' :'/login'}
            element={isAuthenticated === "true" ? <ContactDashboard /> : <Login />}
            
          />
          <Route
            exact
            path={isAuthenticated=== "true" ? '/SalesContactDashboard' :'/login'}
            element={isAuthenticated === "true" ? <SalesContactDashboard /> : <Login />}
            
          />
          <Route
            exact
            path={isAuthenticated=== "true" ? '/ProductBriefContact' :'/login'}
            element={isAuthenticated === "true" ? <ProductBriefContact /> : <Login />}
            
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/EventsDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <EventsDashboard /> : <Login />}
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/LeadershipDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <LeadershipDashboard /> : <Login />}
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/PartnershipDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <PartnershipDashboard /> : <Login />}JobPostingDashboard
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/JobPostingDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <JobPostingDashboard /> : <Login />}NewDashboardpage
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/NewsPageDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <NewsPageDashboard /> : <Login />}
          
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/BlogsPageDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <BlogsPageDashboard /> : <Login />}
          
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/CompanyDetailDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <CompanyDetailDashboard /> : <Login />}
          
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/MembershipDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <MembershipDashboard /> : <Login />}
          
          />
          <Route
          exact
          path={isAuthenticated=== "true" ? '/UserDashboard' :'/login'} 
          element={isAuthenticated === "true" ? <UserDashboard /> : <Login />}
          
          />
           <Route
          exact
          path={isAuthenticated=== "true" ? '/ChangePassword' :'/login'} 
          element={isAuthenticated === "true" ? <ChangePassword /> : <Login />}
          
          />
          <Route exact path="/" element={<Home/>}></Route>
          <Route exact path="/Home" element={<Home/>}></Route>
          <Route exact path="/Career" element={<Career/>}></Route>
          <Route exact path="/Partnerships" element={<Partnerships/>}></Route>
          <Route exact path="/Leadership" element={<Leadership/>}></Route>
          <Route exact path="/Contact" element={<Contact/>}></Route>
          <Route exact path="/CNoC" element={<CNoC/>}></Route>
          <Route exact path="/NCNoc" element={<NCNoc/>}></Route>
          <Route exact path="/INOCulator" element={<INOCulator/>}></Route>
          <Route exact path="/SignatureViews" element={<SignatureViews/>}></Route>
          <Route exact path="/News" element={<News/>}></Route>
          <Route exact path="/Events" element={<Events/>}></Route>
          <Route exact path="/Gallery" element={<Gallery/>}></Route>
          <Route exact path="/Downloads" element={<Downloads/>}></Route>
          <Route exact path="/AboutUs" element={<AboutUs/>}></Route>
          <Route exact path="/Memberships" element={<Memberships/>}></Route>
          <Route exact path="/PCIe" element={<PCIe/>}></Route>
          <Route exact path="/AIAccelerator" element={<AIAccelerator/>}></Route>
          <Route exact path="/Blogs" element={<Blogs/>}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/admin" element={<Login/>}/>
          <Route path="/dashboard" element={<Dashboard />} />
          
          <Route  path="/ContactDashboard" element={<ContactDashboard/>}></Route>
          <Route  path="/SalesContactDashboard" element={<SalesContactDashboard/>}></Route>
          <Route  path="/ProductBriefContact" element={<ProductBriefContact/>}></Route>
          <Route  path="/EventsDashboard" element={<EventsDashboard/>}></Route>
          <Route  path="/LeadershipDashboard" element={<LeadershipDashboard/>}></Route>
          <Route  path="/PartnershipDashboard" element={<PartnershipDashboard/>}></Route>
          <Route  path="/JobPostingDashboard" element={<JobPostingDashboard/>}></Route>
          <Route  path="/NewsPageDashboard" element={<NewsPageDashboard/>}></Route>
          <Route  path="/BlogsPageDashboard" element={<BlogsPageDashboard/>}></Route>
          <Route  path="/CompanyDetailDashboard" element={<CompanyDetailDashboard/>}></Route>
          <Route  path="/MembershipDashboard" element={<MembershipDashboard/>}></Route>
          <Route exact path="/Products" element={<Products/>}></Route>
          <Route exact path="/UserDashboard" element={<UserDashboard/>}></Route>
          <Route exact path="/ChangePassword" element={<ChangePassword/>}></Route>
          <Route exact path="/UserChangePassword" element={<UserChangePassword/>}></Route>

          <Route path="*" element={<PagenotFound/>}></Route>           
        </Routes>
        {!isLoginPage && <Footer />}
        
        
    </>
  );
}


export default App;
