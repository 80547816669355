//import { Tooltip } from 'bootstrap'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import config from '../config';
import ReactLoading from 'react-loading';


function Partnerships() {
    const [partnershipItem, setPartnershipItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getPartnershipDetail();
    }, []);
    const getPartnershipDetail = async () => {
        setIsLoading(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/partnershipmainpage`,
        })
            .then(response => {
                // Handle the response
                setPartnershipItem(response.data.data);
                setIsLoading(false)
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
                setIsLoading(false);
            });
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Our Partners</h2>
                        <ol>
                            <li>Company</li>
                            <li>Our Partners</li>
                        </ol>
                    </div>

                </div>
            </section>

            <div className='container-fluid pt-5 pb-5'>
                {isLoading ? (<>
                    <div className='col-md-12  pt-4 pb-4'>

                        <div className='d-flex justify-content-center align-items-center '>
                            <ReactLoading type={'spin'} color={'#007bff'} height={50} width={50} />
                        </div>


                    </div>
                </>) : (<>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='container'>
                                <div className='row'>
                                    {partnershipItem.map((item, index) => (
                                        <div className='col-md-6' key={"col_1" + index}>
                                            <div className='card partners_details_card' key={"card_1" + index}>
                                                <div className='card-body' key={"card_2" + index}>
                                                    <div className='curved' key={"curved_1" + index}>
                                                        {item.websitelink !== '' ? (
                                                            <a href={item.websitelink} target={"_blank"}><img src={`${config.apiBaseUrl}/public/prtnershipImg/${item.imgfile}`} alt="Avery" className="img-fluid p-3" /></a>)
                                                            : (<img src={`${config.apiBaseUrl}/public/prtnershipImg/${item.imgfile}`} alt="Avery" className="img-fluid p-3" />)}

                                                    </div>

                                                    <p>
                                                        {item.companyname}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>))}




                                </div>
                            </div>
                        </div>
                    </div></>)}

            </div>






        </>
    )
}

export default Partnerships