import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';

export default  function AddLeadershipModal(props) {
    const [show, setShow] = useState(false);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [laeadershipname, setLaeadershipname] = useState("");
    const [position, setPosition] = useState("");
    const [details, setDetails] = useState("");
    const [imgFile, setImagFile] = useState("");
    const [ranking, setRanking] = useState(0);
    const addby=localStorage.getItem('userID');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem('adminToken');
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }
    const addLeadershipHandler = async (e) =>{
        e.preventDefault();
        let formData = new FormData();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        

        // Check if endDate is greater than or equal to startDate
        

        if (imgFile.length > 0) {
            let tempFile = imgFile[0];
            console.log(tempFile.type);


            if (tempFile.type !== "image/png" && tempFile.type !== 'image/jpeg' && tempFile.type !== 'image/jpg' && tempFile.type !== 'image/gif') {
                Swal.fire("Warning", "Please attach Image in .png or .jpeg or.jpg format.", "warning");
                return false;
            }

            formData.append("imgfile", tempFile); // Append the file to FormData
        }
        const name=firstname +" "+lastname;
        //setLaeadershipname(firstname+" "+lastname);
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("laeadershipname",name);
        formData.append("position", position);
        formData.append("details", details);
        formData.append("ranking", ranking);
        formData.append("addedby",addby);

        


        try {
            //setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/leadership`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setFirstname("");
            setLastname("");
            setLaeadershipname("");
            setPosition("");
            setDetails("");
            Swal.fire({
                title: 'Saved!',
                text: `The leadership details  has been Saved Successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Submited");
            props.loadLeader();
            //window.location.reload();
            props.loadLeader();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error adding leadership.", "error");
        }

    }
    return (
        <>
            <Button variant="primary" onClick={handleShow}>Add Leadership Details</Button>

            <Modal show={show} onHide={handleClose} animation={false} size="lg" >
                <Form onSubmit={addLeadershipHandler} encType="multipart/form-data" method="post">
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Add Leadership Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">First Name <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Enter First Name" onChange={(e) => { setFirstname(e.target.value) }} required />
                            </div>
                            <div className="col">
                                <label className="form-label">Last Name <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" id="formGroupExampleInput"  placeholder='Enter Last Name' onChange={(e) => { setLastname(e.target.value) }} required/>
                            </div>
                        </div>
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">Designation <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Designation" aria-label="Designation" onChange={(e) => { setPosition(e.target.value) }} required />
                            </div>
                            <div className="col">
                                <label className="form-label">Profile Image <span style={{ color: 'red' }}>*</span></label>
                                <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png .gif" onChange={onChangeFile} />
                            </div>
                        </div>
                        
                        <div className="row g-3 m-2">
                            <div className="col">
                                <label className="form-label">Details </label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Details" onChange={(e) => { setDetails(e.target.value) }} value={details} required></textarea>
                            </div>
                            
                            
                            
                        </div>
                        <div className="row g-3 m-2">
                        <div className="col-md-4">
                                <label className="form-label">Ranking <span style={{ color: 'red' }}>*</span></label>
                                <input type="number" className="form-control" placeholder="Enter Ranking" aria-label="Ranking" onChange={(e) => { setRanking(e.target.value) }}value={ranking} required />
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'disabled={btnDisabled}>
                            {btnText}
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}
