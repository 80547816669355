//import { Tooltip } from 'bootstrap'
import React from 'react'
import {NavLink} from 'react-router-dom'

function Memberships() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Memberships</h2>
                <ol>
                    <li>Company</li>
                    <li>Memberships</li>
                </ol>
                </div>

            </div>
        </section>
        
        <div className='container-fluid comingsoon'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                    <div className="stage">
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                        <div className="layer"></div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Memberships