import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

const AddPartnersModal = (props) => {
    const [show, setShow] = useState(false);
    
    const [companyName, setCompanyName] = useState([]);
    const [imgFile, setImagFile] = useState("");
    const [websiteLink, setWebsiteLink] = useState("");
    const token = localStorage.getItem('adminToken');
    const addby=localStorage.getItem('userID');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }
    const addPartnershipHandler= async(e)=>
    {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        

        // Check if endDate is greater than or equal to startDate
        

        if (imgFile.length > 0) {
            let tempFile = imgFile[0];

            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            if (!validImageTypes.includes(tempFile.type)) {
                Swal.fire('Warning', 'Please attach an image in .png, .jpeg, .jpg, or .gif format.', 'warning');
                return false;
            }
            formData.append("imgfile", tempFile); // Append the file to FormData
        }
       
        formData.append("companyname", companyName);
        formData.append("websitelink", websiteLink);
        formData.append("addedby",addby);

        


        try {
            //setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/partnership`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setCompanyName("");
            setWebsiteLink("");
            Swal.fire({
                title: 'Saved!',
                text: `Pratnership details  has been Saved Successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Send");
            props.loadPartnership();
        } catch (error) {
            setBtnDisabled(false);
             setBtnText("Send");

            console.error(error);
            Swal.fire("Error", "Error adding Pratnership details.", "error");
        }
    }
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Add Partnership
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size="lg" >
            <Form onSubmit={addPartnershipHandler} encType="multipart/form-data" method="post">
                <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                    <Modal.Title>Add Partnership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row g-3 ">
                        <div class="col">
                            <label className="form-label">Company Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Enter Company Name" onChange={(e) => { setCompanyName(e.target.value) }} required />
                        </div>
                        <div class="col">
                            <label class="form-label"> Company Logo <span style={{ color: 'red' }}>*</span></label>
                            <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} required />
                        </div>
                    </div>
                    <div class="mt-2">
                            <label className="form-label">website Link <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Website Link" onChange={(e) => { setWebsiteLink(e.target.value) }}  />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit' disabled={btnDisabled}>
                         {btnText}
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddPartnersModal
