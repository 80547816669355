import React, { useRef, useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom'
import axios from 'axios';
import config from '../config';

// import emailjs from '@emailjs/browser';


function ContactSales(props) {
    const form = useRef();

    const productName = props.productName;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Send");

    const [productinfo, setProductInfo] = useState({
        products: [productName],
        response: [productName],
    });

    /*useEffect(() => {
        setProductInfo({
            products: [...products, productName],
            response: [...products, productName],
        });
    }, [productName]);*/

      const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { products } = productinfo;
         
        // Case 1 : The user checks the box
        if (checked) {
            setProductInfo({
            products: [...products, value],
            response: [...products, value],
          });
        }
      
        // Case 2  : The user unchecks the box
        else {
            setProductInfo({
            products: products.filter((e) => e !== value),
            response: products.filter((e) => e !== value),
          });
        }
      };


    
      async function  downloadProductFeature(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        
        
        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter corporate email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

       // console.log(productinfo.response);
       // return false;

        setBtnDisabled(true)
          setBtnText("Sending...")
          await axios.post(`${config.apiUrl}/salesContact`, {
                "salescontactname": firstName+ " "+lastName,
                "firstname": firstName,
                "lastname": lastName,
                "email": email,
                "subject": subject,
                "message": message,
                "jobtitle": jobTitle,
                "companyname": companyName,
                "downloadType":productName,
                "products":productinfo.response,

        }).then( response => {
            
            setFirstName("");
            setLastName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setCompanyName("");
            setJobTitle("");
            setBtnDisabled(false)
            setBtnText("Send")

            Swal.fire("Success", "Thank you for contacting us!", "success");
            fetch('https://z2yyotyqnj.execute-api.ap-south-1.amazonaws.com/sendContactSalesMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_subject": subject,
                 "user_message": message,
                 "job_title": jobTitle,
                 "company_name": companyName,
                 "downloadType":productName,
                 "Products":productinfo.response,
            })
            })
            .catch(error => {
                
            });
        })
        .catch(error => {
            setBtnDisabled(false)
            setBtnText("Send")
            Swal.fire("Error", "Error sending mail.", "error");
        });
          /*fetch('https://z2yyotyqnj.execute-api.ap-south-1.amazonaws.com/sendContactSalesMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_subject": subject,
                 "user_message": message,
                 "job_title": jobTitle,
                 "company_name": companyName,
                 "downloadType":productName,
                 "Products":productinfo.response,
            })
            }).then( response => {


                setFirstName("");
                setLastName("");
                setEmail("");
                setSubject("");
                setMessage("");
                setCompanyName("");
                setJobTitle("");
                setBtnDisabled(false)
                setBtnText("Send")

                Swal.fire("Success", "Thank you for contacting us!", "success");
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Download")
                Swal.fire("Error", "Error sending mail.", "error");
            });*/

          e.target.reset();
    }
    
  return (
    <>

        <div className='container-fluid'>
            <div className="modal fade" id="ContactSalesModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header  modalHead-bg">
                            <h5 className="modal-title" id="">Contact Sales</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form ref={form} onSubmit={downloadProductFeature}>

                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <input type="text" className="form-control " id="fname" placeholder="First Name" name="firstName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div className='col-md-6  mb-2'>
                                        <input type="text" className="form-control " id="lname" placeholder="Last Name" name="lastName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                            <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Corporate Email" 
                                            onChange={(e)=>{
                                                setEmail(e.target.value)
                                                setErrorMessage("")
                                            }}
                                            required/>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <input type="text" className="form-control" name="job_title" id="job_title" placeholder="Job title" 
                                        onChange={(e)=>{
                                            setJobTitle(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <input type="text" className="form-control" name="comapny_Name" id="comapny_Name" placeholder="Company Name" 
                                        onChange={(e)=>{
                                            setCompanyName(e.target.value)
                                        }}
                                        required/>
                                        
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <input type="text" className="form-control" name="user_subject" id="user_subject" placeholder="Subject" 
                                        onChange={(e)=>{
                                            setSubject(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <textarea className="form-control" name="message" rows="4" placeholder="Message" 
                                        onChange={(e)=>{
                                            setMessage(e.target.value)
                                        }}
                                        required></textarea>
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        Product :
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <div class="form-check form-check-inline">
                                            {
                                                productName==="iNoCulator"?
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="iNoCulator" name='products' 
                                                checked />:
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="iNoCulator" name='products' 
                                            onChange={handleChange} />
                                            }
                                            
                                            <label class="form-check-label" for="inlineCheckbox1">iNoCulator<sup className='tm'>TM</sup></label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            {
                                                productName==="NC-NoC"?
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="NC-NoC" name='products' checked />
                                                :
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="NC-NoC" name='products' onChange={handleChange} />
                                            }
                                            
                                            <label class="form-check-label" for="inlineCheckbox2">NC-NoC</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            {
                                                productName==="C-NoC"?
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="C-NoC" name='products' checked />
                                                :
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="C-NoC" name='products' onChange={handleChange} />
                                            }
                                            
                                            <label class="form-check-label" for="inlineCheckbox3">C-NoC</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            {
                                                productName==="PCIe Gen6 Controller"?
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="PCIe Gen6 Controller" name='products' checked />
                                                :
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="PCIe Gen6 Controller" name='products' onChange={handleChange} />
                                            }
                                            
                                            <label class="form-check-label" for="inlineCheckbox4">PCIe Gen6 Controller</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            {
                                                productName==="AI Accelerator"?
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox5" value="AI Accelerator" name='products' checked />
                                                :
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox5" value="AI Accelerator" name='products' onChange={handleChange} />
                                            }
                                            
                                            <label class="form-check-label" for="inlineCheckbox5">AI Accelerator</label>
                                        </div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger mb-3'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <div class="d-grid gap-2 col-12 mx-auto">
                                            <button type="submit" className="btn btn-block btn-primary btn-sig" disabled={btnDisabled}>{btnText}</button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        {/*<button type="button" class="btn btn-primary btn-sig"
            data-bs-toggle="modal" data-bs-target="#downloadProductFeatureModalID"
            title='Request Product Brief'
                                    ><i className='bi bi-download'></i> Product Brief</button>*/}

        <div className="suscribe-area">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs=12">
                    <div className="suscribe-text text-center">
                        <button type='button' className="sus-btn" 
                        data-bs-toggle="modal" data-bs-target="#ContactSalesModalID"
                        >Contact Sales</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
               
        
    </>
  )
}

export default ContactSales