import React, { useEffect, useState } from 'react'
import Sendquery from '../Company/Sendquery'
import Banner from './Banner'
import { Link } from 'react-router-dom'
import axios from 'axios';
import config from '../config';

function Home() {
    const [companydetailsItem, setCompanydetailsItem] = useState([]);
    const [partnershipItem, setPartnershipItem] = useState([]);
    useEffect(() => {
        showCompanydetail();
    }, []);

    useEffect(() => {
        getPartnershipDetail();
    }, []);

    /*Compnay Details response */
    const showCompanydetail = async (e) => {

        try {
            const response = await axios.get(
                `${config.apiUrl}/companyaddress`,

            );
            const companydetailsData = response.data.data;

            setCompanydetailsItem(companydetailsData);



        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }

    }
    /* Partnership Response*/
    const getPartnershipDetail = async () => {
        await axios({
            method: 'get',
            url: `${config.apiUrl}/partnershipmainpage`,
        })
            .then(response => {
                // Handle the response
                setPartnershipItem(response.data.data);
                
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }

    return (
        <>
            <Banner />

            <div className='container-fluid bg-light'>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>Our Vision and Mission</h3>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='card mv-card'>
                                        <div className='card-body'>
                                            <i className='bi bi-eye'></i>
                                            <h3>Vision</h3>
                                            <p>SignatureIP’s vision is to make every customer’s SoC optimized to exploit the full speed and efficiency of the most modern interconnect available, from NoCs to interfaces to die-to-die interconnect.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='card mv-card '>
                                        <div className='card-body'>
                                            <i className='bi bi-rocket-takeoff'></i>
                                            <h3>Mission</h3>
                                            <p>SignatureIP’s mission is to enable our customers to rapidly and efficiently explore the design space for their SoCs and achieve the best possible architecture. We aim to speed our customers to market via top-down design exploration and optimization, configurability and flexibility, and excellent customer support.</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid bg-Sig' style={{ position: "relative" }}>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase'>Why use a Network-on-Chip?</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 pb-3'>
                                    <p className='text-justify'>Today’s SoCs are growing in size and complexity, driving a transition from on-chip bus technology to NoC solutions, the adoption of chiplets and more. NoCs provide the speed and flexibility to route data around an SoC the same way data is routed around the internet, using packet switching and layered communication protocols. A NoC can support more concurrent communication channels than a bus architecture, which can increase system performance. A NoC can also provide more efficient communication between different components of a system, as it can route data packets to specific destination nodes, rather than broadcasting the data to all nodes on the bus, as in bus architectures. This can also help to reduce power consumption, as communication can be more targeted and not broadcast to all nodes. A NoC is also scalable, as it can handle more complex systems as the number of nodes increases.</p>
                                </div>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase'>SignatureIP Configurable NoC Products</h3>
                                </div>
                                <div className='col-md-4 whyNoc-card text-start'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title'>iNoCulator<sup className='tm'>TM</sup> - a new way to quickly and easily create flexible and configurable NoCs</h5>
                                            <div className='divider'></div>
                                            <p className='card-text'>iNoCulator<sup className='tm'>TM</sup> is a unique end-to-end solution for the creation and exploration of NoCs, from ideation to system architecture to RTL simulation, emulation and implementation. It allows flexible NoC topology, with easy editing tools, full configuration, and is fully integrated with customers’ EDA environments.</p>
                                            <Link to="/INOCulator" className='learnMorebtn'>Learn More</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 whyNoc-card text-start'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title'>The Smart Non-Coherent Network-on-Chip (NC-NoC) Solution</h5>
                                            <div className='divider'></div>
                                            <p className='card-text'>NC-NoC is available today as a layered, scalable, physically aware configurable NoC supporting multiple clocking schemes for NoCs that do not require coherency. It supports multiple protocols such as AXI4/3, AHB, APB, AXI-lite and multiple bus widths from 32 to 2048 bits.</p>
                                            <Link to="/NCNoc" className='learnMorebtn'>Learn More</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 whyNoc-card text-start'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title'>The Intelligent Coherent Network-on-Chip (C-NoC) Solution</h5>
                                            <div className='divider'></div>
                                            <p className='card-text'>C-NoC, available in the second half of 2023, is a coherent NoC technology that supports mesh, grid and torus topologies with on-chip L3 cache support to reduce latency. This also supports multiple protocols including CHI, AXI4/3, AXI-lite, ACE and ACE-lite with bus widths from 32 to 2048 bits.</p>
                                            <Link to="/CNoC" className='learnMorebtn'>Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                                {/*
                            <div className='col-md-4 whyNoc-card text-start offset-md-2'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5 className='card-title'>PCIe Gen6 Controller</h5>
                                        <div className='divider'></div>
                                        <p className='card-text'>PCIe gen 6 controller IP, which is "NoC aware", provides a high-speed interface for efficient data transfer and system communication, supporting speeds of up to 64 GT/s. </p>
                                        <Link to="/PCIe" className='learnMorebtn'>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 whyNoc-card text-start'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5 className='card-title'>AI Accelerator</h5>
                                        <div className='divider'></div>
                                        <p className='card-text'>PCIe gen 6 controller IP, which is "NoC aware", provides a high-speed interface for efficient data transfer and system communication, supporting speeds of up to 64 GT/s. </p>
                                        <Link to="/AIAccelerator" className='learnMorebtn'>Learn More</Link>
                                    </div>
                                </div>
                            </div>

                            */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid  bg-light'>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>Why SignatureIP?</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 text-justify'>
                                    <p>SignatureIP’s NoC solution was designed from the beginning to be flexible and easily configurable, with instant simulation options to explore the design space, and pushbutton generation of the top-level RTL code. Customers can easily and quickly experiment with different NoC topologies and settings and see the resulting latency, performance and power results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid' id='partnerBlockID'>
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>Our Partners</h3>
                                </div>
                            </div>

                            {/*{<div className='row'>
                                {partnershipItem.map((partnerItem, partnerIndex) => (
                                    <div className={`${partnerIndex === 3 ? 'col-md-4 offset-md-2' : 'col-md-4'}`} key={partnerIndex}>
                                        <div className={`card partner-card mb-4`}>
                                            {partnerItem.websitelink !== '' ? (
                                                <a href={partnerItem.websitelink} target='_blank' rel='noreferrer'>
                                                    <img
                                                        src={`${config.apiBaseUrl}/public/prtnershipImg/${partnerItem.imgfile}`}
                                                        alt='Avery'
                                                        className='img-fluid p-3'
                                                    />
                                                </a>
                                            ) : (
                                                <img
                                                    src={`${config.apiBaseUrl}/public/prtnershipImg/${partnerItem.imgfile}`}
                                                    alt='Avery'
                                                    className='img-fluid p-3'
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                                            </div>}*/}
                            <div className='row'>
                                {partnershipItem.map((partnerItem, partnerIndex) => (
                                    <div className={`col-md-4 ${(
                                        (partnershipItem.length === 5 && (partnerIndex === 3)) ||
                                        (partnershipItem.length === 7 && (partnerIndex === 6)) ||
                                        (partnershipItem.length === 8 && (partnerIndex === 6))
                                    ) ? 'offset-md-2' : 'col-md-4'}`} key={partnerIndex}>
                                        <div className={`card partner-card mb-4`}>
                                            {partnerItem.websitelink !== '' ? (
                                                <a href={partnerItem.websitelink} target='_blank' rel='noreferrer'>
                                                    <img
                                                        src={`${config.apiBaseUrl}/public/prtnershipImg/${partnerItem.imgfile}`}
                                                        alt='Avery'
                                                        className='img-fluid p-3'
                                                    />
                                                </a>
                                            ) : (
                                                <img
                                                    src={`${config.apiBaseUrl}/public/prtnershipImg/${partnerItem.imgfile}`}
                                                    alt='Avery'
                                                    className='img-fluid p-3'
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                       </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid bg-light' >
                <div className='row'>
                    <div className='col-md-12 pt-5 pb-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center block_heading'>
                                    <h3 className='text-uppercase text-mcolor'>Contact Us</h3>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-3 d-flex align-items-center justify-content-center'>

                                    <div className='contact-img'>

                                        <img src='./images/usa-location.jpg' alt='SignatureIP' className='img-fluid' />

                                    </div>
                                </div>
                                <div className='col-md-3 d-flex align-items-center justify-content-center'>

                                    <div>

                                        <p className='blockquote text-mcolor maillink'><i class="bi bi-envelope text-primary me-2 "></i> sales@signatureip.ai{/*<a href='https://outlook.office.com/mail/deeplink/compose?to=sales@signatureip.ai' target={'_blank'}>sales@signatureip.ai</a>*/}</p>
                                        {companydetailsItem.map((phonenoItem, phonenoIndex) => (
                                            <div key={`phoneno${phonenoIndex}`}>
                                                {phonenoItem.phonenumber.map((phonenumberitem, phonenumberindex) => (

                                                    <p className='blockquote text-mcolor' key={`phoneItem${phonenumberindex}`}><i class="bi bi-telephone text-primary me-2">
                                                    </i> {phonenumberitem}
                                                    </p>

                                                ))}
                                            </div>
                                        ))}

                                        <p className='blockquote text-mcolor'><i class="bi bi-geo-alt text-primary me-2"></i> Address</p>
                                        {companydetailsItem.map((companyAddItem, companyAddIndex) => (
                                            <div key={companyAddIndex}>
                                                {companyAddItem.addressdetail.map((addItem, addItemIndex) => (
                                                    <div key={addItemIndex}>
                                                        <p key={`address-${addItemIndex}`} style={{ whiteSpace: 'pre-wrap' }}>
                                                            {addItem}
                                                        </p>
                                                        {addItemIndex !== companyAddItem.addressdetail.length - 1 && <hr />}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}

                                    </div>

                                </div>
                                <div className='col-md-6 p-3'>
                                    <Sendquery />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Home