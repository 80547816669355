import React from 'react'

const DeleteLeadershipPrfileImg = () => {
    const showAlert=()=>{}
  return (
    <span style={{ color: 'red' }} onClick={showAlert}>
            <i className="bi bi-trash3"></i>
    </span>
  )
}

export default DeleteLeadershipPrfileImg
