import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../../../config';
import DeleteSingleFile from './DeleteSingleFile';

const UpdateEventModal = (props) => {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState({});
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [venue, setVenue] = useState('');
    const [imgFile, setImagFile] = useState(""); // Use null to indicate no file

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const UpdateId = props.id;
    const token = localStorage.getItem('adminToken');
    const [calenderEventLink, setCalenderEventLink] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const [eventType, setEventType] = useState('');
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }
    const UpdateEventHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);

        let formData = new FormData();

        /*if (imgFile) {
            if (imgFile.type !== "image/png" && imgFile.type !== 'image/jpeg' && imgFile.type !== 'image/jpg') {
                Swal.fire("Warning", "Please attach Image in .png or .jpeg or .jpg format.", "warning");
                return;
            }
            formData.append("imgfile", imgFile);
        }*/
        if (eventType === 'text') {
            formData.append("details", detail); // Text details

            if (imgFile) {
                formData.append("imagefile", imgFile[0]); // Event logo
            }
        } else if (eventType === 'image') {
            if (imgFile) {
                formData.append("imagefile", imgFile[0]); // Event image
            }
        }

        formData.append("eventitle", title);
        formData.append("startdate", startDate);
        formData.append("endate", endDate);
        formData.append("venue", venue);
        formData.append("calenderevent", calenderEventLink);
        formData.append("eventtype", eventType);
/*for(var pair of formData.entries())
{
    console.log(pair[0]+','+pair[1])
}*/
        
       try {
            const response = await axios.put(
                `${config.apiUrl}/events/${UpdateId}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setBtnDisabled(false);
            setBtnText("Submited");
            Swal.fire("Success", "Event updated successfully!", "success");
            handleClose();
            props.loadEvents();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Submited");
            console.error(error);
            Swal.fire("Error", "Error updating event.", "error");
        }
    }

    useEffect(() => {
        getUpdatedEvent();
    }, [UpdateId]);

    const getUpdatedEvent = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/events/${UpdateId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const eventData = response.data.data;
            setItem(eventData);
            setTitle(eventData.eventitle);
            setDetail(eventData.details);
            setStartDate(dateformat(eventData.startdate));
            setEndDate(dateformat(eventData.endate));
            setVenue(eventData.venue);
            setCalenderEventLink(eventData.calenderevent);
            setEventType(eventData.eventtype)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }

    function dateformat(date) {
        const createdAtDate = new Date(date);
        const day = String(createdAtDate.getDate()).padStart(2, '0');
        const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
        const year = String(createdAtDate.getFullYear()).slice(-4);
        const newdate = `${year}-${month}-${day}`;
        return newdate;
    }

    return (
        <>
            <Button className="btn btn-success" onClick={handleShow}><i className="bi bi-pencil-square"></i></Button>

            <Modal show={show} onHide={handleClose} animation={false} size="xl">
                <Form onSubmit={UpdateEventHandler} encType="multipart/form-data" method='put'>
                    <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                        <Modal.Title>Update Events</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Event Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        </div>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="Text"
                                    name="eventType"
                                    type={type}
                                    id={`inline-${type}-Text`}
                                    onChange={() => setEventType('text')}
                                    checked={eventType === 'text' ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="Image"
                                    name="eventType"
                                    type={type}
                                    id={`inline-${type}-Image`}
                                    onChange={() => setEventType('image')}
                                    checked={eventType === 'image' ? true : false}
                                />

                            </div>
                        ))}
                        {eventType === 'text' ? (
                            <>
                                <div className="row g-3 my-3">
                                    <div className="col">
                                        <label className="form-label">Detail <span style={{ color: 'red' }}>*</span></label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Enter Event detail' onChange={(e) => { setDetail(e.target.value) }} required value={detail}></textarea>
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Event Logo <span style={{ color: 'red' }}></span></label>
                                        <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} />
                                        {item.imgfile !== null && (
                                            <p style={{ color: 'green' }}>Previous File:  {item.imgfile} <DeleteSingleFile deleteFileID={UpdateId} token={token} />  </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : eventType === 'image' ? (
                            <>
                                <div className="my-3">
                                    <label className="form-label">Event Image <span style={{ color: 'red' }}></span></label>
                                    <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} />
                                    {item.imgfile !== null && (
                                        <p style={{ color: 'green' }}>Previous File:  {item.imgfile} <DeleteSingleFile deleteFileID={UpdateId} token={token} />  </p>
                                    )}
                                </div>
                            </>
                        ) : null}
                        <div className="row g-3">
                            <div className="col">
                                <label className="form-label">Start Date</label>
                                <input type="date" className="form-control" placeholder="Start Date" aria-label="Start Date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />

                            </div>
                            <div className="col">
                                <label className="form-label">End Date</label>
                                <input type="date" className="form-control" placeholder="End date" aria-label="End Date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />

                            </div>
                        </div>

                        {/*{<div class="row g-3 my-3">
                            <div class="col">
                                <label className="form-label">Event Logo <span style={{ color: 'red' }}>*</span></label>
                                <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png" onChange={onChangeFile} />
                                {item.imgfile !== null && (
                                    <p style={{ color: 'green' }}>Previous File:  {item.imgfile} <DeleteSingleFile deleteFileID={UpdateId} token={token} />  </p>
                                )}

                            </div>
                            <div class="col">
                                <label className="form-label">Venue <span style={{ color: 'red' }}>*</span></label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Venue" value={venue} onChange={(e) => setVenue(e.target.value)} required></textarea>
                            </div>
                                </div>}*/}
                        <div class="my-3">
                            <label className="form-label">Venue <span style={{ color: 'red' }}>*</span></label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Venue" value={venue} onChange={(e) => setVenue(e.target.value)} required></textarea>
                        </div>
                        <div class="mb-3">
                            <label className="form-label"> EventLink </label>
                            <input type="text" className="form-control" placeholder="Calender EventLink" onChange={(e) => { setCalenderEventLink(e.target.value) }} value={calenderEventLink} />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {/*{<Button variant="primary" type="submit" disabled={btnDisabled}>
                            {btnText}
                            </Button>}*/}
                            <Button variant="primary" type='submit' >
                            submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default UpdateEventModal;
