import React, { useState, useEffect } from 'react'
//import Tab from 'react-bootstrap/Tab';
import axios from 'axios'
import config from '../config';

const UpcomingEventTab = () => {
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const currentDate = new Date();
    const getUpcomingEvents = async () => {
        await axios({
            method: 'get',
            url: `${config.apiUrl}/showAllEventsUpcoming`
        })
            .then(response => {
                // Handle the response


                setUpcomingEvents(response.data.data);
                //setFilterdEvents(response.data.data);
            })

            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
            });
    }
    useEffect(() => {
        getUpcomingEvents();
    }, []);
    const upcomingEventsChecking = upcomingEvents.filter((eventsdata) => {
        const eventStartDate = new Date(eventsdata.startdate);
        return eventStartDate > currentDate;
    });
    return (
        <>

            {upcomingEventsChecking.length > 0 ? (
                upcomingEventsChecking.map((eventsdata, index) => {
                    const startDate = new Date(eventsdata.startdate);
                    const endDate = new Date(eventsdata.endate);
                    const startMonth = startDate.toLocaleString('default', { month: 'short' });
                    const endMonth = endDate.toLocaleString('default', { month: 'short' });
                    const startDay = startDate.getDate();
                    const endDay = endDate.getDate();
                    const year = startDate.getFullYear();
                    const dateRange = startMonth === endMonth ? (
                        `${startMonth} ${startDay} - ${endDay}, ${year}`
                    ) : (
                        `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`
                    );
                    if (eventsdata.isactive) {
                        return ( // You were missing this 'return' statement
                            <div className='row' key={index}>
                                <div className='col-md-12 mb-5'>
                                    <div className='card event-card'>
                                        <div className='card-body'>
                                            <div className='row'>
                                                {eventsdata.eventtype === 'text' ? (<>
                                                    <div className='col-md-9'>
                                                        <h5>{eventsdata.eventitle}</h5>
                                                        {eventsdata.details.split('\r\n').map((paragraph, index) => (
                                                            <p key={index}>
                                                                {paragraph}
                                                            </p>
                                                        ))}
                                                        <h6>
                                                            <i className='bi bi-calendar-event-fill ml-2 text-info'></i>{' '}
                                                            {dateRange}
                                                        </h6>
                                                        <h6>
                                                            <i className='bi bi-pin-map-fill ml-2 text-primary'></i>{' '}
                                                            {eventsdata.venue}
                                                        </h6>
                                                        {eventsdata.calenderevent !== '' && (
                                                            <a
                                                                className='btn btn-outline-info mt-4'
                                                                href={eventsdata.calenderevent}
                                                                target='_blank'
                                                            >
                                                                Schedule
                                                            </a>)}
                                                    </div>
                                                    {eventsdata.imgfile !== null && (
                                                        <div className='col-md-3'>
                                                            <img src={`${config.apiBaseUrl}/public/uploadImg/${eventsdata.imgfile}`} alt='SignatureIP' className='img-fluid' />
                                                        </div>
                                                    )}
                                                </>) : eventsdata.eventtype === 'image' ? (<>
                                                    <div className='col-md-12'>
                                                        <h5>{eventsdata.eventitle}</h5>
                                                        <div className='col-md-12 my-3'>
                                                            <img src={`${config.apiBaseUrl}/public/uploadImg/${eventsdata.imgfile}`} alt='SignatureIP' className='img-fluid' width="100%" />
                                                        </div>
                                                        <h6>
                                                            <i className='bi bi-calendar-event-fill ml-2 text-info'></i>{' '}
                                                            {dateRange}
                                                        </h6>
                                                        <h6>
                                                            <i className='bi bi-pin-map-fill ml-2 text-primary'></i>{' '}
                                                            {eventsdata.venue}
                                                        </h6>
                                                        {eventsdata.calenderevent !== '' && (
                                                            <a
                                                                className='btn btn-outline-info mt-4'
                                                                href={eventsdata.calenderevent}
                                                                target='_blank'
                                                            >
                                                                Schedule
                                                            </a>)}
                                                    </div>
                                                </>) : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return <div className='col-md-12 text-center'>
                            <img src='./images/no-news.png' alt='signatureIP' className='img-fluid p4' />
                            <h6>There are no upcoming events to show</h6>
                            <h4>Watch this space for upcoming events!</h4>
                        </div>
                    }
                })
            ) : (
                <div className='col-md-12 text-center'>
                    <img src='./images/no-news.png' alt='signatureIP' className='img-fluid p4' />
                    <h6>There are no upcoming events to show</h6>
                    <h4>Watch this space for upcoming events!</h4>
                </div>
            )}

        </>
    )
}

export default UpcomingEventTab
