import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config';

const ShowLeadershipModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = localStorage.getItem('adminToken');
  const showLeadershipID=props.id;
  const [item, setItem] = useState({});
  useEffect(() => {
    showLeadershipdetail();
}, [showLeadershipID]);

const showLeadershipdetail = async () => {
    try {
        const response = await axios.get(
            `${config.apiUrl}/leadership/${showLeadershipID}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
        );
        const leadershipData = response.data.data;
        setItem(leadershipData);
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Authentication error: You are not authorized to access this resource.');
        } else {
            console.error('An error occurred:', error.message);
        }
    }
}

  return (
    <>
      <Button variant="warning" onClick={handleShow} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}><i className="bi bi-eye"></i></Button>

      <Modal show={show} onHide={handleClose} animation={false} size="xl" >

        <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
          <Modal.Title>Show LeaderShip</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            
                  <div className='container'>
                    <div className='row'>
                    <div className='col-md-12'>
                      <div className='card leadershipnew-item'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-md-3 text-center'>
                            <img src={`${config.apiBaseUrl}/public/uploadLeadershipImg/${item.imgfile}`} className="leadershipnew-img" />
                              <h3>{item.firstname+" "+item.lastname}</h3>
                              <h4>{item.position}</h4>
                            </div>
                            <div className='col-md-9  d-flex align-items-center justify-content-center'>
                              <p>
                                  {item.details}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  </div>
                
          </>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>


        </Modal.Footer>

      </Modal>

    </>
  )
}

export default ShowLeadershipModal
