import React from 'react'
import {Routes,Route } from 'react-router-dom'
import NewDashboardpage from './Pages/NewDashboardpage'

function AppRouter() {
  return (
    
      <Routes>
        

        
      </Routes>
    
  )
}

export default AppRouter
