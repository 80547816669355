//import { Tooltip } from 'bootstrap'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';
import ReactLoading from 'react-loading';


function Leadership() {
    const [leadershipItem, setLeadershipItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getLeadershipDetail = async () => {
        setIsLoading(true);
        await axios({
            method: 'get',
            url: `${config.apiUrl}/leadershipmainpage`,
        })
            .then(response => {
                // Handle the response


                setLeadershipItem(response.data.data);
                setIsLoading(false);
                //setFilterdEvents(response.data.data);
            })

            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, handle accordingly (e.g., show login prompt)
                    console.error('Authentication error: You are not authorized to access this resource.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error.message);
                }
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getLeadershipDetail();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Leadership</h2>
                        <ol>
                            <li>Company</li>
                            <li>Leadership</li>
                        </ol>
                    </div>

                </div>
            </section>

            <div className='container-fluid leadershipnew'>
                {isLoading ? (<>
                    <div className='col-md-12  pt-4 pb-4'>

                        <div className='d-flex justify-content-center align-items-center '>
                            <ReactLoading type={'spin'} color={'#007bff'} height={50} width={50} />
                        </div>


                    </div>
                </>) : (<>
                    <div className='row'>
                        <div className='col-md-12 pt-4 pb-4'>
                            <div className='container'>
                                {leadershipItem.map((item, index) => (
                                    <div className='row' key={"row" + index}>
                                        <div className='col-md-12' key={"col" + index}>
                                            <div className='card leadershipnew-item' key={"card" + index}>
                                                <div className='card-body' key={"card_body" + index}>
                                                    <div className='row' key={"row_1" + index}>
                                                        <div className='col-md-3 text-center' key={"col_1" + index}>
                                                            <img src={`${config.apiBaseUrl}/public/uploadLeadershipImg/${item.imgfile}`} className="leadershipnew-img" alt="" />
                                                            <h3>{item.laeadershipname}</h3>
                                                            <h4>{item.position}</h4>
                                                        </div>
                                                        <div className='col-md-9  d-flex align-items-center justify-content-center' key={"col_2" + index}>
                                                            <p>
                                                                {item.details}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>)}

            </div>
        </>
    )
}

export default Leadership