//import { Tooltip } from 'bootstrap'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UpcomingEventTab from './UpcomingEventTab';
import HistoryEventTab from './HistoryEventTab';



function Events() {

    
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Events</h2>
                        <ol>
                            <li>Company</li>
                            <li>Events</li>
                        </ol>
                    </div>

                </div>
            </section>

            <div className='container-fluid leadership'>
                <div className='row'>
                    <div className='col-md-12 pt-4 pb-4'>
                        <div className='container'>
                            <Tabs
                                defaultActiveKey="upcoming" // Set the default active tab key
                                id="event-tabs"
                                className="mb-3"
                                variant="pills"
                            >
                                <Tab eventKey="upcoming" title="Upcoming Events">
                                    <UpcomingEventTab />
                                </Tab>
                                <Tab eventKey="history" title="History of Events">
                                    <HistoryEventTab />
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Events