import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';
import { saveAs } from 'file-saver';

const ShowCandidate = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [candidatedetail, setCandidatedetail] = useState([]);
  const updateid = props.id;
  const token = props.token;

  useEffect(() => {
    showCareerDetail();
  }, [updateid]);

  const showCareerDetail = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/career/${updateid}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const jobpostingData = response.data.data;
      setCandidatedetail(jobpostingData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error: You are not authorized to access this resource.');
      } else {
        console.error('An error occurred:', error.message);
      }
    }
  }

  return (
    <>
      <Button variant="success" onClick={handleShow} style={{ border: '1px solid orange', fontSize: '16px', padding: '5px 10px' }}>
        <i className="bi bi-person"></i>
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Candidate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {candidatedetail.length > 0 ? (
          <table className='table table-bordered'>
            <thead className='table-dark'>
              <tr>
                <th>Sl#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact No</th>
                <th>Skill Set</th>
                <th>Location</th>
                <th>Resume</th>
              </tr>
            </thead>
            <tbody>
              {candidatedetail.map((item, index) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.firstname + (item.midddlename ? ' ' + item.midddlename : '') + ' ' + item.lastname}</td>
                  <td>{item.email}</td>
                  <td>{item.contactno}</td>
                  <td>{item.skillset}</td>
                  <td>{item.preferredlocation}</td>
                  <td>
                    <button onClick={() => saveAs(`${config.apiBaseUrl}/public/uploads/${item.filename}`, item.filename)} className="btn btn-success">
                    <i class="bi bi-box-arrow-down"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          ):(<div className='text-center'>No job applicant found.</div>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShowCandidate;
