import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';

const AddMembership = (props) => {
    const [show, setShow] = useState(false);
    const token = localStorage.getItem('adminToken');
    const addby = localStorage.getItem('userID');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgFile, setImagFile] = useState(null);
    const [displayOrder, setDisplayOrder] = useState(0);
    const [websitelink, setWebsitelink] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }
    const addMembershipHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
        if (imgFile.length > 0) {
            let tempFile = imgFile[0];
            //console.log(tempFile.type);


            if (tempFile.type !== "image/png" && tempFile.type !== 'image/jpeg' && tempFile.type !== 'image/jpg' && tempFile.type !== 'image/gif') {
                Swal.fire("Warning", "Please attach Image in .png or .jpeg or.jpg format.", "warning");
                return false;
            }

            formData.append("imgfile", tempFile);
            // Append the file to FormData
        }
        formData.append("displayorder", displayOrder);
        formData.append("websitelink", websitelink);
        formData.append("addedby", addby);
        try {
            //setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/membership`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setDisplayOrder("");
            setWebsitelink("");
            Swal.fire({
                title: 'Saved!',
                text: `Membership details  has been saved successfully`,
                icon: 'success',
            }).then(() => {
                handleClose();
            });
            setBtnDisabled(false);
            setBtnText("Send");
            props.loadMembership();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Send");

            console.error(error);
            Swal.fire("Error", "Error adding Pratnership details.", "error");
        }


    }
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Add Membership
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size="lg">
                <Form onSubmit={addMembershipHandler} encType="multipart/form-data" method="post">
                    <Modal.Header style={{ backgroundColor: '#3572d4', color: 'white' }} closeButton>
                        <Modal.Title>Add Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formMembershipImage">
                            <Form.Label>Membership Image</Form.Label>
                            <Form.Control type="file" accept=".jpg, .jpeg, .png .gif" onChange={onChangeFile} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDisplayOrder">
                            <Form.Label>Display Order</Form.Label>
                            <Form.Control type="number" placeholder="Enter Order" onChange={(e) => { setDisplayOrder(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formWebsiteLink">
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control type="text" placeholder="Enter Website Link" onChange={(e) => { setWebsitelink(e.target.value) }} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit' disabled={btnDisabled}>
                            {btnText}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}

export default AddMembership
