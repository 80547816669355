import React, { useRef, useState, useEffect } from 'react'

import Iframe from 'react-iframe'
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../config';

function Contact() {
    useEffect(() => {
        showCompanydetail();
    }, []);

    const form = useRef();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companydetailsItem, setCompanydetailsItem] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalCountemail, setTotalCountemail] = useState(0);

    const [errorMessage, setErrorMessage] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Send");



    async function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //const token=firstName+lastName+email;
        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else {
            setErrorMessage("");
        }

        setBtnDisabled(true)
        setBtnText("Sending...")
        await axios.post(`${config.apiUrl}/Contact`, {
            "contactname": firstName + " " + lastName,
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "subject": subject,
            "message": message,
            "jobtitle": jobTitle,
            "companyname": companyName,

        }).then(response => {

            setFirstName("");
            setLastName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setCompanyName("");
            setJobTitle("");
            setBtnDisabled(false)
            setBtnText("Send")

            Swal.fire("Success", "Thank you for contacting us!", "success");
            fetch('https://3rxol6ia0g.execute-api.ap-south-1.amazonaws.com/sendContactMail', {
                mode: "no-cors",
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "user_name": firstName + " " + lastName,
                    "first_name": firstName,
                    "last_name": lastName,
                    "user_email": email,
                    "user_subject": subject,
                    "user_message": message,
                    "user_jobTitle": jobTitle,
                    "user_companyName": companyName,
                })
            })
        })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });
        /*fetch('https://3rxol6ia0g.execute-api.ap-south-1.amazonaws.com/sendContactMail', {
          mode:"no-cors",
          method: 'POST',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
               "user_name": firstName+ " "+lastName,
               "first_name": firstName,
               "last_name": lastName,
               "user_email": email,
               "user_subject": subject,
               "user_message": message,
               "user_jobTitle": jobTitle,
               "user_companyName": companyName,
          })
          }).then( response => {

              setFirstName("");
              setLastName("");
              setEmail("");
              setSubject("");
              setMessage("");
              setCompanyName("");
              setJobTitle("");
              setBtnDisabled(false)
              setBtnText("Send")

              Swal.fire("Success", "Thank you for contacting us!", "success");
          })
          .catch(error => {
              setBtnDisabled(false)
              setBtnText("Send")
              Swal.fire("Error", "Error sending mail.", "error");
          });*/

        e.target.reset();
    }
    const showCompanydetail = async (e) => {

        try {
            const response = await axios.get(
                `${config.apiUrl}/companyaddress`,

            );
            const companydetailsData = response.data.data;

            setCompanydetailsItem(companydetailsData);
            const totalCountaddress = companydetailsData.reduce((acc, item) => acc + item.addressdetail.length, 0);
            setTotalCount(totalCountaddress);


        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }

    }
    const getColClass = (addressindex, totalCount) => {
        const totalAddresses = totalCount;
        if (addressindex === 0) {
            return '6 '; // Left for the first address
        } else if (addressindex === totalAddresses - 1) {
            return '8 offset-md-2'; // Right for the last address
        } else if (addressindex === Math.floor(totalAddresses / 2)) {
            return '6'; // Center for the middle address
        } else {
            return '6'; // For other addresses
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 inner-bg text-white'>
                    </div>
                </div>
            </div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Contact</h2>
                    </div>

                </div>
            </section>

            <div className='container-fluid contact pt-5'>
                <div className='row'>
                    <div className='col-md-12 pt-4 pb-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <div className="myinfo-box">
                                                <i className="bi bi-map"></i>
                                                <h3>Our Address</h3>
                                                <div className='row'>
                                                    {companydetailsItem.map((item, index) => (
                                                        <>
                                                            {item.addressdetail.map((addressitem, addressindex) => (
                                                                totalCount % 2 === 0 ? (
                                                                    <div key={`${index}-${addressindex}`} className='col-md-6 mb-2'>
                                                                        <p className='bg-light2' style={{ whiteSpace: 'pre-wrap' }}>
                                                                            {addressitem}
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div key={`${index}-${addressindex}`} className={`col-md-${getColClass(addressindex, totalCount)} mb-2`}>
                                                                        <p className='bg-light2' style={{ whiteSpace: 'pre-wrap' }}>
                                                                            {addressitem}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="myinfo-box">
                                                <i className="bi bi-envelope"></i>
                                                <h3>Email Us</h3>
                                                {companydetailsItem.map((item, index) => (
                                                    <>
                                                        {item.emailaddress.map((emailaddressitem, emailaddressindex) => (

                                                            <p className='maillink2'>{emailaddressitem}{/*<a href='https://outlook.office.com/mail/deeplink/compose?to=contact@signatureip.ai' target={'_blank'}>contact@signatureip.ai</a>*/}</p>

                                                        ))}
                                                    </>
                                                ))}

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="myinfo-box">
                                                <i className="bi bi-telephone"></i>
                                                <h3>Call Us</h3>
                                                {companydetailsItem.map((item, index) => (
                                                    <>
                                                        {item.phonenumber.map((phonenumberitem, phonenumberindex) => (

                                                            <p className='maillink2'>{phonenumberitem}</p>

                                                        ))}
                                                    </>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-7'>
                                    <form ref={form} onSubmit={sendEmail} className="php-email-form">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <input type="text" name="firstName" className="form-control" id="firstName" placeholder="First Name"
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value)
                                                    }}
                                                    required />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input type="text" className="form-control" name="lastName" id="lastName" placeholder="Last Name"
                                                    onChange={(e) => {
                                                        setLastName(e.target.value)
                                                    }}
                                                    required />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Corporate Email"
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setErrorMessage("")
                                                    }}
                                                    required />
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <input type="text" className="form-control" name="job_title" id="job_title" placeholder="Job title"
                                                    onChange={(e) => {
                                                        setJobTitle(e.target.value)
                                                    }}
                                                    required />
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <input type="text" className="form-control" name="comapny_Name" id="comapny_Name" placeholder="Company Name"
                                                    onChange={(e) => {
                                                        setCompanyName(e.target.value)
                                                    }}
                                                    required />

                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <input type="text" className="form-control" name="user_subject" id="user_subject" placeholder="Subject"
                                                    onChange={(e) => {
                                                        setSubject(e.target.value)
                                                    }}
                                                    required />
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <textarea className="form-control" name="message" rows="4" placeholder="Message"
                                                    onChange={(e) => {
                                                        setMessage(e.target.value)
                                                    }}
                                                    required></textarea>
                                            </div>
                                        </div>






                                        <div className="text-center mt-3"><button type="submit" disabled={btnDisabled}>{btnText}</button></div>

                                        <div className='row'>
                                            <div className='col-12'>
                                                {
                                                    errorMessage ? <>
                                                        <div className='alert alert-danger  mt-3'>
                                                            {"Error: " + errorMessage}
                                                        </div>
                                                    </> : <></>
                                                }
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="map mt-2">
                <div className="container-fluid p-0">
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.1949222026283!2d-121.8989024849559!3d37.43249977982319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fced5e5dcd079%3A0x4594edbccea6860a!2s500%20E%20Calaveras%20Blvd%20Suite%20218%2C%20Milpitas%2C%20CA%2095035%2C%20USA!5e0!3m2!1sen!2sin!4v1676538837033!5m2!1sen!2sin"
                        width="640px"
                        height="320px"
                        id=""
                        className=""
                        display="block"
                        position="relative" />
                </div>
            </section>
        </>
    )
}

export default Contact
