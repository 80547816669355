import React from 'react'


function FooterDashboard() {
    return (
        <>
            <footer className="main-footer">
                <strong>Copyright &copy; 2023</strong>
                 <span className='ml-2'>Marquee Semiconductor Inc.</span>
                <div class="float-right d-none d-sm-inline-block">
                
                </div>
            </footer>
            
        </>
    )
}

export default FooterDashboard
