import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';


const UpdatePartnershipModal = (props) => {
    const token = localStorage.getItem('adminToken');
    const addby=localStorage.getItem('userID');
    const PartnershipID = props.id;
    const [show, setShow] = useState(false);
    const [companyName, setCompanyName] = useState([]);
    const [imgFile, setImagFile] = useState("");
    const [websiteLink, setWebsiteLink] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Save Changes");
    const [item, setItem] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onChangeFile = (e) => {
        const files = e.target.files;
        //const file = files[0];

        setImagFile(files);

    }

    useEffect(() => {
        showPartnershipdetail();
    }, [PartnershipID]);

    const showPartnershipdetail = async () => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/partnership/${PartnershipID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            const partnershippData = response.data.data;
            setItem(partnershippData);
            setCompanyName(partnershippData.companyname);
            setWebsiteLink(partnershippData.websitelink);
            

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Authentication error: You are not authorized to access this resource.');
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    }
    const UpdateLeadershipHandler = async (e) => {
        e.preventDefault();
        setBtnText("Submitting...");
        setBtnDisabled(true);
        let formData = new FormData();
         
        if (imgFile.length > 0) {
            let tempFile = imgFile[0];
            console.log(console.log(tempFile))
            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
            if (!validImageTypes.includes(tempFile.type)) {
                Swal.fire("Warning", "Please attach an image in .png, .jpeg, .jpg, or .gif format.", "warning");
                return;
            }
            formData.append("imgfile", tempFile);
        }

        console.log(imgFile)
            
        
        formData.append("companyname", companyName);
        formData.append("websitelink", websiteLink);
        formData.append("addedby",addby);
        
        //formData.append("calenderevent", calenderEventLink);

        /*for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
          }*/
          //return false;
        try {
            const response = await axios.put(
                `${config.apiUrl}/partnership/${PartnershipID}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setBtnDisabled(false);
            setBtnText("Sumbited");
            Swal.fire("Success", "Partnership details updated successfully!", "success");

            handleClose();
            props.loadPartnership();
        } catch (error) {
            setBtnDisabled(false);
            setBtnText("Sumbited");
            console.error(error);
            Swal.fire("Error", "Error updating Partnership.", "error");
        }
    }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
      <i className="bi bi-pencil-square"></i>
            </Button>
            <Modal show={show} onHide={handleClose} animation={false} size="lg" >
            <Form onSubmit={UpdateLeadershipHandler} encType="multipart/form-data" method="post">
                <Modal.Header closeButton style={{ backgroundColor: '#3572d4', color: 'white' }}>
                    <Modal.Title>Update Partnership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row g-3">
                        <div class="col">
                            <label className="form-label">Company Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Enter Company Name" onChange={(e) => { setCompanyName(e.target.value) }} value={companyName} required />
                        </div>
                        <div class="col">
                            <label class="form-label"> Company Logo <span style={{ color: 'red' }}>*</span></label>
                            <input className="form-control" type="file" id="formFile" accept=".jpg, .jpeg, .png, .gif" onChange={onChangeFile} />
                            {item.imgfile !== null && (
                                    <p style={{ fontSize: '12px' ,margin:'5px' }}><img src={`${config.apiBaseUrl}/public/prtnershipImg/${item.imgfile}`} width={50} className="img-fluid" /></p>
                                )}
                        </div>
                    </div>
                    <div class="mt-2">
                            <label className="form-label">website Link <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Website Link" onChange={(e) => { setWebsiteLink(e.target.value) }} value={websiteLink} />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit' disabled={btnDisabled}>
                    {btnText}
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
    </>
  )
}

export default UpdatePartnershipModal
